import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import FixedBottomButtonWrapper from '../../components/core/FAB/FixedBottomButtonWrapper';
import TimeParameter from '../../components/core/FAB/TimeParameter';
import {
  useSearchParams,
  useScrollContextRef,
  withScrollToTopOnBack,
} from '../../components/search/helpers';
import {
  actionSetFabAvailabilityDate,
  actionSetFabAvailabilityGuests,
  actionSetFabAvailabilityTime,
  actionSetFabDisplayValues,
} from '../../store/Availability/AvailabilityAction';
import useAvailabilityCallback from './helpers/useAvailabilityCallback';
import TableParameter from '../venue/TableParameter';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    width: '100vw',
    height: '100%',
    background: theme.palette.background.white,
    top: 56,
    left: 0,
    [theme.breakpoints.up('xl')]: {
      top: 64,
    },
  },
  scroll: {
    display: (props) => (props?.showFAB ? 'block' : 'grid'),
    height: '100%',
    overflowY: (props) => (props?.showFAB ? 'scroll' : 'visible'),
  },
}));

const FABWrapper = ({ children, showFAB = true, isSearchPage = false }) => {
  const classes = useStyles({ showFAB });
  const dispatch = useDispatch();
  const scrollRef = useScrollContextRef();
  const { pageNumber } = useSearchParams();
  const [findTableOpen, setFindTableOpen] = useState(false);
  const {
    submittedDate,
    submittedTime,
    submittedGuests,
    selectedDate,
    selectedTime,
    selectedGuests,
  } = useSelector((state) => state?.availability);
  const fetchAvailability = useAvailabilityCallback(pageNumber);

  useEffect(() => {
    fetchAvailability();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submittedDate, submittedTime, submittedGuests]);

  const handleCloseFab = () => {
    setFindTableOpen(false);
    // unsubmitted values on FAB should still maintain the submitted values
    if (
      moment(selectedDate).format('YYYY-MM-DD') !==
      moment(submittedDate).format('YYYY-MM-DD')
    ) {
      dispatch(actionSetFabAvailabilityDate({ selectedDate: submittedDate }));
    }

    if (selectedTime !== submittedTime) {
      dispatch(actionSetFabAvailabilityTime({ selectedTime: submittedTime }));
    }

    if (selectedGuests !== submittedGuests) {
      dispatch(
        actionSetFabAvailabilityGuests({ selectedGuests: submittedGuests })
      );
    }
  };

  const handleClick = () => {
    setFindTableOpen(true);
  };

  const handleDone = () => {
    setFindTableOpen(false);
    dispatch(
      actionSetFabDisplayValues({
        submittedDate: selectedDate,
        submittedTime: selectedTime,
        submittedGuests: selectedGuests,
      })
    );
  };

  const handleFindTableOpen = (isOpen) => {
    setFindTableOpen(isOpen);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.scroll} ref={scrollRef}>
        {children}
      </Box>
      {showFAB && (
        <FixedBottomButtonWrapper
          isSearchPage={isSearchPage}
          onClick={handleClick}>
          <TimeParameter />
        </FixedBottomButtonWrapper>
      )}
      <TableParameter
        isOpen={findTableOpen}
        setOpen={handleFindTableOpen}
        handleDone={handleDone}
        handleCloseFab={() => handleCloseFab()}
      />
    </Box>
  );
};

export default withScrollToTopOnBack(FABWrapper);
