import {
  CONTACT_US_ERROR_BUTTON_TEXT,
  CONTACT_US_ERROR_BUTTON_URL,
  CONTACT_US_ERROR_MESSAGE,
  ERROR_SIGNOUT_BUTTON_TEXT,
  GENERIC_ERROR_MESSAGE,
  GENERIC_ERROR_TITLE,
  INELIGIBLE_ERROR_BUTTON_TEXT,
  INELIGIBLE_ERROR_BUTTON_URL,
  INELIGIBLE_ERROR_MESSAGE,
  RESERVATION_FAILED_ERROR_ALREADY_EXISTS_BUTTON_TEXT,
  RESERVATION_FAILED_ERROR_ALREADY_EXISTS_TITLE,
  RESERVATION_FAILED_ERROR_ALREADY_EXISTS_MESSAGE,
  ICON_GENERIC_ERROR_ALT_TEXT,
  ICON_CAUTION_ALT_TEXT,
  ICON_CALENDAR_ALT_TEXT,
  MAINTENANCE_ERROR_MESSAGE,
  HOME_LINK,
  GENERIC_ERROR_NO_BUTTON_MESSAGE,
  GENERIC_ERROR_NO_BUTTON_TITLE,
  INVALID_EMAIL_ERROR_MESSAGE,
  INVALID_PHONE_NUMBER_ERROR_MESSAGE,
  RESERVATION_CANCEL_ERROR_BUTTON_TEXT,
  RESERVATION_CANCEL_ERROR_TITLE,
  RESERVATION_CANCEL_ERROR_MESSAGE,
  RESERVATION_UPDATE_ERROR_BUTTON_TEXT,
  RESERVATION_UPDATE_ERROR_MESSAGE,
  RESERVATION_UPDATE_ERROR_TITLE,
  PAGE_NOT_FOUND_TITLE,
  PAGE_NOT_FOUND_MESSAGE,
  LOGIN_ERROR_BUTTON_TEXT,
  LOGIN_ERROR_MESSAGE,
  LOGIN_ERROR_TITLE,
  UNAUTHORIZED_ERROR_MESSAGE,
} from '../../assets/copy';

import CautionCircleSvg from '../../assets/icons/caution-circle.svg';
import WineGlassSvg from '../../assets/icons/wine-glass.svg';
import CalendarSvg from '../../assets/icons/drawn-calendar.svg';

const maintenanceErrorBase = {
  errorMessage: MAINTENANCE_ERROR_MESSAGE,
  errorMessageVariant: 'medium2Normal',
  iconAlt: ICON_CAUTION_ALT_TEXT,
  iconSrc: CautionCircleSvg,
  shouldShowAppBar: false,
};

export const ERROR_PAGE_TYPES = Object.freeze({
  AUTH_UNHANDLED: {
    buttonText: ERROR_SIGNOUT_BUTTON_TEXT,
    buttonUrl: '/sign-out',
    errorMessage: GENERIC_ERROR_NO_BUTTON_MESSAGE,
    errorMessageVariant: 'medium1Normal',
    errorTitle: GENERIC_ERROR_NO_BUTTON_TITLE,
    iconAlt: ICON_GENERIC_ERROR_ALT_TEXT,
    iconSrc: WineGlassSvg,
    shouldShowAppBar: false,
    shouldShowSignoutButton: false,
    value: 'AUTH_UNHANDLED',
  },
  BAD_STANDING: {
    buttonText: CONTACT_US_ERROR_BUTTON_TEXT,
    buttonUrl: CONTACT_US_ERROR_BUTTON_URL,
    errorMessage: CONTACT_US_ERROR_MESSAGE,
    errorMessageVariant: 'medium2Normal',
    iconAlt: ICON_CAUTION_ALT_TEXT,
    iconSrc: CautionCircleSvg,
    shouldShowAppBar: false,
    shouldShowSignoutButton: true,
    value: 'BAD_STANDING',
  },
  INELIGIBLE: {
    buttonText: INELIGIBLE_ERROR_BUTTON_TEXT,
    buttonUrl: INELIGIBLE_ERROR_BUTTON_URL,
    errorMessage: INELIGIBLE_ERROR_MESSAGE,
    errorMessageVariant: 'medium2Normal',
    iconAlt: ICON_CAUTION_ALT_TEXT,
    iconSrc: CautionCircleSvg,
    shouldShowAppBar: false,
    shouldShowSignoutButton: true,
    value: 'INELIGIBLE',
  },
  INVALID_EMAIL: {
    errorMessage: INVALID_EMAIL_ERROR_MESSAGE,
    errorMessageVariant: 'medium2Normal',
    iconAlt: ICON_CAUTION_ALT_TEXT,
    iconSrc: CautionCircleSvg,
    shouldShowAppBar: false,
    shouldShowSignoutButton: true,
    value: 'INVALID_EMAIL',
  },
  INVALID_PHONE_NUMBER: {
    errorMessage: INVALID_PHONE_NUMBER_ERROR_MESSAGE,
    errorMessageVariant: 'medium2Normal',
    iconAlt: ICON_CAUTION_ALT_TEXT,
    iconSrc: CautionCircleSvg,
    shouldShowAppBar: false,
    shouldShowSignoutButton: true,
    value: 'INVALID_PHONE_NUMBER',
  },
  PAGE_NOT_FOUND: {
    buttonText: HOME_LINK,
    buttonUrl: '/',
    errorMessage: PAGE_NOT_FOUND_MESSAGE,
    errorMessageVariant: 'medium1Normal',
    errorTitle: PAGE_NOT_FOUND_TITLE,
    iconAlt: ICON_GENERIC_ERROR_ALT_TEXT,
    iconSrc: WineGlassSvg,
    shouldShowAppBar: true,
    shouldShowSignoutButton: false,
    value: 'PAGE_NOT_FOUND',
  },
  PAGE_NOT_FOUND_NO_BUTTON: {
    errorMessage: PAGE_NOT_FOUND_MESSAGE,
    errorMessageVariant: 'medium1Normal',
    errorTitle: PAGE_NOT_FOUND_TITLE,
    iconAlt: ICON_GENERIC_ERROR_ALT_TEXT,
    iconSrc: WineGlassSvg,
    shouldShowAppBar: true,
    shouldShowSignoutButton: false,
    value: 'PAGE_NOT_FOUND_NO_BUTTON',
  },
  GENERIC: {
    buttonText: HOME_LINK,
    buttonUrl: '/',
    errorMessage: GENERIC_ERROR_MESSAGE,
    errorMessageVariant: 'medium1Normal',
    errorTitle: GENERIC_ERROR_TITLE,
    iconAlt: ICON_GENERIC_ERROR_ALT_TEXT,
    iconSrc: WineGlassSvg,
    shouldShowAppBar: true,
    shouldShowSignoutButton: false,
    value: 'GENERIC',
  },
  GENERIC_NO_BUTTON: {
    errorMessage: GENERIC_ERROR_NO_BUTTON_MESSAGE,
    errorMessageVariant: 'medium1Normal',
    errorTitle: GENERIC_ERROR_NO_BUTTON_TITLE,
    iconAlt: ICON_GENERIC_ERROR_ALT_TEXT,
    iconSrc: WineGlassSvg,
    shouldShowAppBar: true,
    shouldShowSignoutButton: false,
    value: 'GENERIC_NO_BUTTON',
  },
  LOGIN: {
    buttonText: LOGIN_ERROR_BUTTON_TEXT,
    buttonUrl: '/',
    errorMessage: LOGIN_ERROR_MESSAGE,
    errorMessageVariant: 'medium1Normal',
    errorTitle: LOGIN_ERROR_TITLE,
    iconAlt: ICON_GENERIC_ERROR_ALT_TEXT,
    iconSrc: WineGlassSvg,
    shouldShowAppBar: false,
    shouldShowSignoutButton: false,
    value: 'LOGIN',
  },
  MAINTENANCE: {
    ...maintenanceErrorBase,
    shouldShowSignoutButton: true,
    value: 'MAINTENANCE',
  },
  MAINTENANCE_NO_SIGNOUT: {
    ...maintenanceErrorBase,
    shouldShowSignoutButton: false,
    value: 'MAINTENANCE_NO_SIGNOUT',
  },
  // Shown in Alert component on reserve page
  NO_AVAILABILITY: {
    value: 'NO_AVAILABILITY',
  },
  DOUBLE_BOOKED: {
    buttonText: RESERVATION_FAILED_ERROR_ALREADY_EXISTS_BUTTON_TEXT,
    buttonUrl: '/reservations',
    errorMessage: RESERVATION_FAILED_ERROR_ALREADY_EXISTS_MESSAGE,
    errorMessageVariant: 'medium1Normal',
    errorTitle: RESERVATION_FAILED_ERROR_ALREADY_EXISTS_TITLE,
    iconAlt: ICON_CALENDAR_ALT_TEXT,
    iconSrc: CalendarSvg,
    onCloseUrl: '/reserve',
    shouldShowAppBar: true,
    shouldShowSignoutButton: false,
    value: 'DOUBLE_BOOKED',
  },
  RESERVATION_UNHANDLED: {
    errorMessage: GENERIC_ERROR_NO_BUTTON_MESSAGE,
    errorMessageVariant: 'medium1Normal',
    errorTitle: GENERIC_ERROR_NO_BUTTON_TITLE,
    iconAlt: ICON_GENERIC_ERROR_ALT_TEXT,
    iconSrc: WineGlassSvg,
    onCloseUrl: '/reserve',
    shouldShowAppBar: true,
    shouldShowSignoutButton: false,
    value: 'RESERVATION_UNHANDLED',
  },
  RESERVATION_CANCEL: {
    buttonText: RESERVATION_CANCEL_ERROR_BUTTON_TEXT,
    errorMessageVariant: 'medium1Normal',
    buttonUrl: '/reservations',
    errorMessage: RESERVATION_CANCEL_ERROR_MESSAGE,
    errorTitle: RESERVATION_CANCEL_ERROR_TITLE,
    iconAlt: ICON_GENERIC_ERROR_ALT_TEXT,
    iconSrc: WineGlassSvg,
    shouldShowAppBar: true,
    value: 'RESERVATION_CANCEL',
  },
  RESERVATION_UPDATE: {
    buttonText: RESERVATION_UPDATE_ERROR_BUTTON_TEXT,
    errorMessageVariant: 'medium1Normal',
    buttonUrl: '/reservations',
    errorMessage: RESERVATION_UPDATE_ERROR_MESSAGE,
    errorTitle: RESERVATION_UPDATE_ERROR_TITLE,
    iconAlt: ICON_GENERIC_ERROR_ALT_TEXT,
    iconSrc: WineGlassSvg,
    shouldShowAppBar: true,
    value: 'RESERVATION_UPDATE',
  },
  UNAUTHORIZED: {
    value: 'UNAUTHORIZED',
    errorMessage: UNAUTHORIZED_ERROR_MESSAGE,
  },
});
