import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { convertUserReservationItemsForDraft } from './reservationFilters';
import {
  actionSetDraftReservation,
  actionSetEditReservationInfoBlock,
  actionSetOpenSheet,
} from '../store/Reservations/ReservationsAction';
import { actionSetAnimationPushUp } from '../store/Animations/AnimationsAction';
import moment from 'moment';
import { DATE_FORMAT_YEAR_FIRST } from '../assets/dateFormats';
import { redirectToReservePage } from '../pages/reserve/helpers/reserveHelpers';
import { RESERVATION_TYPE } from './constants/Reservation';

const useReservationMethods = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { reservation, draftReservation } = useSelector(
    (state) => state?.reservations
  );
  const { submittedGuests } = useSelector((state) => state?.availability);

  const { allergies, diets, appData } = useSelector((state) => state);
  const { user } = useSelector((state) => state.user);

  const handleUpdate = (date, time, timeslot) => {
    // TO DO: we might want to move the handleUpdate here
  };

  const selectTimeSlotEdit = (date, time, timeslot) => {
    const draftReservationEdit = {
      venue: draftReservation.venue,
      userId: reservation.userId,
      c1CustRefId: user.c1CustRefId,
      defaultTipAmount: reservation.defaultTipAmount,
      allergies: convertUserReservationItemsForDraft(
        allergies?.list,
        reservation.allergies
      ),
      diets: convertUserReservationItemsForDraft(
        diets?.list,
        reservation.diets
      ),
      specialOccasions: convertUserReservationItemsForDraft(
        appData.specialOccasions,
        reservation.specialOccasions
      ),
      notes: reservation.notes || '',
      emailAddress: reservation.email,
      phoneNumber: reservation.phone,
      firstName: reservation.firstName,
      lastName: reservation.lastName,
      ...timeslot,
      time: time,
      date: moment(date).format(DATE_FORMAT_YEAR_FIRST),
      guests: submittedGuests,
      bookingPolicy: timeslot?.bookingPolicy,
    };

    if (draftReservationEdit.requiresCreditCard) {
      dispatch(actionSetEditReservationInfoBlock(true));
      return;
    }
    if (timeslot.slotName === '') {
      dispatch(actionSetDraftReservation(draftReservationEdit));
      dispatch(actionSetAnimationPushUp(true));
      redirectToReservePage(history, {
        type: RESERVATION_TYPE.UPDATE
      });
    } else {
      dispatch(actionSetDraftReservation(draftReservationEdit));
      dispatch(actionSetOpenSheet());
    }
  };

  return {
    handleUpdate,
    selectTimeSlotEdit,
  };
};

export default useReservationMethods;
