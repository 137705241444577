import React from 'react';
import { IconButton } from '@material-ui/core';
import { useSelector } from 'react-redux';
import {
  MENU_ALT_TEXT,
  NAVIGATION_SHOW_ALT_TEXT,
  EXPANDED_TEXT,
  COLLAPSED_TEXT,
} from '../../../assets/copy';
import MenuSvg from '../../../assets/icons/menu.svg';

const MobileMenuButton = ({ handlePress, menuVisible }) => {
  const { overlayVisible } = useSelector((state) => state.search);
  const STATUS = menuVisible ? EXPANDED_TEXT : COLLAPSED_TEXT;

  if (overlayVisible) return null;

  return (
    <IconButton
      autoFocus
      aria-label={`${NAVIGATION_SHOW_ALT_TEXT} ${STATUS}`}
      onClick={handlePress}
      disableFocusRipple>
      <img aria-hidden="true" src={MenuSvg} alt={MENU_ALT_TEXT} />
    </IconButton>
  );
};

export default MobileMenuButton;
