import { put, takeLatest } from 'redux-saga/effects';
import { ERROR_PAGE_TYPES } from '../../utils/constants/ErrorPageTypes';
import { dispatchError, getConfigJson } from '../helpers';

import * as ACTIONS from './ConfigAction';
import * as TYPES from './ConfigTypes';

export function* getConfigRequestHandler() {
  try {
    const config = yield getConfigJson();

    if (config.maintenanceWindowFlag) {
      throw new Error(`${ERROR_PAGE_TYPES.MAINTENANCE.value}:`);
    }

    yield put(ACTIONS.actionGetConfigSuccess(config));
  } catch (error) {
    yield dispatchError(error.response?.data?.message ?? error.message);
    yield put(ACTIONS.actionGetConfigFail(error));
  }
}

export default function* configSaga() {
  yield takeLatest(TYPES.GET_CONFIG_REQUEST, getConfigRequestHandler);
}
