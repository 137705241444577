import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import { connectRouter } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import { persistStore, persistCombineReducers } from 'redux-persist';
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1';
import storageSession from 'redux-persist/lib/storage/session';
import reducers from './reducers';
import sagas from './sagas/index';

export const history = createBrowserHistory();

const persistConfig = {
  key: 'root',
  storage: storageSession,
  stateReconciler: autoMergeLevel1,
};

const rootReducer = persistCombineReducers(persistConfig, {
  ...reducers,
  router: connectRouter(history),
});

const routingMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

// Redux Dev tools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = () => {
  const enhancers = [applyMiddleware(sagaMiddleware, routingMiddleware)];

  // Create store
  const store = createStore(rootReducer, composeEnhancers(...enhancers));

  // Persist store
  const persistor = persistStore(store);

  // Run sagas
  sagaMiddleware.run(sagas);

  return { persistor, store };
};

export const { store, persistor } = configureStore();
