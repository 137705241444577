import React from 'react';
import { makeStyles } from '@material-ui/styles';
import baseCreditCardStyle from './baseCreditCardStyles';
import {
  DEFAULT_PAYMENT_ACTION_TEXT,
  DEFAULT_PAYMENT_TEXT,
  DEFAULT_TEXT,
  PAYMENT_METHODS_CREDIT_CARD,
} from '../../assets/copy';
import Typography from '../../components/overrides/Typography';
import { WIDTH_BREAKPOINT } from '../../utils/constants/Breakpoints';
import { useViewport } from '../../utils/useViewport';
import GravityCheckbox from '../../components/gravity/checkbox';
import clsx from 'clsx';
import { PAYMENT_METHOD_COMPONENT_TYPES } from './paymentComponentTypes';
import { generateScreenReaderText } from '../../utils/stringHelperMethods';
import { useFocusWithin } from '@react-aria/interactions';

const useStyles = makeStyles((theme) => ({
  creditCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.up('xl')]: {
      marginTop: ({ type }) => {
        if (type === PAYMENT_METHOD_COMPONENT_TYPES.RESERVE)
          return theme.spacing(2);
        return 0;
      },
    },
  },
  defaultFlexDirection: {
    flexDirection: 'row',
  },
  creditCardInnerContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    [theme.breakpoints.up('xl')]: {
      alignItems: 'unset',
      flex: 1,
    },
  },
  creditCardImage: {
    width: '51px',
    height: theme.spacing(4),
    marginTop: 0,
    borderRadius: theme.spacing(0.5),
    [theme.breakpoints.up('xl')]: {
      marginTop: theme.spacing(0.65),
    },
  },
  creditCardTextContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  creditCardName: {
    color: theme.palette.secondary[80],
    ...baseCreditCardStyle,
  },
  creditCardDigit: {
    color: theme.palette.digitalGray[60],
    ...baseCreditCardStyle,
  },
  edit: {
    flex: 1,
    order: 3,
    alignSelf: 'auto',
  },
  defaultLabel: {
    background: theme.palette.digitalGray[10],
    padding: `${theme.spacing(0.25)} ${theme.spacing(1)}`,
    borderRadius: '12px',
    color: theme.palette.secondary[80],
    whiteSpace: 'nowrap',
    textAlign: 'center',
    marginTop: theme.spacing(1),
    fontFamily: 'Optimist',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    width: '41px',
    height: theme.spacing(3),
  },
  option: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    background: theme.palette.text.secondary,
    boxSizing: 'border-box',
    borderRadius: '100px',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.up('xl')]: {
      marginTop: theme.spacing(1.25),
    },
  },
  selected: {
    border: `${theme.spacing(1)} solid ${
      theme.gravityPalette.button.progressive.default
    }`,
    '&:hover': {
      borderColor: theme.gravityPalette.button.progressive.active,
    },
  },
  unselected: {
    border: `1px solid ${theme.gravityPalette.button.regressive.default}`,
    '&:hover': {
      cursor: 'pointer',
      borderColor: theme.gravityPalette.button.regressive.active,
    },
  },
  checkBoxContainer: {
    display: 'flex',
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(5),
    '& > label': {
      marginRight: theme.spacing(1),
    },
  },
  checkboxLabel: {
    alignItems: 'baseline',
  },
  checkBox: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:first-of-type': {
      paddingTop: 0,
      paddingRight: theme.spacing(1.5),
    },
    // Disable MUI specific hover color until adopting new Gravity hover state
    '&.Mui-checked:hover': {
      backgroundColor: 'transparent',
    },
  },
  defaultPaymentActionText: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Optimist',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: theme.spacing(2),
    lineHeight: theme.spacing(3),
    color: '#1F1F1F',
  },
  focusRing: {
    outline: '5px auto -webkit-focus-ring-color',
  },
}));

const CreditCard = ({
  type,
  account,
  isDefaultPayment,
  isSingleCardUser = false,
  checked,
  onSelect,
  selectedPayment,
  handleSelectPayment,
  showCheckBox,
}) => {
  const classes = useStyles({ type });
  const { width } = useViewport();
  const isMobile = width < WIDTH_BREAKPOINT;
  const shouldShowDefaultBadge = isDefaultPayment && !isSingleCardUser;
  const shouldShowDefaultBadgeOnDesktop = !isMobile && shouldShowDefaultBadge;
  const shouldShowDefaultBadgeOnMobile = isMobile && shouldShowDefaultBadge;
  const [isFocusWithin, setFocusWithin] = React.useState(false);
  const { focusWithinProps } = useFocusWithin({
    onFocusWithinChange: (isFocusWithin) => setFocusWithin(isFocusWithin),
  });
  const generateRadioLabel = () => {
    return generateScreenReaderText(
      ',',
      account.accountDetails.product,
      PAYMENT_METHODS_CREDIT_CARD,
      account.accountDetails.lastFourCardNumber,
      (shouldShowDefaultBadgeOnMobile || shouldShowDefaultBadgeOnDesktop) &&
        DEFAULT_PAYMENT_TEXT
    );
  };
  const radioTabIndex =
    !isSingleCardUser && !shouldShowDefaultBadgeOnDesktop
      ? { tabIndex: 0 }
      : null;

  return (
    <div
      className={clsx(
        classes.creditCardContainer,
        isDefaultPayment && classes.defaultFlexDirection
      )}
      key={`credit-card-${account.associatedAccountReferenceId}`}>
      <div
        className={classes.creditCardInnerContainer}
        // interactive controls should receive focus non interactive elemenst should not
        {...radioTabIndex}
        aria-label={generateRadioLabel()}
        role="group">
        {!isSingleCardUser && type === 'editView' && (
          <div
            tabIndex={0}
            role="radio"
            accessibiltyRole="radio"
            aria-checked={
              selectedPayment?.associatedAccountReferenceId ===
              account.associatedAccountReferenceId
                ? true
                : false
            }
            className={clsx(
              classes.option,
              selectedPayment?.associatedAccountReferenceId ===
                account.associatedAccountReferenceId
                ? classes.selected
                : classes.unselected
            )}
            onClick={() => handleSelectPayment(account)}
            onKeyPress={(e) =>
              e.key === 'Enter' && handleSelectPayment(account)
            }
            aria-label={generateRadioLabel()}
          />
        )}
        <img
          src={account.accountDetails.cardArtUrl}
          className={classes.creditCardImage}
          alt=""
        />
        <div className={classes.creditCardTextContainer}>
          <Typography className={classes.creditCardName}>
            {account.accountDetails.product}
          </Typography>
          <Typography className={classes.creditCardDigit}>
            {`${PAYMENT_METHODS_CREDIT_CARD} ${account.accountDetails.lastFourCardNumber}`}
          </Typography>
          {shouldShowDefaultBadgeOnDesktop && (
            <Typography
              align={width > WIDTH_BREAKPOINT ? 'right' : 'inherit'}
              className={classes.defaultLabel}>
              {DEFAULT_TEXT}
            </Typography>
          )}
        </div>
      </div>
      {showCheckBox && (
        <div
          className={clsx(
            classes.checkBoxContainer,
            isFocusWithin ? classes.focusRing : ''
          )}
          {...focusWithinProps}>
          <GravityCheckbox
            labelClassName={clsx(
              classes.checkboxLabel,
              classes.defaultPaymentActionText
            )}
            className={classes.checkBox}
            checked={checked}
            aria-checked={checked}
            disableRipple
            onChange={() => onSelect(account.associatedAccountReferenceId)}
            aria-label={DEFAULT_PAYMENT_ACTION_TEXT}
            role="checkbox"
          />
          <div className={classes.defaultPaymentActionText} aria-hidden="true">
            {DEFAULT_PAYMENT_ACTION_TEXT}
          </div>
        </div>
      )}
      {shouldShowDefaultBadgeOnMobile && !isSingleCardUser && (
        <Typography
          aria-label={DEFAULT_PAYMENT_TEXT}
          align={'inherit'}
          className={classes.defaultLabel}>
          {DEFAULT_TEXT}
        </Typography>
      )}
    </div>
  );
};

export default CreditCard;
