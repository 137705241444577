import * as types from './ReservationsTypes';

//reservation list by email
export const actionReservationListByUserIdRequest = (payload) => ({
  type: types.RESERVATION_LIST_BY_USERID_REQUEST,
  payload,
});

export const actionReservationListByUserIdSuccess = (payload) => ({
  type: types.RESERVATION_LIST_BY_USERID_SUCCESS,
  payload,
});

export const actionReservationListByUserIdFail = (payload) => ({
  type: types.RESERVATION_LIST_BY_USERID_FAIL,
  payload,
});

//set reservation - dummy action
export const actionSetReservation = (payload) => ({
  type: types.SET_RESERVATION,
});

export const actionSetReservationSuccess = () => ({
  type: types.SET_RESERVATION_SUCCESS,
});

export const actionSetReservationFail = () => ({
  type: types.SET_RESERVATION_FAIL,
});

export const actionSetEditReservationInfoBlock = (payload) => ({
  type: types.SET_EDIT_RESERVATION_INFO_BLOCK,
  payload,
});

//get reservation
export const actionGetReservationRequest = (payload) => ({
  type: types.GET_RESERVATION_REQUEST,
  payload,
});

export const actionGetReservationSuccess = (payload) => ({
  type: types.GET_RESERVATION_SUCCESS,
  payload,
});

export const actionGetReservationFail = (payload) => ({
  type: types.GET_RESERVATION_FAIL,
  payload,
});

//create reservation
export const actionCreateReservationRequest = (payload) => ({
  type: types.CREATE_RESERVATION_REQUEST,
  payload,
});

export const actionCreateReservationSuccess = (payload) => ({
  type: types.CREATE_RESERVATION_SUCCESS,
  payload,
});

export const actionCreateReservationFail = (payload) => ({
  type: types.CREATE_RESERVATION_FAIL,
  payload,
});

//update reservation
export const actionUpdateReservationRequest = (payload) => ({
  type: types.UPDATE_RESERVATION_REQUEST,
  payload,
});

export const actionUpdateReservationSuccess = (payload) => ({
  type: types.UPDATE_RESERVATION_SUCCESS,
  payload,
});

export const actionUpdateReservationFail = (payload) => ({
  type: types.UPDATE_RESERVATION_FAIL,
  payload,
});

//cancel reservation
export const actionCancelReservationRequest = (payload) => ({
  type: types.CANCEL_RESERVATION_REQUEST,
  payload,
});

export const actionCancelReservationSuccess = (payload) => ({
  type: types.CANCEL_RESERVATION_SUCCESS,
  payload,
});

export const actionCancelReservationFail = (payload) => ({
  type: types.CANCEL_RESERVATION_FAIL,
  payload,
});

// set draftReservation
export const actionSetDraftReservation = (payload) => ({
  type: types.SET_DRAFT_RESERVATION,
  payload,
});

// clear request statuses
export const actionClearReservationCreateStatus = () => ({
  type: types.CLEAR_RESERVATION_CREATE_STATUS,
});

export const actionClearReservationUpdateStatus = () => ({
  type: types.CLEAR_RESERVATION_UPDATE_STATUS,
});

export const actionClearReservationCancelStatus = () => ({
  type: types.CLEAR_RESERVATION_CANCEL_STATUS,
});

export const actionClearReservationListStatus = () => ({
  type: types.CLEAR_RESERVATION_LIST_STATUS,
});

export const actionClearReservationGetStatus = () => ({
  type: types.CLEAR_RESERVATION_GET_STATUS,
});

export const actionClearAllReservations = () => ({
  type: types.CLEAR_ALL_RESERVATIONS,
});

export const actionSetOpenSheet = () => ({
  type: types.OPEN_RESERVATION_SHEET,
});

export const actionSetCloseSheet = () => ({
  type: types.CLOSE_RESERVATION_SHEET,
});

export const actionSetPaymentAccount = (payload) => ({
  type: types.SET_PAYMENT_ACCOUNT,
  payload,
});

export const actionUpdateDefaultPaymentSuccess = () => ({
  type: types.UPDATE_DEFAULT_PAYMENT_SUCCESS,
});

export const actionSetReservationPaymentRedirectFromStepUp = (payload) => ({
  type: types.SET_RESERVATION_PAYMENT_REDIRECT_FROM_STEP_UP,
  payload,
});

export const actionClearReservationPaymentRedirectFromStepUp = (payload) => ({
  type: types.CLEAR_RESERVATION_PAYMENT_REDIRECT_FROM_STEP_UP,
  payload,
});
