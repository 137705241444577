import paginateSearch from '../../utils/paginateSearch';
import { SIGNOUT_USER_SUCCESS } from '../User/UserTypes';
import * as TYPES from './VenuesTypes';

const initialState = {
  venue: null,
  inLocalMarket: null,
  list: [],
  pages: [],
  totalCount: 0,
  totalPages: 0,
  events: [],
  getEventsStatus: {
    error: null,
    loading: null,
  },
  findVenuesStatus: {
    error: null,
    loading: null,
  },
  searchVenuesStatus: {
    error: null,
    success: null,
  },
  venuesByMarketStatus: {
    error: null,
    success: null,
  },
};

export default function venueReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case TYPES.FIND_VENUES_REQUEST:
      return {
        ...state,
        findVenuesStatus: {
          ...state.findVenuesStatus,
          error: null,
          loading: true,
        },
      };
    case TYPES.FIND_VENUES_FAIL:
      return {
        ...state,
        findVenuesStatus: {
          ...state.findVenuesStatus,
          error: payload,
          loading: false,
        },
      };
    case TYPES.GET_VENUE_SUCCESS:
      return {
        ...state,
        venue: payload,
      };
    case TYPES.SET_VENUES:
      const list = payload ?? [];
      const { totalPages, pages } = paginateSearch(list);
      return {
        ...state,
        list,
        pages,
        totalCount: list.length,
        totalPages,
      };
    case TYPES.RESET_VENUE_LIST:
      return {
        ...initialState,
        venue: state.venue,
      };
    case TYPES.SEARCH_VENUES_REQUEST:
      return {
        ...state,
        findVenuesStatus: {
          ...state.findVenuesStatus,
          loading: true,
        },
        searchVenuesStatus: {
          ...state.searchVenuesStatus,
          success: null,
        },
        venuesByMarketStatus: {
          error: null,
          success: null,
        },
      };
    case TYPES.SEARCH_VENUES_SUCCESS:
      return {
        ...state,
        inLocalMarket: payload.inLocalMarket,
        findVenuesStatus: {
          ...state.findVenuesStatus,
          loading: false,
        },
        searchVenuesStatus: {
          error: null,
          success: true,
        },
      };
    case TYPES.SEARCH_VENUES_FAIL:
      return {
        ...state,
        findVenuesStatus: {
          ...state.findVenuesStatus,
          loading: false,
        },
        searchVenuesStatus: {
          error: payload,
          success: false,
        },
      };
    case TYPES.VENUES_BY_MARKET_REQUEST:
      return {
        ...state,
        findVenuesStatus: {
          ...state.findVenuesStatus,
          loading: true,
        },
        searchVenuesStatus: {
          error: null,
          success: null,
        },
        venuesByMarketStatus: {
          ...state.venuesByMarketStatus,
          success: null,
        },
      };
    case TYPES.VENUES_BY_MARKET_SUCCESS:
      return {
        ...state,
        inLocalMarket: true,
        findVenuesStatus: {
          ...state.findVenuesStatus,
          loading: false,
        },
        venuesByMarketStatus: {
          error: null,
          success: true,
        },
      };
    case TYPES.VENUES_BY_MARKET_FAIL:
      return {
        ...state,
        findVenuesStatus: {
          ...state.findVenuesStatus,
          loading: false,
        },
        venuesByMarketStatus: {
          error: payload,
          success: false,
        },
      };
    case TYPES.GET_EVENTS_REQUEST:
      return {
        ...state,
        getEventsStatus: {
          error: null,
          loading: true,
        },
      };
    case TYPES.GET_EVENTS_SUCCESS:
      return {
        ...state,
        events: payload,
        getEventsStatus: {
          error: null,
          loading: false,
        },
      };
    case SIGNOUT_USER_SUCCESS:
      return initialState;
    default:
      return state;
  }
}
