import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor } from '../../store';

const useStyles = makeStyles((theme) => ({
  commonLayout: {
    marginTop: theme.spacing(7),
    minHeight: 'calc(100vh - 56px)',
    [theme.breakpoints.up('xl')]: {
      marginTop: theme.spacing(8),
      minHeight: 'calc(100vh - 144px)',
    },
  },
}));

const PageLayout = ({ children }) => {
  const classes = useStyles();

  return (
    <Box className={classes.commonLayout}>
      <PersistGate loading={null} persistor={persistor}>
        {children}
      </PersistGate>
    </Box>
  );
};

export default PageLayout;
