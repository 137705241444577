import { VENUE_TYPES } from './constants/FilterTypes';
import DateTime from './dateTimeHelpers';

export const isEvent = (venue) => venue?.venueType === VENUE_TYPES.EVENT;

export const isPastEvent = (venue) => {
  if (!isEvent(venue)) {
    return false;
  }
  const lastDate = getLastDateOfEvents(venue?.eventDates);
  if (!lastDate) {
    return true;
  }
  const lastEventDate = new DateTime(lastDate);
  const now = new DateTime();
  return lastEventDate < now;
};

export const isSoldOut = (venue) => {
  if (!isEvent(venue)) {
    return false;
  }
  if (venue?.isSoldOut) {
    return true;
  }
  const salesEndDate = new DateTime(venue?.salesEndDate);
  const now = new DateTime();
  return salesEndDate < now;
};

export const isVenueAvailable = (venue) =>
  !isPastEvent(venue) && !venue.eventSalesClosed;

export const filterUnavailableRelatedEvents = (venue) => {
  if (venue.relatedVenues?.items?.length) {
    const venueClone = { ...venue };
    const relatedVenuesClone = { ...venueClone.relatedVenues };
    const itemsClone = [...relatedVenuesClone.items].filter(
      ({ relatedVenue }) => !isPastEvent(relatedVenue) && !relatedVenue.isHidden
    );
    relatedVenuesClone.items = itemsClone;
    venueClone.relatedVenues = relatedVenuesClone;
    return venueClone;
  }
  return venue;
};

const getLastDateOfEvents = (eventDates) =>
  eventDates?.length > 0 && eventDates[eventDates?.length - 1];
