import React from 'react';
import { useHistory } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import DefaultImage from '../../assets/images/media.png';
import { chefItemBaseStyles } from './ChefItemStyles';
import { useViewport } from '../../utils/useViewport';
import OverflowRestaurantDetails from './OverflowRestaurantDetails';
import {
  generateScreenReaderText,
  convertNullToEmptyString,
} from '../../utils/stringHelperMethods';
import { priceCheck } from '../../utils/venue';
import useEventFlag from '../../utils/useEventFlag';
import { EVENT_TEXT } from '../../assets/copy';
import clsx from 'clsx';
import { enterKeyPressHandler } from '../../utils/keyPresses';

const useStyles = makeStyles((theme) => ({
  ...chefItemBaseStyles(theme),
  media: {
    height: 168,
    position: 'relative',
  },
  eventCard: {
    height: 'auto !important',
  },
}));

//update to pass venue instead of name desc
export default function ChefItemVertical({
  large,
  venue,
  showGetTicketLink = false,
}) {
  const classes = useStyles({ large, vertical: true });
  const history = useHistory();
  const { width } = useViewport();
  const MAX_WIDTH_OF_CARD_TEXT = 256;

  const handleClick = () => {
    history.push(`/venue/${venue.id}`);
  };

  const eventStatus = useEventFlag(venue);
  const eventText = eventStatus?.venueHasEvent
    ? convertNullToEmptyString(eventStatus?.renderVenueBadge()?.props?.text) +
      ' ' +
      EVENT_TEXT
    : '';
  const generateVenueText = () => {
    return generateScreenReaderText(
      ',',
      eventText,
      venue?.name,
      venue.cuisines.items[0]?.cuisine.name || '',
      priceCheck(venue?.priceRange),
      venue.city,
      venue.state
    );
  };
  const onKeyPressHandler = enterKeyPressHandler && handleClick;

  return (
    <Paper elevation={4} className={classes.root}>
      {venue && (
        <Card
          className={clsx(classes.card, showGetTicketLink && classes.eventCard)}
          role="link"
          tabIndex={0}
          aria-label={generateVenueText()}
          onClick={handleClick}
          onKeyPress={onKeyPressHandler}>
          <CardMedia
            className={classes.media}
            image={venue.images[0] || DefaultImage}>
            {eventStatus.renderVenueBadge()}
          </CardMedia>
          <CardContent>
            <OverflowRestaurantDetails
              large={false}
              vertical
              maxCardWidth={MAX_WIDTH_OF_CARD_TEXT}
              width={width}
              venue={venue}
            />
            {showGetTicketLink &&
              eventStatus.renderGetTicketLink(showGetTicketLink)}
          </CardContent>
        </Card>
      )}
    </Paper>
  );
}
