import { VENUE_TYPE_EVENT, VENUE_TYPE_REGULAR } from '../../assets/copy';

export const FILTER_TYPES = Object.freeze({
  ACCOLADES: 'accolades',
  ATMOSPHERES: 'atmospheres',
  CATEGORIES: 'categories',
  CUISINES: 'cuisines',
  NEIGHBORHOODS: 'neighborhoods',
  PRICE_RANGE: 'priceRange',
  VENUE_TYPE: 'venueType',
});

export const VENUE_TYPES = {
  EVENT: 'EVENT',
  REGULAR: 'REGULAR',
};

export const VENUE_TYPE_VALUES = [
  { venueType: VENUE_TYPES.EVENT, name: VENUE_TYPE_EVENT },
  {
    venueType: VENUE_TYPES.REGULAR,
    name: VENUE_TYPE_REGULAR,
  },
];

export const VENUE_TYPE_FILTERS = Object.freeze(
  VENUE_TYPE_VALUES.map(({ venueType, name }, index) =>
    Object.freeze({ id: index + 1, name, venueType })
  )
);
