import * as TYPES from './ReservationsTypes';
import { SIGNOUT_USER_SUCCESS } from '../User/UserTypes';
import { FETCH_STATUS } from '../../utils/constants/FetchStatus';

const initialState = {
  list: [],
  reservation: null,
  draftReservation: {},
  reservationCreateStatus: null,
  reservationUpdateStatus: null,
  reservationCancelStatus: null,
  reservationListStatus: null,
  reservationGetStatus: null,
  isEditReservationInfoBlockVisible: false,
  isReservationSheetOpen: false,
};

export default function reservationReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case TYPES.RESERVATION_LIST_BY_USERID_REQUEST:
      return {
        ...state,
        list: [],
        reservationListStatus: null,
      };
    case TYPES.RESERVATION_LIST_BY_USERID_SUCCESS:
      return {
        ...state,
        list: payload,
        reservationListStatus: {
          success: true,
        },
      };
    case TYPES.GET_RESERVATION_REQUEST:
      return {
        ...state,
        reservation: null,
        reservationGetStatus: null,
      };
    case TYPES.GET_RESERVATION_SUCCESS:
      return {
        ...state,
        reservation: payload,
        reservationGetStatus: {
          success: true,
        },
      };
    case TYPES.SET_RESERVATION_SUCCESS:
      return {
        ...state,
        reservation: payload,
      };
    case TYPES.SET_DRAFT_RESERVATION:
      return {
        ...state,
        draftReservation: payload,
      };
    case TYPES.RESERVATION_LIST_BY_USERID_FAIL:
      return {
        ...state,
        reservationListStatus: {
          success: false,
          error: payload,
        },
      };
    case TYPES.GET_RESERVATION_FAIL:
      return {
        ...state,
        reservationGetStatus: {
          success: false,
          error: payload,
        },
      };
    case TYPES.CREATE_RESERVATION_REQUEST:
    case TYPES.UPDATE_RESERVATION_REQUEST:
      return {
        ...state,
        status: FETCH_STATUS.FETCHING,
      };
    case TYPES.CREATE_RESERVATION_SUCCESS:
      return {
        ...state,
        status: FETCH_STATUS.SUCCESS,
        reservationCreateStatus: {
          success: true,
        },
        reservation: payload,
      };
    case TYPES.UPDATE_RESERVATION_SUCCESS:
      return {
        ...state,
        status: FETCH_STATUS.SUCCESS,
        reservation: payload,
        reservationUpdateStatus: {
          success: true,
        },
      };
    case TYPES.CANCEL_RESERVATION_SUCCESS:
      return {
        ...state,
        reservation: { ...state.reservation, status: 'deletedSubmitted' },
        reservationCancelStatus: {
          success: true,
          payload: payload,
        },
      };
    case TYPES.CREATE_RESERVATION_FAIL:
      return {
        ...state,
        status: FETCH_STATUS.ERROR,
        reservationCreateStatus: {
          success: false,
          error: payload,
        },
      };
    case TYPES.UPDATE_RESERVATION_FAIL:
      return {
        ...state,
        status: FETCH_STATUS.ERROR,
        reservationUpdateStatus: {
          status: false,
          error: payload,
        },
      };
    case TYPES.CANCEL_RESERVATION_FAIL:
      return {
        ...state,
        reservationCancelStatus: {
          success: false,
          error: payload,
        },
      };
    case TYPES.CLEAR_RESERVATION_CREATE_STATUS:
      return {
        ...state,
        reservationCreateStatus: null,
      };
    case TYPES.CLEAR_RESERVATION_UPDATE_STATUS:
      return {
        ...state,
        reservationUpdateStatus: null,
      };
    case TYPES.CLEAR_RESERVATION_CANCEL_STATUS:
      return {
        ...state,
        reservationCancelStatus: null,
      };
    case TYPES.CLEAR_RESERVATION_LIST_STATUS:
      return {
        ...state,
        reservationListStatus: null,
      };
    case TYPES.CLEAR_RESERVATION_GET_STATUS:
      return {
        ...state,
        reservationGetStatus: null,
      };
    case TYPES.OPEN_RESERVATION_SHEET:
      return {
        ...state,
        isReservationSheetOpen: true,
      };
    case TYPES.CLOSE_RESERVATION_SHEET:
      return {
        ...state,
        isReservationSheetOpen: false,
      };
    case TYPES.SET_EDIT_RESERVATION_INFO_BLOCK: {
      return {
        ...state,
        isEditReservationInfoBlockVisible: payload,
      };
    }
    case TYPES.SET_PAYMENT_ACCOUNT:
      return {
        ...state,
        paymentAccount: payload,
      };
    case TYPES.UPDATE_DEFAULT_PAYMENT_SUCCESS:
      return {
        ...state,
        paymentAccount: {
          ...state.paymentAccount,
          isDefaultPaymentEdited: false,
        },
      };
    case TYPES.SET_RESERVATION_PAYMENT_REDIRECT_FROM_STEP_UP:
      return {
        ...state,
        stepUp: {
          shouldRedirect: true,
          errorCode: payload.stepUpErrorCode,
        },
      };
    case TYPES.CLEAR_RESERVATION_PAYMENT_REDIRECT_FROM_STEP_UP:
      return {
        ...state,
        stepUp: {},
      };
    case SIGNOUT_USER_SUCCESS:
      return initialState;
    default:
      return state;
  }
}
