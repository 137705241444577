import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Typography from '../../components/overrides/Typography';
import { useViewport } from '../../utils/useViewport';
import {
  shortScreenReaderVenueDetails,
  shortVenueDetails,
} from '../../utils/venue';
import {
  generateScreenReaderText,
  removeHtmlTags,
} from '../../utils/stringHelperMethods';
import ScreenReaderText from '../../components/core/ScreenReaderText';
import ReadMoreReadLess from '../../components/core/ReadMoreReadLess';
import clsx from 'clsx';
import { TAGS } from './tags';
import InteractiveTag from '../../components/core/InteractiveTag';
import SignatureIcon from '../../assets/icons/signature-icon.svg';
import {
  SIGNATURE_COLLECTION_TEXT,
  INACTIVE_VENUE_TEXT,
  FULL_VENUE_DESCTIPTION_TEXT,
} from '../../assets/copy';
import ExclusiveAlert from '../home/ExclusiveAlert';
import NoAvailabilityInfo from '../venueAvailability/NoAvailabilityInfo';
import { TIME_AVAILABILITY_TYPES } from '../venueAvailability/timeAvailabilityTypes';
import { WIDTH_BREAKPOINT } from '../../utils/constants/Breakpoints';
import useEventFlag from '../../utils/useEventFlag';
import { useLocation } from 'react-router';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4, 3, 3, 3),
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(5, 7),
    },
  },
  title: {
    color: '#3D3D3D',
    [theme.breakpoints.up('xl')]: {
      marginBottom: theme.spacing(1),
    },
  },
  location: {
    color: '#3D3D3D',
  },
  tagContainer: {
    display: 'flex',
    gap: theme.spacing(1.5),
    flexWrap: 'nowrap',
    maxWidth: `calc(100vw - ${theme.spacing(6)})`,
    overflowX: 'auto',
    margin: theme.spacing(2, 0.5, 0.5, 0),
    [theme.breakpoints.up('xl')]: {
      flexWrap: 'wrap',
      maxWidth: '100%',
    },
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  tagItem: {
    background: '#F4F4F4',
    padding: `${theme.spacing(0.25)} ${theme.spacing(1)}`,
    borderRadius: '12px',
    color: theme.palette.secondary[80],
    whiteSpace: 'nowrap',
  },
  specialTag: {
    background: '#C0E7F3',
    color: theme.palette.blue[70],
  },
  signatureTag: {
    background: theme.palette.chip.background,
    border: theme.palette.chip.background,
  },
  signatureContent: {
    color: theme.palette.primary.dark,
    lineHeight: theme.spacing(3),
    padding: theme.spacing(0.25, 0),
  },
  signatureTrailingIcon: {
    '& path': {
      fill: theme.palette.primary.dark,
    },
  },
  divider: {
    margin: `${theme.spacing(4)} 0 ${theme.spacing(3)} 0`,
  },
  awards: {
    [theme.breakpoints.up('xl')]: {
      display: 'flex',
      flexWrap: 'wrap',
    },
  },
  experience: {
    display: 'flex',
    margin: `${theme.spacing(2)} 0 ${theme.spacing(0.5)} 0`,
    [theme.breakpoints.up('xl')]: {
      flex: '1 0 50%',
    },
  },
  description: {
    wordBreak: 'break-word',
  },
  awardText: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.up('xl')]: {
      marginLeft: theme.spacing(3),
    },
  },
  awardIcon: {
    maxHeight: 32,
    maxWidth: 32,
    alignSelf: 'center',
    [theme.breakpoints.up('xl')]: {
      maxHeight: 48,
      maxWidth: 48,
    },
  },
  noAvailabilityInfoContainer: {
    padding: theme.spacing(2, 0, 0),
    justifyContent: 'normal',
  },
  noAvailabilityInfoContent: {
    width: '100%',
    maxWidth: '100%',
  },
  venueDetail: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('xl')]: {
      marginTop: theme.spacing(2),
    },
  },
}));

const Information = ({ venue }) => {
  const classes = useStyles();
  const { width } = useViewport();
  const [isExclusiveAlertOpened, setIsExclusiveAlertOpened] = useState(false);
  const venueName = venue?.name;
  const { pathname } = useLocation();
  const [pageTitle, setPageTitle] = useState('');

  const handleCloseExclusiveModal = useCallback(() => {
    setIsExclusiveAlertOpened(false);
  }, []);

  const handleOpenExclusiveModal = useCallback(() => {
    setIsExclusiveAlertOpened(true);
  }, []);

  const tags =
    venue.tags.items.length > 0
      ? venue.tags.items.map((item) => item.tag.name)
      : null;
  const awards =
    venue.awards.items.length > 0
      ? venue.awards.items.map((item) => item.award)
      : null;

  const eventStatus = useEventFlag(venue);
  // Commenting out below functions to be updated in future ticket for link to parent venue
  // const navigateToVenue = useCallback(
  //   (e) => {
  //     // this line is needed for Safari since it won't redirect unless the e.preventDefault() is called
  //     e.preventDefault();
  //     history.push(`/venue/${parentID}`);
  //   },
  //   [parentID, history]
  // );

  // const generateParentLink = useCallback(() => {
  //   if (!parentName) return;
  //   const location = ` • ${[venue?.city, venue?.state].join(', ')}`;

  //   return (
  //     <>
  //       <Link onClick={(e) => navigateToVenue(e)}>{parentName}</Link>
  //       {location}
  //     </>
  //   );
  // }, [venue?.city, venue?.state, navigateToVenue, parentName]);

  useEffect(() => {
    setPageTitle(venueName);
  }, [pathname, venueName]);

  const venueDescription = useMemo(
    () => venue.description,
    [venue.description]
  );

  const generateFullDescriptionText = () => {
    return generateScreenReaderText(
      ',',
      FULL_VENUE_DESCTIPTION_TEXT,
      removeHtmlTags(venueDescription)
    );
  };

  return (
    <Box className={classes.root}>
      <Box>
        <ScreenReaderText>
          {generateScreenReaderText(
            ',',
            venue.name,
            !eventStatus?.venueHasEvent
              ? shortScreenReaderVenueDetails(venue)
              : ''
          )}
        </ScreenReaderText>
        <Typography
          component="h1"
          variant="large1Light"
          aria-live="polite"
          className={classes.title}>
          {pageTitle}
        </Typography>
        <Typography
          variant={width > WIDTH_BREAKPOINT ? 'medium1Normal' : 'small2Normal'}
          className={classes.location}>
          {!eventStatus?.venueHasEvent && shortVenueDetails(venue)}
        </Typography>

        <Box display={'flex'} flexWrap="wrap">
          {venue.isSignatureCollection && (
            <Box className={classes.tagContainer}>
              <InteractiveTag
                text={SIGNATURE_COLLECTION_TEXT}
                leadingIcon={SignatureIcon}
                rootStyle={classes.signatureTag}
                contentStyle={classes.signatureContent}
                trailingIconStyle={classes.signatureTrailingIcon}
                onClickHandler={handleOpenExclusiveModal}
              />
              <ExclusiveAlert
                isOpen={isExclusiveAlertOpened}
                handleCloseAlert={handleCloseExclusiveModal}
              />
            </Box>
          )}
          {tags?.map((item, idx) => (
            <Box
              key={`${item}-${idx}`}
              role="list"
              className={classes.tagContainer}>
              <Typography
                className={clsx(
                  classes.tagItem,
                  item === TAGS.ACCLAIMED_DINING && classes.specialTag
                )}
                role="listitem"
                variant="small1Semibold">
                {item}
              </Typography>
            </Box>
          ))}
        </Box>

        {!venue.isActive && (
          <NoAvailabilityInfo
            containerClassName={classes.noAvailabilityInfoContainer}
            contentClassName={classes.noAvailabilityInfoContent}
            text={INACTIVE_VENUE_TEXT}
            useDivider={false}
            dividerClassName={classes.divider}
            type={TIME_AVAILABILITY_TYPES.HORIZONTAL}
          />
        )}
      </Box>
      <Box mt={4} className={classes.venueDetail}>
        <Typography
          variant="medium1Normal"
          className={classes.description}
          component="h2"
          aria-label={generateFullDescriptionText()}>
          <ReadMoreReadLess>{venueDescription}</ReadMoreReadLess>
        </Typography>
        {awards && (
          <Box mt={2} className={classes.awards}>
            {awards.map((item) => (
              <Box key={item.id} className={classes.experience}>
                <img className={classes.awardIcon} src={item.icon} alt="" />
                <Box className={classes.awardText}>
                  <Typography variant="medium1Normal">
                    {item.secondaryText}
                  </Typography>
                  <Typography variant="medium1Semibold">
                    {item.primaryText}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Information;
