import axios from 'axios';
import { call, put, cancelled } from 'redux-saga/effects';
import { ERROR_PAGE_TYPES } from '../utils/constants/ErrorPageTypes';
import { GRAPHQL_ROUTE } from './apiRoutes';
import { actionSetErrorType } from './Error/ErrorAction';
import { DEFAULT_PAGE_SIZE } from '../utils/constants/Pagination';
import { store } from './index';

const SHOULD_SET_COOKIES = process.env.REACT_APP_SHOULD_SET_COOKIES === 'true';

export const axiosInstance = axios.create({
  ...(SHOULD_SET_COOKIES ? { withCredentials: true } : {}),
  baseURL: process.env.REACT_APP_BFF_ENDPOINT_URL,
  headers: {
    'Content-Type': 'application/json;v=1',
    Accept: 'application/json;v=1',
  },
});

// This is used outside of Redux Sagas, where we need to call using async/await or Promises.
export const callGraphqlWithTokenAsync = async ({ query, variables = {} }) => {
  try {
    const result = await axiosInstance.post(GRAPHQL_ROUTE, {
      query,
      variables,
    });
    return result.data;
  } catch (error) {
    const errorType =
      error.response.status === 401 || error.response.status === 403
        ? ERROR_PAGE_TYPES.UNAUTHORIZED.value
        : ERROR_PAGE_TYPES.GENERIC.value;

    store.dispatch(actionSetErrorType(errorType));
  }
};

export function* callGraphqlWithToken({ query, variables = {} }) {
  const cancelTokenSource = axios.CancelToken.source();

  try {
    const result = yield call(
      axiosInstance.post,
      GRAPHQL_ROUTE,
      {
        query,
        variables,
      },
      {
        cancelToken: cancelTokenSource.token,
      }
    );
    return result.data;
  } catch (error) {
    if (error.response.status === 401 || error.response.status === 403) {
      throw new Error(
        `${ERROR_PAGE_TYPES.UNAUTHORIZED.value}: ${ERROR_PAGE_TYPES.UNAUTHORIZED.message}`
      );
    } else {
      throw error;
    }
  } finally {
    // Cancel previous axios calls when multiple actions are dispatched AND get cancelled by takeLatest
    if (yield cancelled()) {
      cancelTokenSource.cancel();
    }
  }
}

export function* dispatchError(message) {
  const errorType = message?.split(':')[0];
  const error = ERROR_PAGE_TYPES[errorType] ?? ERROR_PAGE_TYPES.GENERIC;
  yield put(actionSetErrorType(error.value));
}

export function* getConfigJson(noCache = false) {
  try {
    const { data } = yield call(
      axios.get,
      `${window.location.origin}/config.json`,
      {
        ...(noCache
          ? {
              params: {
                timestamp: Date.now(),
              },
            }
          : {}), // set timestamp param to force fetch from Cloudfront
      }
    );
    return data;
  } catch (error) {
    console.error(error);
    return {};
  }
}

export function* checkMaintenanceWindow() {
  const config = yield getConfigJson(true);
  if (config.maintenanceWindowFlag) {
    throw new Error(`${ERROR_PAGE_TYPES.MAINTENANCE.value}:`);
  }
}

export function getDefaultPaymentAccount(user) {
  const isSingleCardUser = user.associatedAccounts.length === 1;
  if (isSingleCardUser) return user?.associatedAccounts[0];
  return user?.associatedAccounts?.find((account) => {
    return (
      account.associatedAccountReferenceId ===
      user?.defaultPaymentMethodAccountId
    );
  });
}

export function getVenuesByPage(venues, page) {
  // request DEFAULT_PAGE_SIZE venues at a time for better user experience. But only load 10 at a time.
  // if the page number is 1 request venues for page 0 and page 1
  const startIndex = page === 1 ? 0 : page * DEFAULT_PAGE_SIZE;
  const endIndex = (page + 1) * DEFAULT_PAGE_SIZE;

  return venues?.length > DEFAULT_PAGE_SIZE
    ? venues.slice(startIndex, endIndex)
    : venues;
}
