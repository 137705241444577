import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import Typography from '../../components/overrides/Typography';
import { useViewport } from '../../utils/useViewport';
import { VENUE_ATMOSPHERE_TITLE } from '../../assets/copy';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 3, 4),
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(5, 0, 4, 7),
      width: '100%',
    },
  },
  container: {
    [theme.breakpoints.up('xl')]: {
      alignItems: 'flex-start',
    },
  },
  subTitle: {
    marginBottom: theme.spacing(0.5),
    color: theme.palette.secondary[80],
    [theme.breakpoints.up('xl')]: {
      marginBottom: theme.spacing(1),
    },
  },
  description: {
    color: theme.palette.secondary[80],
  },
  atmospheres: {
    margin: 0,
  },
}));

const widthBreakpoint = 960;

const Details = ({ venue }) => {
  const classes = useStyles();
  const { width } = useViewport();

  return (
    <Box className={classes.root}>
      <Grid container className={classes.container}>
        <Grid item xs={12} xl={6}>
          <Typography
            variant={
              width > widthBreakpoint ? 'medium2Semibold' : 'medium1Semibold'
            }
            className={clsx(classes.subTitle)}
            component="h2">
            {VENUE_ATMOSPHERE_TITLE}
          </Typography>
          <Grid item container spacing={3} className={classes.atmospheres}>
            {venue.atmospheres.items.length > 0 &&
              venue.atmospheres.items.map((item, index) => (
                <>
                  <Typography
                    variant="medium1Normal"
                    className={classes.description}
                    key={`atmosphere-${item.atmosphere.id}`}>
                    <>
                      {item.atmosphere.name}
                      {index < venue.atmospheres.items.length - 1 && (
                        <span>,&nbsp;</span>
                      )}
                    </>
                  </Typography>
                </>
              ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Details;
