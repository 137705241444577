import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Box, Grid, FormControl, Select } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Info } from '@material-ui/icons/';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Typography from '../../components/overrides/Typography';
import FilledButton from '../../components/core/FilledButton';
import PageSection from '../../components/core/PageSection';
import LargeCard from '../../components/core/Card/LargeCard';
import SmallCard from '../../components/core/Card/SmallCard';
import TimePicker from '../../components/core/TimePicker';
import DropDownSvg from '../../assets/icons/dropdown.svg';
import CloseButton from '../../components/core/CloseButton';
import CancelEditModal from '../../components/reserve/CancelEditModal';
import { useViewport } from '../../utils/useViewport';
import { actionSetDraftReservation } from '../../store/Reservations/ReservationsAction';
import { sanitizeSelTime, dateToISOString } from '../../utils/dateTime';

import {
  CALENDAR_INPUT_LABEL,
  CALENDAR_INPUT_SCREENREADER_LABEL,
  TIME_INPUT_LABEL,
  GUESTS_INPUT_LABEL,
  FAB_SUBMIT_LABEL,
  EDIT_EXISTING_RESERVATION_FIND_TIMESLOT,
  RESERVATION_EDIT_TITLE,
  RESERVATION_DATE_SELECT,
  RESERVATION_GUESTS_SELECT,
  RESERVATION_EDIT_CURRENT_LABEL,
  RESERVATION_CHANGES_AND_UPDATE_CONTACT_RESTAURANT_TEXT,
  EDIT_PROFILE_SPECIAL_DATES_GENERAL_ERROR,
  CLOSE_BUTTON_DESCRIPTION,
  CLOSE_BUTTON_LABEL,
} from '../../assets/copy';
import { PAYMENT_RULE_TYPE } from './helpers/paymentAvailabilityTypes';
import moment from 'moment';
import { isDate } from 'date-fns';
import ScreenReaderText from '../../components/core/ScreenReaderText';
import '../../components/core/Calendar/css/react-datetime.css';
import { WIDTH_BREAKPOINT } from '../../utils/constants/Breakpoints';
import DatepickerCalendar from '../../components/core/Calendar/DatepickerCalendar';
import { trackClick } from '../../utils/useOneTag';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
    alignItems: 'center',
    boxSizing: 'border-box',
    flexDirection: 'column',
    padding: theme.spacing(3),
    [theme.breakpoints.up('xl')]: {
      paddingTop: theme.spacing(4),
    },
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    scrollbarWidth: 'none',
    scrollBehavior: 'smooth',
  },
  calendar: {
    marginTop: theme.spacing(1.5),
    flexWrap: 'wrap',
    display: 'flex',
    width: '100%',
  },
  closeContainer: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('xl')]: {
      marginBottom: theme.spacing(4),
    },
  },
  close: {
    padding: 0,
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(1.5),
    },
  },
  button: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('xl')]: {
      maxWidth: 186,
      padding: 0,
    },
  },
  venueButton: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('xl')]: {
      maxWidth: 250,
      padding: 0,
    },
  },
  formControl: {
    minWidth: 120,
    width: '100%',
    '& .MuiOutlinedInput-input': {
      ...theme.typography.medium1Normal,
      boxSizing: 'border-box',
      height: '100%',
      padding: '11px 14px',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 4,
      height: 48,
    },
  },
  infoIcon: {
    color: theme.palette.text.primary,
  },
  image: {
    marginRight: theme.spacing(2),
    top: `calc(40%)`,
  },
  leftBorder: {
    [theme.breakpoints.up('xl')]: {
      maxWidth: '323px',
    },
    borderLeft: `4px solid ${theme.palette.primary.main}`,
  },
  leftGridSection: {
    [theme.breakpoints.up('xl')]: {
      maxWidth: 350,
      marginRight: theme.spacing(8),
    },
  },
  subheader: {
    [theme.breakpoints.up('xl')]: {
      marginBottom: theme.spacing(2),
    },
  },
  message: {
    textAlign: 'left',
    color: theme.palette.error.main,
    fontSize: 12,
    marginBottom: theme.spacing(0.4),
  },
  hiddenStyles: {
    position: 'absolute',
    overflow: 'hidden',
    clip: 'rect(0 0 0 0)',
    height: 1,
    width: 1,
    margin: -1,
    padding: 0,
    border: 0,
  },
  contentColumn: {
    [theme.breakpoints.up('xl')]: {
      flex: '1 1 100%',
    },
  },
  dateSectionBorder: {
    '& div input': {
      border: `2px solid ${theme.palette.red[50]}!important`,
    },
  },
  dateSection: {
    pading: `{theme.spacing(0)!important}`,
    [theme.breakpoints.up('xl')]: {
      '& .rdt p.MuiTypography-root': {
        marginBottom: 1,
      },
    },
  },
}));

const EditReserve = () => {
  const classes = useStyles();
  const { width } = useViewport();
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState();
  const [selGuests, setSelGuests] = useState();
  const [selTime, setSelTime] = useState();

  const midnightToday = new Date(new Date().setHours(0, 0, 0, 0));
  const [message, setMessage] = useState('');
  const [calendarClasses, setCalendarClasses] = useState([
    classes.contentColumn,
    classes.dateSection,
  ]);
  const withRedBorder = useMemo(
    () => [
      classes.contentColumn,
      classes.dateSection,
      classes.dateSectionBorder,
    ],
    [classes.contentColumn, classes.dateSection, classes.dateSectionBorder]
  );
  const withoutRedBorder = useMemo(
    () => [classes.contentColumn, classes.dateSection],
    [classes.contentColumn, classes.dateSection]
  );

  const handleFabSubmitClick = (event) => {
    handleSeeVenueAvailability(event);
    trackClick(event, "edit-reservation-fab-submit", "Button");
  }
  const { reservation } = useSelector((state) => state.reservations);

  useEffect(() => {
    if (reservation) {
      if (reservation.paymentRule !== PAYMENT_RULE_TYPE.NONE) {
        // if paymentRule is either "save_for_later" or "advance_payment" redirect the user out
        history.replace('/reservations');
      }
      const initialYear = moment(`${reservation.date}`).format('YYYY/MM/DD');
      const tempDate = moment(`${initialYear} ${reservation.time}`);
      setSelectedDate(tempDate.format('YYYY-MM-DD'));
      setSelGuests(reservation.guests);
      setSelTime(sanitizeSelTime(reservation.time));
    }
  }, [reservation, history]);

  const handleSeeVenueAvailability = () => {
    dispatch(
      actionSetDraftReservation({
        ...reservation,
        date: selectedDate,
        time: selTime,
        guests: selGuests,
      })
    );

    history.push('/reserve/edit/check-availability', {
      date: selectedDate,
      time: selTime,
      guests: selGuests,
    });
  };

  const handleDateChange = (date) => {
    const updatedInputValue = date ? new Date(date) : date;
    if (!isDate(updatedInputValue) || updatedInputValue < midnightToday) {
      setMessage(EDIT_PROFILE_SPECIAL_DATES_GENERAL_ERROR);
      setCalendarClasses(withRedBorder);
    } else {
      setMessage('');
      setCalendarClasses(withoutRedBorder);
      setSelectedDate(updatedInputValue);
    }
  };

  const handleChange = (event) => {
    setSelGuests(event.target.value);
  };
  const handleTime = (event) => {
    setSelTime(event.target.value);
  };

  const handleToggleEditModal = () => {
    setModalOpen((isOpen) => !isOpen);
  };

  const handleCancelEdit = () => {
    setModalOpen(false);
    setMessage('');
    history.push(`/reservations/${id}`);
  };

  const [modalOpen, setModalOpen] = useState(false);
  const { navigationMenuVisible } = useSelector((state) => state.appData);

  const renderPrepaidView = () => (
    <>
      <Box pt={1} className={classes.alertContainer}>
        <Box className={classes.leftBorder}>
          <Alert
            severity="info"
            iconMapping={{
              info: <Info className={classes.infoIcon} />,
            }}>
            <Typography variant="small2Normal" gutterBottom>
              {RESERVATION_CHANGES_AND_UPDATE_CONTACT_RESTAURANT_TEXT}
            </Typography>
          </Alert>
        </Box>
      </Box>
      <Box>
        <FilledButton
          text="See Venue Availability"
          variant="medium2Normal"
          onClick={handleSeeVenueAvailability}
          role="link"
          style={classes.venueButton}
        />
      </Box>
    </>
  );

  const guests = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
    { value: 8, label: '8' },
    { value: 9, label: '9' },
    { value: 10, label: '10' },
  ];

  const renderEditView = () => (
    <>
      <Box pb={2} pt={width >= WIDTH_BREAKPOINT ? 1 : 2}>
        <Typography variant="small2Normal" gutterBottom aria-hidden="true">
          {CALENDAR_INPUT_LABEL}
        </Typography>
        <ScreenReaderText>{CALENDAR_INPUT_SCREENREADER_LABEL}</ScreenReaderText>
        <label htmlFor="dateSelect" className={classes.hiddenStyles}>
          {RESERVATION_DATE_SELECT}
        </label>
        <Box className={clsx(calendarClasses)}>
          <DatepickerCalendar
            calendarClassName={!message && classes.calendar}
            initialValue={selectedDate}
            minDate={midnightToday}
            onChange={(date) => {
              handleDateChange(date);
            }}
            selectedDate={new Date(dateToISOString(selectedDate))}
          />
          {!!message && (
            <Typography className={classes.message} variant="medium1Normal">
              {message}
            </Typography>
          )}
        </Box>
      </Box>
      <Box>
        <Typography
          variant="small2Normal"
          gutterBottom
          id="reservationTimeLabel">
          {TIME_INPUT_LABEL}
        </Typography>
        <TimePicker
          labelId="reservationTimeLabel"
          name="default"
          defaultValue={selTime}
          onChange={handleTime}
        />
      </Box>
      <Box py={2}>
        <Typography variant="small2Normal" gutterBottom>
          {GUESTS_INPUT_LABEL}
        </Typography>
        <label
          htmlFor="guestsSelect"
          aria-hidden="true"
          className={classes.hiddenStyles}
          id="reservationGuestsLabel">
          {RESERVATION_GUESTS_SELECT}
        </label>
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            id="guestsSelect"
            inputProps={{ 'aria-labelledby': 'reservationGuestsLabel' }}
            native
            value={selGuests}
            onChange={handleChange}
            IconComponent={(selectProps) => (
              <img
                src={DropDownSvg}
                alt=""
                className={clsx(classes.image, selectProps.className)}
              />
            )}>
            {guests.map((item) => (
              <option value={item.value} key={`guest-dropdown-${item.value}`}>
                {item.label}
              </option>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box>
        <FilledButton
          text={FAB_SUBMIT_LABEL}
          variant="medium2Normal"
          onClick={handleFabSubmitClick}
          style={classes.button}
          role="link"
          data-cy="fab-submit"
        />
      </Box>
    </>
  );

  const renderEditReservationSection = useCallback(() => {
    return (
      reservation && (
        <Grid item xs={12} xl={5}>
          <Typography
            className={classes.subheader}
            variant={
              width >= WIDTH_BREAKPOINT ? 'medium2Semibold' : 'medium1Semibold'
            }
            component="h2">
            {EDIT_EXISTING_RESERVATION_FIND_TIMESLOT}
          </Typography>
          {reservation.isPrepaid ? renderPrepaidView() : renderEditView()}
        </Grid>
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, selTime, selGuests]);

  const closeSVGDimension = width >= WIDTH_BREAKPOINT ? 21 : 16;

  return (
    !navigationMenuVisible && (
      <PageSection className={classes.root} variant="grid">
        <Grid item container flexWrap="wrap" xl={10}>
          <Grid item xs={12}>
            <Box mb={width >= WIDTH_BREAKPOINT ? 3 : 2}>
              <Box
                className={classes.closeContainer}
                display="flex"
                justifyContent="flex-end">
                <CloseButton
                  className={classes.close}
                  close={handleToggleEditModal}
                  description={CLOSE_BUTTON_DESCRIPTION}
                  fill="#0D74AF"
                  label={CLOSE_BUTTON_LABEL}
                  width={closeSVGDimension}
                  height={closeSVGDimension}
                  disableRipple={true}
                />
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center">
                <Typography
                  component="h1"
                  aria-live="polite"
                  variant={
                    width >= WIDTH_BREAKPOINT ? 'large1Light' : 'medium3Light'
                  }>
                  {RESERVATION_EDIT_TITLE}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} xl={5} className={classes.leftGridSection}>
            <Typography
              variant={
                width >= WIDTH_BREAKPOINT
                  ? 'medium2Semibold'
                  : 'medium1Semibold'
              }
              className={classes.subTitle}
              component="h2">
              {RESERVATION_EDIT_CURRENT_LABEL}
            </Typography>
            <Box pt={1} pb={4}>
              {reservation &&
                (width >= WIDTH_BREAKPOINT ? (
                  <LargeCard
                    venue={reservation.venue}
                    reservation={reservation}
                    showActionLinks={false}
                    showSeeVenueLink={false}
                  />
                ) : (
                  <SmallCard
                    reservation={reservation}
                    venue={reservation.venue}
                  />
                ))}
            </Box>
          </Grid>
          {renderEditReservationSection()}
        </Grid>
        <CancelEditModal
          modalOpen={modalOpen}
          handleCancelEdit={handleCancelEdit}
          handleToggleEditModal={handleToggleEditModal}
        />
      </PageSection>
    )
  );
};

export default EditReserve;
