import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { trackPageView } from '../../utils/useOneTag';

const TrackedRoute = ( props ) => {

  useEffect(() => {
    trackPageView(window.location.pathname);
  }, []);

  return (
    <Route
      {...props}
    />
  );
};

export default TrackedRoute;
