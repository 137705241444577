import { put, takeLatest, takeEvery, all } from 'redux-saga/effects';

import {
  listAvailability,
  getAvailability,
  listAvailabilityVenues,
  findAvailability,
} from '../../graphql/queries';
import * as ACTIONS from './AvailabilityAction';
import * as TYPES from './AvailabilityTypes';
import {
  callGraphqlWithToken,
  dispatchError,
  getVenuesByPage,
} from '../helpers';
import {
  generateEventAvailabilityRequestPayload,
  generateListSearchRequestPayload,
  generateMultipleAvailabilityRequestPayload,
} from './helpers';
import moment from 'moment';

export function* availabilityListRequestHandler(data) {
  try {
    const result = yield callGraphqlWithToken({
      query: listAvailability,
      variables: { ...data.payload },
    });
    yield put(
      ACTIONS.actionAvailabilityListSuccess(result.data.listAvailability)
    );
  } catch (e) {
    console.log(e);
    yield dispatchError(e.message);
    yield put(ACTIONS.actionAvailabilityListFail(e));
  }
}

export function* getAvailabilityRequestHandler(data) {
  try {
    const result = yield callGraphqlWithToken({
      query: getAvailability,
      variables: { ...data.payload },
    });
    yield put(
      ACTIONS.actionGetAvailabilitySuccess(result.data.getAvailability)
    );
  } catch (e) {
    console.log(e);
    yield dispatchError(e.message);
    yield put(ACTIONS.actionGetAvailabilityFail(e));
  }
}

function* getAvailabities(dates) {
  const results = yield all(
    dates.map((date) => {
      return callGraphqlWithToken({
        query: getAvailability,
        variables: { ...date },
      });
    })
  );
  return results.map((result) => result.data.getAvailability);
}

export function* getMultipleAvailabilityRequestHandler(data) {
  try {
    const requestPayload = generateMultipleAvailabilityRequestPayload(
      data.payload
    );
    const listAvailability = yield getAvailabities(requestPayload);
    yield put(ACTIONS.actionGetMultipleAvailabilitiesSuccess(listAvailability));
  } catch (e) {
    console.log(e);
    yield dispatchError(e.message);
    yield put(ACTIONS.actionGetMultipleAvailabilitiesFail(e));
  }
}

export function* findAvailabilityRequestHandler(data) {
  try {
    const result = yield callGraphqlWithToken({
      query: findAvailability,
      variables: { ...data.payload },
    });
    yield put(
      ACTIONS.actionFindAvailabilitySuccess(result.data.findAvailability)
    );
  } catch (e) {
    console.log(e);
    yield dispatchError(e.message);
    yield put(ACTIONS.actionFindAvailabilityFail(e));
  }
}


export function* loadDateAvailabilityRequest(data) {
  try {
    const result = yield callGraphqlWithToken({
      query: listAvailability,
      variables: { ...data.payload },
    });
    yield put(
      ACTIONS.actionLoadDateAvailabilitySuccess(result.data.listAvailability)
    );
  } catch (e) {
    console.log(e);
    yield dispatchError(e.message);
    yield put(ACTIONS.actionLoadDateAvailabilityFail);
  }
}

export function* getEventAvailabilityRequest(data) {
  try {
    const requestPayload = generateEventAvailabilityRequestPayload(
      data.payload
    );
    const listEventAvailabilities = yield getAvailabities(requestPayload);
    yield put(
      ACTIONS.actionGetEventAvailabilitySuccess(listEventAvailabilities)
    );
  } catch (e) {
    console.log(e);
    yield dispatchError(e.message);
    yield put(ACTIONS.actionGetEventAvailabilityFail);
  }
}

function* getListVenueAvailabities(payloads) {
  const results = yield all(
    payloads.map(payload => {
      return callGraphqlWithToken({
        query: listAvailabilityVenues,
        variables: { input: payload }
      });
    })
  );
  return results.map(result => result.data.listAvailabilityVenues);
} 

export function* listAvailabilityVenuesRequest(data) {
  try {
    const input = { ...data.payload };
    const { page, venues, sevenRoomsVenueIds } = input;
    const newPayload = { ...input };
    let venuesPayload = [];
    let copyOfVenuesInput = [];

    if (input.sevenRoomsVenueIds) {
      copyOfVenuesInput = [...sevenRoomsVenueIds];
      venuesPayload = getVenuesByPage(copyOfVenuesInput, page);
      newPayload.sevenRoomsVenueIds = venuesPayload;
    } else {
      copyOfVenuesInput = [...venues];
      venuesPayload = getVenuesByPage(copyOfVenuesInput, page);
      newPayload.venues = venuesPayload;
    }

    if (!venuesPayload || venuesPayload.length < 1) {
      yield put(ACTIONS.actionSetAvailabilityLoadingStatus(false));
      return;
    }

    const today = moment();
    newPayload.currentDate = today.format('YYYY-MM-DD');
    newPayload.currentTime = today.format('HH:mm');
    delete newPayload.page;

    const generatedListSearchRequestPayloads = generateListSearchRequestPayload(newPayload);
    const listVenueAvailabilities = yield getListVenueAvailabities(generatedListSearchRequestPayloads);
    yield put(ACTIONS.actionListAvailabilityVenuesProgress(
      listVenueAvailabilities.map(listVenueAvailability => listVenueAvailability[0])
    ));
    yield put(ACTIONS.actionSetAvailabilityLoadingStatus(false));
  } catch (e) {
    console.log(e);
    yield dispatchError(e.message);
    yield put(ACTIONS.actionListAvailabilityVenuesFail);
  }
}

export default function* availabilitysSaga() {
  yield takeLatest(
    TYPES.AVAILABILITY_LIST_REQUEST,
    availabilityListRequestHandler
  );
  yield takeLatest(
    TYPES.GET_AVAILABILITY_REQUEST,
    getAvailabilityRequestHandler
  );
  yield takeLatest(
    TYPES.GET_MULTIPLE_AVAILABILITIES_REQUEST,
    getMultipleAvailabilityRequestHandler
  );
  yield takeLatest(
    TYPES.FIND_AVAILABILITY_REQUEST,
    findAvailabilityRequestHandler
  );
  yield takeLatest(
    TYPES.LOAD_MORE_AVAILABILITIES,
    getAvailabilityRequestHandler
  );
  yield takeLatest(
    TYPES.LIST_AVAILABILITY_VENUES_REQUEST,
    listAvailabilityVenuesRequest
  );
  yield takeEvery(
    TYPES.LOAD_DATE_AVAILABILITY_REQUEST,
    loadDateAvailabilityRequest
  );
  yield takeEvery(
    TYPES.GET_EVENT_AVAILABILITY_REQUEST,
    getEventAvailabilityRequest
  );
}
