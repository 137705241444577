import { createMuiTheme } from '@material-ui/core/styles';

// ui
import palette from './palette';
import { breakpoints, applyFontBreakpoints } from './breakpoints';
import typography from './typography';
import gravityPalette from '../components/gravity/global/gravityPalette';

const theme = createMuiTheme({
  direction: 'ltr',
  spacing: (factor) => `${8 * factor}px`, // 1 point = 8px
  transitions: {
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      // most basic recommended timing
      standard: 300,
      // this is to be used in complex animations
      complex: 375,
      // recommended when something is entering screen
      enteringScreen: 225,
      // recommended when something is leaving screen
      leavingScreen: 195,
    },
  },
  shape: {
    borderRadius: 0,
  },
  overrides: {
    MuiCheckbox: {
      colorPrimary: {
        color: gravityPalette.checkbox.unchecked.active,
        '&$checked': {
          color: gravityPalette.checkbox.checked.active,
        },
        '&:hover': {
          color: gravityPalette.checkbox.unchecked.hover,
          '&$checked': {
            color: gravityPalette.checkbox.checked.hover,
          },
        },
        '&:disabled': {
          color: gravityPalette.checkbox.unchecked.disabled,
          '&$checked': {
            color: gravityPalette.checkbox.checked.disabled,
          },
        },
      },
    },
    MuiRadio: {
      colorSecondary: {
        '&$checked': {
          color: '#FEFE',
        },
      },
    },
    MuiSwitch: {
      colorSecondary: {
        '&$checked': {
          color: '#FEFEFE',
          '& + $bar': {
            backgroundColor: '#A0A0A0',
          },
        },
      },
    },
    MuiChip: {
      deleteIcon: {
        'html[dir="rtl"] &': {
          marginLeft: '8px',
        },
      },
    },
    MuiInputAdornment: {
      root: {
        'html[dir="rtl"] &': {
          marginLeft: '-8px',
        },
      },
    },
    MuiPaper: {
      root: {
        color: 'inherit',
      },
    },
    MuiStepper: {
      root: {
        padding: 0,
      },
    },
  },
  arrows: {
    expand: {
      cursor: 'pointer',
      float: 'right',
      position: 'relative',
      right: '15px',
      height: '50px',
    },
    collapse: {
      cursor: 'pointer',
      float: 'right',
      height: '40px',
      'html[dir="rtl"] &': {
        float: 'left',
      },
    },
    opened: {
      transform: 'rotate(270deg)',
    },
    closed: {
      transform: 'rotate(90deg)',
    },
  },
  buttons: {
    default: {
      fontSize: '0.9286rem',
      fontWeight: '500',
      minWidth: '100px',
      minHeight: 'auto',
      maxHeight: '32px',
      padding: '8px 20px',
    },

    blue: {
      backgroundColor: '#0b6eb5',
      color: '#fff',
      fontSize: '0.9286rem',
      fontWeight: '500',
      minWidth: '100px',
      minHeight: 'auto',
      maxHeight: '32px',
      padding: '8px 20px',
      '&:hover': {
        backgroundColor: '#127cbb',
      },
    },

    edit: {
      background: '#e8e6e0',
      fontSize: '0.9286rem',
      fontWeight: '500',
      minWidth: '100px',
      minHeight: 'auto',
      maxHeight: '32px',
      padding: '8px 20px',
      '& a': {
        color: '#2b2924',
        '&:hover': {
          textDecoration: 'none',
        },
      },
    },
  },
  typography,
  palette,
  breakpoints,
  gravityPalette,
});

applyFontBreakpoints(theme);

export default theme;
