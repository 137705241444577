import React, { useCallback } from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { SEARCH_RESTAURANTS_ALT_TEXT } from '../../../assets/copy';
import SearchSvg from '../../../assets/icons/search.svg';
import { actionSetSearchOverlayVisible } from '../../../store/Search/SearchAction';
import { focusVisible } from '../../../utils/ada/contrastStyles';
import { trackClick } from '../../../utils/useOneTag';

const useStyles = makeStyles({
  root: {
    ...focusVisible(),
  },
});

const SearchButton = ({ menuVisible = false }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const handleShowSearch = useCallback((e) => {
    trackClick(e, "app-bar-search", "Button");
    dispatch(actionSetSearchOverlayVisible(true));
  }, [dispatch]);

  if (menuVisible) return null;

  return (
    <IconButton
      className={classes.root}
      onClick={handleShowSearch}
      disableFocusRipple
      aria-label={SEARCH_RESTAURANTS_ALT_TEXT}
      data-cy="app-bar-search">
      <img src={SearchSvg} alt={SEARCH_RESTAURANTS_ALT_TEXT} />
    </IconButton>
  );
};

export default SearchButton;
