//AVAILABILITY list
export const AVAILABILITY_LIST_REQUEST = 'AVAILABILITY_LIST_REQUEST';
export const AVAILABILITY_LIST_SUCCESS = 'AVAILABILITY_LIST_SUCCESS';
export const AVAILABILITY_LIST_FAIL = 'AVAILABILITY_LIST_FAIL';

//get AVAILABILITY
export const GET_AVAILABILITY_REQUEST = 'GET_AVAILABILITY_REQUEST';
export const GET_AVAILABILITY_SUCCESS = 'GET_AVAILABILITY_SUCCESS';
export const GET_AVAILABILITY_FAIL = 'GET_AVAILABILITY_FAIL';


// MULTIPLE AVAILABILITIES
export const GET_MULTIPLE_AVAILABILITIES_REQUEST = 'GET_MULTIPLE_AVAILABILITIES_REQUEST';
export const GET_MULTIPLE_AVAILABILITIES_SUCCESS = 'GET_MULTIPLE_AVAILABILITIES_SUCCESS';
export const GET_MULTIPLE_AVAILABILITIES_FAIL = 'GET_MULTIPLE_AVAILABILITIES_FAIL';

//FIND AVAILABILITY
export const FIND_AVAILABILITY_REQUEST = 'FIND_AVAILABILITY_REQUEST';
export const FIND_AVAILABILITY_SUCCESS = 'FIND_AVAILABILITY_SUCCESS';
export const FIND_AVAILABILITY_FAIL = 'FIND_AVAILABILITY_FAIL';

//LOAD MORE AVAILABILITY
export const LOAD_DATE_AVAILABILITY_REQUEST = 'LOAD_DATE_AVAILABILITY_REQUEST';
export const LOAD_DATE_AVAILABILITY_SUCCESS = 'LOAD_DATE_AVAILABILITY_SUCCESS';
export const LOAD_DATE_AVAILABILITY_FAIL = 'LOAD_DATE_AVAILABILITY_FAIL';

//LOAD MORE AVAILABILITY LIST
export const LIST_AVAILABILITY_VENUES_REQUEST =
  'LIST_AVAILABILITY_VENUES_REQUEST';
export const LIST_AVAILABILITY_VENUES_PROGRESS =
  'LIST_AVAILABILITY_VENUES_PROGRESS';
export const LIST_AVAILABILITY_VENUES_CLEAR = 'LIST_AVAILABILITY_VENUES_CLEAR';
export const LIST_AVAILABILITY_VENUES_FAIL = 'LIST_AVAILABILITY_VENUES_FAIL';

// ADDING MORE AVAILABLE TIMESLOT TO AVAILABILITIES
export const ADD_TIMESLOT_TO_AVAILABILITY = 'ADD_TIMESLOT_TO_AVAILABILITY';
export const LOAD_MORE_AVAILABILITIES = 'LOAD_MORE_AVAILABILITIES';
export const SET_HAS_LOADED_ALL_AVAILABILITIES =
  'SET_HAS_LOADED_ALL_AVAILABILITIES';

//Remove Availability
export const CLEAR_AVAILABILITY = 'CLEAR_AVAILABILITY';
export const CLEAR_LIST_AVAILABILITIES = 'CLEAR_LIST_AVAILABILITIES';

//FAB
export const SET_FAB_AVAILABILITY_DATE = 'SET_FAB_AVAILABILITY_DATE';
export const SET_FAB_AVAILABILITY_TIME = 'SET_FAB_AVAILABILITY_TIME';
export const SET_FAB_AVAILABILITY_GUESTS = 'SET_FAB_AVAILABILITY_GUESTS';

export const SET_FAB_DISPLAY_VALUES = 'SET_FAB_DISPLAY_VALUES';

//DROPDOWN
export const SET_SUBMITTED_TICKETS = 'SET_SUBMITTED_TICKETS';
export const GET_EVENT_AVAILABILITY_REQUEST = 'GET_EVENT_AVAILABILITY_REQUEST';
export const GET_EVENT_AVAILABILITY_SUCCESS = 'GET_EVENT_AVAILABILITY_SUCCESS';
export const GET_EVENT_AVAILABILITY_FAIL = 'GET_EVENT_AVAILABILITY_FAIL';

//set loading status
export const SET_AVAILABILITY_LOADING_STATUS =
  'SET_AVAILABILITY_LOADING_STATUS';
