export function trackClick(event, name, elementType) {
  window.oneTag.track('link', {
    event_name: 'ui_interaction',
    ui_interaction_action: 'click',
    ui_interaction_element_id: event.currentTarget.id,
    ui_interaction_element_name: name,
    ui_interaction_element_type: elementType
  });
}

export function trackPageView(page_title) {
  window.oneTag.track('view', { event_name: 'page_view', page_title: `${page_title}` });
}
