import { React, useCallback } from 'react';
import { Box, Container, Grid, makeStyles } from '@material-ui/core';
import Divider from '../../overrides/Divider';
import Typography from '../../overrides/Typography';
import NavigationLink from '../NavigationLink';
import AppBarClose from '../AppBarClose';
import { useHistory, useLocation } from 'react-router';
import {
  MOBILE_NAVIGATION_MARKET_PREFIX,
  MOBILE_NAVIGATION_VIEW_ALL,
} from '../../../assets/copy';
import DirectionalButton from '../../buttons/DirectionalButton';
import { useSearchRedirect, mapToQueryParam } from '../../search/helpers';
import {
  actionSetSearchCurrentSessionId,
  actionSetSearchCurrentInputValue,
  actionSetSearchCurrentQuery,
} from '../../../store/Search/SearchAction';
import { getRandomString } from '../../../utils/useGeneratedKey';
import { useSelector, useDispatch } from 'react-redux';
import { BASE_SEARCH_PATHNAME } from '../../search/constants';
import { actionResetCurrentFilters } from '../../../store/Filter/FilterAction';
import palette from '../../../theme/palette';
import { MOBILE_NAVIGATION_LINKS } from '../../../utils/constants/Links';
import { trackClick } from '../../../utils/useOneTag';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100vh',
    background: theme.palette.background.white,
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 99,
  },
  container: {
    padding: theme.spacing(0, 3),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  footer: {
    backgroundColor: theme.palette.primary[60],
    boxSizing: 'border-box',
    bottom: 0,
    height: 120,
    left: 0,
    padding: theme.spacing(3.5),
    position: 'fixed',
    width: '100%',
  },
  footerText: {
    color: theme.palette.background.white,
  },
  viewAllRestaurants: {
    alignItems: 'center',
    display: 'flex',
    marginTop: theme.spacing(1.5),
    cursor: 'pointer',
    '& button': {
      padding: theme.spacing(0),
      '& p': {
        color: `${theme.palette.background.white} !important`,
      },
      '& span': {
        color: theme.palette.background.white,
      },
      '&:active': {
        '& p': {
          color: `${theme.palette.background.white} !important`,
        },
      },
    },
  },
  viewAllRestaurantsArrow: {
    marginLeft: theme.spacing(1.5),
  },
}));

const MobileMenu = ({ close, isOpen }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const redirectTo = useSearchRedirect();
  const markets = useSelector((state) => state.markets);

  const resetSearchQuery = useCallback(() => {
    dispatch(actionSetSearchCurrentInputValue(''));
    dispatch(actionSetSearchCurrentQuery(''));
  }, [dispatch]);

  const resetFilters = useCallback(
    () => dispatch(actionResetCurrentFilters()),
    [dispatch]
  );

  const handleMobileNavClick = (event, dataCy) => {
    trackClick(event, dataCy, "Link");
    close();
  }

  const handleViewAllRestaurants = useCallback(
    (sessionId) => {
      dispatch(actionSetSearchCurrentSessionId(sessionId));
      const pathname = BASE_SEARCH_PATHNAME;
      const search = mapToQueryParam({ sessionId });

      if (location.pathname !== pathname) {
        history.replace({ pathname, search }, { redirectTo });
      } else {
        resetFilters();
        resetSearchQuery();
        history.push({ pathname }, { redirectTo });
      }
    },
    [location, history, dispatch, redirectTo, resetFilters, resetSearchQuery]
  );

  return (
    <div className={classes.root} role="dialog" title="dialog">
      {isOpen && <AppBarClose autoFocus close={close} />}
      <Container className={classes.container}>
        <Grid
          className={classes.content}
          container
          direction="column"
          justify="flex-start">
          {MOBILE_NAVIGATION_LINKS.map((link, index) => (
            <>
              <NavigationLink
                key={link.path}
                label={link.label}
                path={link.path}
                data-cy={link.dataCy}
                onClick={(e) => {handleMobileNavClick(e, link.dataCy)}}
              />
              {index !== MOBILE_NAVIGATION_LINKS.length - 1 && <Divider />}
            </>
          ))}
        </Grid>
        <Box className={classes.footer}>
          <Typography className={classes.footerText}>
            {MOBILE_NAVIGATION_MARKET_PREFIX +
              (markets.market ? markets.market.name : '')}
          </Typography>
          <DirectionalButton
            classContainer={classes.viewAllRestaurants}
            text={MOBILE_NAVIGATION_VIEW_ALL}
            customHexColor={palette.text.secondary}
            onClickHandler={() => {
              close();
              handleViewAllRestaurants(getRandomString());
            }}
          />
        </Box>
      </Container>
    </div>
  );
};
export default MobileMenu;
