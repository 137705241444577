import * as TYPES from './AvailabilityTypes';
import defaultReservation from '../../pages/venue/helpers/defaultReservation';
import { SIGNOUT_USER_SUCCESS } from '../User/UserTypes';
import { combine7RVenueIdAndExperienceId } from '../../utils/availabilityHelper';

const { defaultDate, defaultTime, defaultGuests } = defaultReservation();

const initialState = {
  list: null,
  availability: null,
  listAvailabilityVenues: {},
  loadingMoreAvailabilities: false,
  loadingAvailability: false,
  selectedDate: defaultDate,
  selectedTime: defaultTime,
  selectedGuests: defaultGuests,
  submittedDate: defaultDate,
  submittedTime: defaultTime,
  submittedGuests: defaultGuests,
  submittedTickets: defaultGuests,
  paymentLoading: false,
  hasLoadedAllAvailabilities: false,
};

export default function availabilityReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case TYPES.GET_AVAILABILITY_REQUEST:
    case TYPES.FIND_AVAILABILITY_REQUEST: {
      return {
        ...state,
        paymentLoading: true,
        loadingAvailability: true,
      };
    }
    case TYPES.LOAD_DATE_AVAILABILITY_REQUEST:
    case TYPES.GET_EVENT_AVAILABILITY_REQUEST:
    case TYPES.GET_MULTIPLE_AVAILABILITIES_REQUEST:
    case TYPES.AVAILABILITY_LIST_REQUEST:
    case TYPES.LIST_AVAILABILITY_VENUES_REQUEST:
      return {
        ...state,
        loadingAvailability: true,
      };
    case TYPES.LOAD_MORE_AVAILABILITIES:
      return {
        ...state,
        loadingMoreAvailabilities: true,
      };
    case TYPES.ADD_TIMESLOT_TO_AVAILABILITY:
      const updatedList = state.list?.map((availability) => {
        if (payload.date === availability.date) {
          const availabilityClone = { ...availability };
          const addTimeslots =
            availability.timeslots.length === 0
              ? payload.timeslots.slice(0)
              : payload.timeslots.slice(1); // if there's no availability no elements in array [] add all of the timeslots [0 ~ n] else if there's atleast one [7:00 am] in the timeslots don't include [7:00 am] = [1 ~ n],
          availabilityClone.timeslots = [
            ...availability.timeslots,
            ...addTimeslots,
          ]; //adding the two timeslots availability.timeslots and payload.timeslots. I'm annoyed that I have to do a [7:30, 7:45].slice(1) to get rid of 7:30 since it's being returned by 7R when I use 7:30pm as startTime, I thought we will be given the next time slot
          return availabilityClone;
        }
        return availability;
      });
      return {
        ...state,
        list: updatedList,
        loadingMoreAvailabilities: false,
      };
    case TYPES.SET_AVAILABILITY_LOADING_STATUS:
      return {
        ...state,
        loadingAvailability: payload,
      };
    case TYPES.AVAILABILITY_LIST_SUCCESS:
    case TYPES.LOAD_DATE_AVAILABILITY_SUCCESS:
      return {
        ...state,
        list: payload,
        loadingAvailability: false,
      };
    case TYPES.GET_EVENT_AVAILABILITY_SUCCESS:
    case TYPES.GET_MULTIPLE_AVAILABILITIES_SUCCESS:
      return {
        ...state,
        list: payload,
        loadingAvailability: false,
      };
    case TYPES.LIST_AVAILABILITY_VENUES_CLEAR:
      return {
        ...state,
        listAvailabilityVenues: {},
      };
    case TYPES.LIST_AVAILABILITY_VENUES_PROGRESS:
      return {
        ...state,
        listAvailabilityVenues: payload.reduce(
          (venues, venue) => ({
            ...venues,
            [combine7RVenueIdAndExperienceId(venue)]: venue.availability,
          }),
          { ...state.listAvailabilityVenues }
        ),
      };
    case TYPES.SET_HAS_LOADED_ALL_AVAILABILITIES: {
      return {
        ...state,
        hasLoadedAllAvailabilities: payload,
      };
    }
    case TYPES.LIST_AVAILABILITY_VENUES_FAIL:
    case TYPES.GET_MULTIPLE_AVAILABILITIES_FAIL:
    case TYPES.GET_EVENT_AVAILABILITY_FAIL:
      return {
        ...state,
        loadingAvailability: false,
      };
    case TYPES.GET_AVAILABILITY_SUCCESS:
    case TYPES.FIND_AVAILABILITY_SUCCESS:
      return {
        ...state,
        availability: payload,
        loadingAvailability: false,
        paymentLoading: false,
        loadingMoreAvailabilities: false,
      };
    case TYPES.SET_FAB_AVAILABILITY_DATE: {
      return {
        ...state,
        selectedDate: payload?.selectedDate || defaultDate,
      };
    }
    case TYPES.SET_FAB_AVAILABILITY_TIME: {
      return {
        ...state,
        selectedTime: payload?.selectedTime || defaultTime,
      };
    }
    case TYPES.SET_FAB_AVAILABILITY_GUESTS: {
      return {
        ...state,
        selectedGuests: payload?.selectedGuests || defaultGuests,
      };
    }
    case TYPES.SET_FAB_DISPLAY_VALUES: {
      sessionStorage.setItem('defaultDate', payload?.submittedDate);
      sessionStorage.setItem('defaultTime', payload?.submittedTime);
      sessionStorage.setItem('defaultGuests', payload?.submittedGuests);
      return {
        ...state,
        submittedDate: payload?.submittedDate || defaultDate,
        submittedTime: payload?.submittedTime || defaultTime,
        submittedGuests: payload?.submittedGuests || defaultGuests,
      };
    }
    case TYPES.SET_SUBMITTED_TICKETS: {
      return {
        ...state,
        submittedTickets: payload?.submittedTickets || defaultGuests,
      };
    }
    case TYPES.CLEAR_AVAILABILITY: {
      return {
        ...state,
        availability: null,
      };
    }
    case TYPES.CLEAR_LIST_AVAILABILITIES: {
      return {
        ...state,
        list: null,
      };
    }
    case SIGNOUT_USER_SUCCESS:
      return initialState;
    default:
      return {
        ...state,
        loadingAvailability: false,
      };
  }
}
