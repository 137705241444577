import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Item } from '@react-stately/collections';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SEARCH_ACTION_BUTTON_TEXT, SEARCH_TITLE } from '../../assets/copy';
import AppBarClose from '../../components/core/AppBarClose';
import FilledButton from '../../components/core/FilledButton';
import Typography from '../../components/overrides/Typography';
import DesktopSearch from '../../components/search/DesktopSearch';
import {
  handleCuisineSelect,
  useResults,
  useSearchChangeHandler,
  useSearchRedirect,
  useSelectionHandler,
  useSuggestions,
} from '../../components/search/helpers';
import {
  BASE_SEARCH_INPUT_PROPS,
  SearchComboBox,
  SearchFormContainer,
  SearchSelect,
} from '../../components/search/SearchForm';
import { actionResetCurrentFilters } from '../../store/Filter/FilterAction';
import {
  actionCacheMarketId,
  actionGetMarketRequest,
  actionResetCachedMarketId,
} from '../../store/Markets/MarketsAction';
import {
  actionAddRecentSearch,
  actionSetSearchCachedQuery,
  actionSetSearchCurrentInputValue,
  actionSetSearchCurrentQuery,
  actionSetSearchOverlayVisible,
} from '../../store/Search/SearchAction';
import { useComponentWillUnmount } from '../../utils/useComponentWillUnmount';
import { useIsMounted } from '../../utils/useIsMounted';
import { useViewport } from '../../utils/useViewport';
import { focusVisible } from '../../utils/ada/contrastStyles';
import FocusTrap from 'focus-trap-react';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    background: theme.palette.background.white,
    top: 0,
    left: 0,
  },
  scroll: {
    display: 'grid',
    gridTemplateRows: 'min-content',
    overflowY: 'scroll',
    height: 'calc(100% - 200px)',
    [theme.breakpoints.up('xl')]: {
      height: '100%',
    },
  },
  mobileTitle: {
    padding: theme.spacing(3, 3, 0),
  },
  mobileFooter: ({ hideSearchButton }) => ({
    background: theme.palette.white,
    borderTop: `1px solid ${theme.palette.gray[30]}`,
    boxSizing: 'border-box',
    bottom: hideSearchButton ? -100 : 0,
    padding: theme.spacing(2, 3),
    position: 'fixed',
    width: '100%',
  }),
  searchButton: {
    ...focusVisible(),
  },
}));

const widthBreakpoint = 960;

const SearchOverlay = () => {
  const { width } = useViewport();
  const isMobile = useMemo(() => width < widthBreakpoint, [width]);
  const dispatch = useDispatch();
  const history = useHistory();
  const redirectTo = useSearchRedirect();
  const isMounted = useIsMounted();
  const { cachedMarketId, market } = useSelector((state) => state.markets);
  const { overlayVisible, currentQuery, currentSearchInputValue } = useSelector(
    (state) => state.search
  );
  const [hideSearchButton, setHideSearchButton] = useState(false);
  const {
    resetSuggestions,
    suggestedCuisines,
    suggestedRestaurants,
    updateSuggestions,
  } = useSuggestions();
  const results = useResults({ suggestedCuisines, suggestedRestaurants });
  const classes = useStyles({ hideSearchButton });

  useComponentWillUnmount(() => {
    if (currentQuery && currentQuery !== currentSearchInputValue) {
      dispatch(actionSetSearchCurrentInputValue(currentQuery));
    }
    dispatch(actionResetCachedMarketId());
  });

  useEffect(() => {
    return history.listen(() => {
      if (overlayVisible) {
        dispatch(actionSetSearchOverlayVisible(false));
      }
    });
  }, [dispatch, history, overlayVisible]);

  const closeOverlay = useCallback(() => {
    if (cachedMarketId && cachedMarketId !== market?.id) {
      dispatch(actionGetMarketRequest({ id: cachedMarketId }));
    }

    dispatch(actionSetSearchOverlayVisible(false));
  }, [cachedMarketId, dispatch, market?.id]);

  const setInitial = () => {
    dispatch(actionSetSearchOverlayVisible(false));
    history.push('/search', { redirectTo });
  };

  const selectCuisine = (cuisine) => {
    handleCuisineSelect(cuisine, dispatch);
    setInitial();
  };

  const handleSelect = useSelectionHandler({
    suggestedCuisines,
    suggestedRestaurants,
    selectCuisine,
  });

  const handleSearchChange = useSearchChangeHandler({
    update: updateSuggestions,
    reset: resetSuggestions,
  });

  const setCurrentSearchFromSearchBox = () => {
    setHideSearchButton(false);
    dispatch(actionSetSearchCurrentQuery(currentSearchInputValue));
    dispatch(actionResetCurrentFilters());
    dispatch(actionAddRecentSearch(currentSearchInputValue));
    setInitial();
  };

  const handleMarketChange = (event) => {
    const id = event.target.value;
    dispatch(actionGetMarketRequest({ id }));
  };

  const resetSearch = () => {
    dispatch(actionSetSearchCurrentInputValue(''));
    resetSuggestions();
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => (document.body.style.overflow = 'unset');
  }, []);

  useEffect(() => {
    if (!isMounted && market?.id != null) {
      dispatch(actionCacheMarketId(market.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    resetSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => dispatch(actionSetSearchCachedQuery(currentQuery)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentQuery]
  );

  const searchButton = () => (
    <FilledButton
      text={SEARCH_ACTION_BUTTON_TEXT}
      onClick={setCurrentSearchFromSearchBox}
      style={classes.searchButton}
    />
  );

  const searchComboBox = () => (
    <SearchComboBox
      {...BASE_SEARCH_INPUT_PROPS}
      className={classes.scroll}
      handleChange={handleSearchChange}
      handleSearch={setCurrentSearchFromSearchBox}
      handleReset={resetSearch}
      onInputChange={handleSelect}>
      {results.map((result) => (
        <Item key={result.id}>{result.name}</Item>
      ))}
    </SearchComboBox>
  );

  const searchSelect = (props) => (
    <SearchSelect handleChange={handleMarketChange} {...props} />
  );

  return (
    <FocusTrap active={overlayVisible}>
      <Box className={classes.root}>
        <AppBarClose close={closeOverlay} isMobile={isMobile} />
        <Box className={classes.scroll}>
          {!isMobile ? (
            <DesktopSearch
              searchForm={
                <SearchFormContainer>
                  {searchComboBox()}
                  {searchSelect()}
                </SearchFormContainer>
              }
              searchButton={searchButton()}
              showTitle={true}
            />
          ) : (
            <>
              <Typography
                className={classes.mobileTitle}
                component="h1"
                variant="medium3Light"
                aria-live="polite">
                {SEARCH_TITLE}
              </Typography>
              <SearchFormContainer>
                {searchComboBox()}
                {searchSelect({
                  handleBlur: () => setHideSearchButton(false),
                  handleFocus: () => setHideSearchButton(true),
                })}
              </SearchFormContainer>
            </>
          )}
        </Box>
        {isMobile && (
          <Box className={classes.mobileFooter}>{searchButton()}</Box>
        )}
      </Box>
    </FocusTrap>
  );
};

export default SearchOverlay;
