import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import typography from '../../theme/typography';
import clsx from 'clsx';
import { READ_MORE, SHOW_LESS } from '../../assets/copy';
import generateSafeHtmlTags from '../../utils/generateSafeHtmlTags';
import { Typography } from '@material-ui/core';
import { useCopy } from '../../utils/useCopy';
import { enterKeyPressHandler } from '../../utils/keyPresses';

const LINES_LIMIT = 3;

const useStyles = makeStyles((theme) => ({
  readMoreLess: {
    color: theme.palette.interactionBlue[50],
    cursor: 'pointer',
    ...typography.medium1Semibold,
  },
  overflowContainer: {
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': ({ linesLimit }) => linesLimit,
    '-webkit-box-orient': 'vertical',
    position: 'relative',
  },
  readMoreLessContainer: {
    marginTop: theme.spacing(2.5),
  },
}));

const ReadMoreReadLess = ({
  linesLimit = LINES_LIMIT,
  children, //String only. No component allowed
  readMoreLessStyle,
  readMoreLessClassName,
}) => {
  const classes = useStyles({ linesLimit });
  const [buttonCTA, setButtonCTA] = useState(READ_MORE);
  const [clickedShowMore, setClickedShowMore] = useState(false);
  const [isOverflow, setIsOverflow] = useState(false);
  const [ref, setRef] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [description, setDescription] = useState(null);
  const linkTag = '<a';
  const linkTagIndex = children.indexOf(linkTag);
  const shortDescription = children.substring(
    0,
    linkTagIndex > 0 ? linkTagIndex : children.length
  );
  const { getCopy } = useCopy();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (ref) {
      const { clientWidth, scrollWidth, clientHeight, scrollHeight } = ref;
      const hasOverflow =
        scrollHeight > clientHeight || scrollWidth > clientWidth;
      setIsOverflow(hasOverflow);
      if (hasOverflow && !clickedShowMore) setClickedShowMore(false);
    }
  });

  useEffect(() => {
    if (isOverflow) {
      setButtonCTA(READ_MORE);
    }
  }, [isOverflow]);

  useEffect(() => {
    setDescription(shortDescription);
  }, [shortDescription]);

  const handleToggleOverflowText = () => {
    const containerClass = ref.classList.toString();
    const hasOverflowClass = containerClass.includes(classes.overflowContainer);
    const overflowClassArray = classes.overflowContainer.split(' ');

    if (hasOverflowClass) {
      ref.classList.remove(...overflowClassArray);
      setButtonCTA(SHOW_LESS);
      setClickedShowMore(true);
      setExpanded(true);
      setDescription(children);
    } else {
      ref.classList.add(...overflowClassArray);
      setButtonCTA(READ_MORE);
      setClickedShowMore(false);
      setExpanded(false);
      setDescription(shortDescription);
    }
  };
  const onKeyPressHandler = enterKeyPressHandler && handleToggleOverflowText;

  const ToggleReadMoreLess = () =>
    (isOverflow || clickedShowMore) && (
      <span
        aria-expanded={expanded}
        aria-label={getCopy('READ_MORE_SHOW_LESS_SCREEN_READER_TEXT', {
          amount: expanded ? 'limited' : 'entire',
        })}
        className={clsx(classes.readMoreLess, readMoreLessClassName)}
        role="button"
        tabIndex={0}
        style={readMoreLessStyle}
        onKeyPress={onKeyPressHandler}
        onClick={handleToggleOverflowText}>
        {buttonCTA}
      </span>
    );

  return (
    <>
      <div className={classes.overflowContainer} ref={setRef}>
        <Typography
          dangerouslySetInnerHTML={generateSafeHtmlTags(description)}
          aria-label={description}
        />
      </div>
      <div className={classes.readMoreLessContainer}>
        {<ToggleReadMoreLess />}
      </div>
    </>
  );
};

export default ReadMoreReadLess;
