import * as types from './VenuesTypes';

//set filtered, paged Venues and totals
export const actionSetVenues = (payload) => ({
  type: types.SET_VENUES,
  payload,
});

//find Venues by market ID or keyword, filters afterward
export const actionFindVenuesRequest = ({ filters, keyword, marketId }) => ({
  type: types.FIND_VENUES_REQUEST,
  payload: { filters, keyword, marketId },
});

//find Venues by market ID or keyword, filters afterward
export const actionFindVenuesFail = (payload) => ({
  type: types.FIND_VENUES_FAIL,
  payload,
});

//get Venue
export const actionGetVenueRequest = (payload) => ({
  type: types.GET_VENUE_REQUEST,
  payload,
});

export const actionGetVenueSuccess = (payload) => ({
  type: types.GET_VENUE_SUCCESS,
  payload,
});

export const actionGetVenueFail = (payload) => ({
  type: types.GET_VENUE_FAIL,
  payload,
});

//get Events
export const actionGetEventsRequest = (payload) => ({
  type: types.GET_EVENTS_REQUEST,
  payload,
});

export const actionGetEventsSuccess = (payload) => ({
  type: types.GET_EVENTS_SUCCESS,
  payload,
});

export const actionGetEventsFail = (payload) => ({
  type: types.GET_EVENTS_FAIL,
  payload,
});

//reset Venues
export const actionResetVenueList = () => ({
  type: types.RESET_VENUE_LIST,
});

//search Venues
export const actionSearchVenuesRequest = (payload) => ({
  type: types.SEARCH_VENUES_REQUEST,
  payload,
});

export const actionSearchVenuesSuccess = (payload) => ({
  type: types.SEARCH_VENUES_SUCCESS,
  payload,
});

export const actionSearchVenuesFail = (payload) => ({
  type: types.SEARCH_VENUES_FAIL,
  payload,
});

//Venue list by market ID
export const actionVenuesByMarketRequest = (payload) => ({
  type: types.VENUES_BY_MARKET_REQUEST,
  payload,
});

export const actionVenuesByMarketSuccess = (payload) => ({
  type: types.VENUES_BY_MARKET_SUCCESS,
  payload,
});

export const actionVenuesByMarketFail = (payload) => ({
  type: types.VENUES_BY_MARKET_FAIL,
  payload,
});
