import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Card, CardContent, CardMedia } from '@material-ui/core';
import SignatureSvg from '../../assets/icons/grv-signature.svg';
import Typography from '../overrides/Typography';
import ExclusiveAlert from '../../pages/home/ExclusiveAlert';
import {
  EXCLUSIVE_ALERT_TITLE,
  LEARN_MORE_BUTTON_TEXT,
  ABOUT_THE_TEXT,
  SIGNATURE_COLLECTION_TEXT,
} from '../../assets/copy';
import { enterKeyPressHandler } from '../../utils/keyPresses';

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.secondary[60]}`,
    padding: theme.spacing(2, 3),
    width: 'auto',
    '&:first-of-type': {
      paddingTop: 0,
    },
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(0, 3, 2),
    },
    [theme.breakpoints.up('xxl')]: {
      padding: theme.spacing(0, 0, 2),
    },
  },
  wrapper: {
    display: 'grid',
    gridColumnGap: theme.spacing(2),
    gridTemplateColumns: 'auto 1fr',
  },
  image: {
    height: 48,
    width: 48,
  },
  content: {
    alignSelf: 'center',
    '&:last-child': {
      padding: 0,
    },
  },
  link: {
    display: 'inline',
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));

const ExclusiveTagging = () => {
  const classes = useStyles();
  const [isExclusiveAlertOpened, setIsExclusiveAlertOpened] = useState(false);

  const handleCloseExclusiveModal = useCallback(() => {
    setIsExclusiveAlertOpened(false);
  }, []);

  const handleOpenExclusiveModal = useCallback(() => {
    setIsExclusiveAlertOpened(true);
  }, []);

  const onKeyPressHandler = enterKeyPressHandler && handleOpenExclusiveModal;

  const getCustomAriaLabel = () => {
    return `${LEARN_MORE_BUTTON_TEXT} ${ABOUT_THE_TEXT} ${SIGNATURE_COLLECTION_TEXT}`;
  };
  return (
    <Card className={classes.root} elevation={0}>
      <Box className={classes.wrapper}>
        <CardMedia className={classes.image} image={SignatureSvg} />
        <CardContent className={classes.content}>
          <Typography variant="tiny1Normal">
            {EXCLUSIVE_ALERT_TITLE + ' '}
            <Typography
              variant="tiny1Normal"
              className={classes.link}
              onClick={handleOpenExclusiveModal}
              onKeyPress={onKeyPressHandler}
              role="button"
              aria-label={getCustomAriaLabel()}
              tabIndex={0}>
              {LEARN_MORE_BUTTON_TEXT}
            </Typography>
            <ExclusiveAlert
              isOpen={isExclusiveAlertOpened}
              handleCloseAlert={handleCloseExclusiveModal}
            />
          </Typography>
          <ExclusiveAlert
            isOpen={isExclusiveAlertOpened}
            handleCloseAlert={handleCloseExclusiveModal}
          />
        </CardContent>
      </Box>
    </Card>
  );
};

export default ExclusiveTagging;
