import React, { useState } from 'react';
import Reserve from './ReservePage';
import {
  EDIT_PAYMENT_BUTTON_TITLE,
  RESERVATION_PAYMENT_EDIT_HELPER_TEXT,
  RESERVATION_PAYMENT_EDIT_TEXT,
} from '../../assets/copy';
import DefaultPaymentEditView from '../profile/DefaultPaymentEditView';
import { useDispatch } from 'react-redux';
import { actionSetPaymentAccount } from '../../store/Reservations/ReservationsAction';
import { PAYMENT_METHOD_COMPONENT_TYPES } from '../profile/paymentComponentTypes';

const ReservationPageWrapper = (props) => {
  const [editView, setEditView] = useState('');
  const dispatch = useDispatch();

  const handleSavePaymentMethod = (accountDetails) => {
    dispatch(actionSetPaymentAccount(accountDetails));
    setEditView(null);
  };

  const setEditPaymentView = () => {
    setEditView(
      <DefaultPaymentEditView
        handleBack={() => setEditView(null)}
        title={EDIT_PAYMENT_BUTTON_TITLE}
        type={PAYMENT_METHOD_COMPONENT_TYPES.EDIT_VIEW}
        saveButtonText={RESERVATION_PAYMENT_EDIT_TEXT}
        saveButtonHelperText={RESERVATION_PAYMENT_EDIT_HELPER_TEXT}
        handleSavePayment={handleSavePaymentMethod}
      />
    );
  };

  return (
    <>{editView || <Reserve onEditPayment={setEditPaymentView} {...props} />}</>
  );
};

export default ReservationPageWrapper;
