//paginate VENUEs, set totals
export const SET_VENUES = 'SET_VENUES';

//find VENUEs
export const FIND_VENUES_REQUEST = 'FIND_VENUES_REQUEST';
export const FIND_VENUES_FAIL = 'FIND_VENUES_FAIL';

//get VENUE
export const GET_VENUE_REQUEST = 'GET_VENUE_REQUEST';
export const GET_VENUE_SUCCESS = 'GET_VENUE_SUCCESS';
export const GET_VENUE_FAIL = 'GET_VENUE_FAIL';

//get EVENTS
export const GET_EVENTS_REQUEST = 'GET_EVENTS_REQUEST';
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS';
export const GET_EVENTS_FAIL = 'GET_EVENTS_FAIL';

//reset VENUE list
export const RESET_VENUE_LIST = 'RESET_VENUE_LIST';

//search VENUEs by keyword and market ID, pages and filters afterward
export const SEARCH_VENUES_REQUEST = 'SEARCH_VENUES_REQUEST';
export const SEARCH_VENUES_SUCCESS = 'SEARCH_VENUES_SUCCESS';
export const SEARCH_VENUES_FAIL = 'SEARCH_VENUES_FAIL';

//VENUE list by market ID, pages and filters afterward
export const VENUES_BY_MARKET_REQUEST = 'VENUES_BY_MARKET_REQUEST';
export const VENUES_BY_MARKET_SUCCESS = 'VENUES_BY_MARKET_SUCCESS';
export const VENUES_BY_MARKET_FAIL = 'VENUES_BY_MARKET_FAIL';
