import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { makeStyles } from '@material-ui/core';
import RightArrowIcon from '../../../assets/icons/rightArrowCarousel.svg';
import LeftArrowIcon from '../../../assets/icons/leftArrowCarousel.svg';
import { useViewport } from '../../../utils/useViewport';
import { breakpoints } from '../../../theme/breakpoints';
import clsx from 'clsx';
import DirectionalButton from '../../buttons/DirectionalButton';
import { WIDTH_BREAKPOINT } from '../../../utils/constants/Breakpoints';
import { VIEW_NEXT_TEXT, VIEW_PREVIOUS_TEXT } from '../../../assets/copy';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    padding: 0,
  },
  galleryList: {
    display: 'flex',
    overflowX: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none' /* hide scrollbar on IE and Edge */,
    scrollbarWidth: 'none' /* hide scrollbar on Firefox */,
    scrollBehavior: 'smooth',
    padding: ({ shadowPadding }) =>
      shadowPadding ? `10px ${shadowPadding}px` : 0,
    margin: ({ shadowPadding }) => shadowPadding && `0 -${shadowPadding}px`,
    columnGap: theme.spacing(2),
    [theme.breakpoints.up('xl')]: {
      columnGap: theme.spacing(4),
    },
    position: 'relative',
  },
  hidden: {
    width: ({ hiddenWidth }) => (hiddenWidth ? hiddenWidth : theme.spacing(1)),
    minWidth: ({ hiddenWidth }) =>
      hiddenWidth ? hiddenWidth : theme.spacing(1),
    visibility: 'hidden',
    display: 'inline-block',
  },
  arrowButton: {
    borderRadius: '50%',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
  },
  leftArrowButton: {
    left: '-24px',
  },
  rightArrowButton: {
    right: '-24px',
  },
}));

const columnGapByWidth = (width, customGapFactor) => {
  const factors = {
    xl: 4,
    default: 2,
  };
  if (customGapFactor) {
    return customGapFactor * 8;
  }
  if (width >= breakpoints.values['xl']) {
    return factors.xl * 8;
  }
  return factors.default * 8;
};

/**
 *  Carousel core component.
 *  @param {FC} children - React component
 *  @param {string} buttonClassName - Custom button's className
 *  @param {boolean} useDirectionalButton - use directional button instead of arrow images
 *  @param {string} leftArrowImage - Custom left arrow image. Will be ignored if useDirectionalButton is true.
 *  @param {string} leftArrowClassName - Custom left arrow className. Is applied to directional button arrow if useDirectionalButton is true.
 *  @param {string} rightArrowImage - Custom right arrow image. Will be ignored if useDirectionalButton is true.
 *  @param {string} rightArrowClassName - Custom right arrow className. Is applied to directional button arrow if useDirectionalButton is true.
 *  @param {number} shadowPadding - Overflow hides any shadow outside of carousel. This prop is used to add padding and
 *  show the shadow of the items. Note: It is also used to calculate scrolling values.
 *  @param {string} galleryClass - Classname that is used to add or override default gallery styling.
 *  @param {string} containerClass - Classname that is used to add or override default container styling.
 *  @param {number} defaultItemIndex - Item's index to centered when first rendered. Array of children starts with 0 index.
 *  @param {number} hiddenWidth - Width of the hidden element. This element is used to fix right overflow padding problem for IOS.
 *  It is used to calculate the width using theme spacing. I.E; hiddenWidth = 1 => theme.spacing(1)
 *  @param {number} customGapFactor - Custom gap factor to calculate gap width.
 *  @param {number} breakpointWidth - width that determines if the component is mobile.
 *  @param {boolean} forceUpdatePosition - prop to force carousel update position. This is to circumvent a bug where on
 *  mobile, carousel doesn't update position even when setPosiion is called.
 * */
const Carousel = ({
  children,
  buttonClassName,
  useDirectionalButton = false,
  leftArrowImage = LeftArrowIcon,
  leftArrowClassName,
  rightArrowImage = RightArrowIcon,
  rightArrowClassName,
  shadowPadding = 0,
  galleryClass,
  containerClass,
  defaultItemIndex,
  hiddenWidth,
  customGapFactor,
  breakpointWidth = WIDTH_BREAKPOINT,
  elementName = '',
  forceUpdatePosition,
}) => {
  const classes = useStyles({ shadowPadding, hiddenWidth });
  const { width } = useViewport();
  const ref = useRef(null);
  const isMobile = width < breakpointWidth;
  const galleryClassName = clsx([classes.galleryList, galleryClass]);
  const containerClassName = clsx([classes.container, containerClass]);
  const [hasMultipleSlides, setHasMultipleSlides] = useState(false);
  const [position, setPosition] = useState(0);
  const [maxScrollWidth, setMaxScrollWidth] = useState(0);
  const [scrollUnit, setScrollUnit] = useState(0);
  const [overflowAmount, setOverflowAmount] = useState(0);

  const showArrows = useMemo(
    () => !isMobile && hasMultipleSlides,
    [isMobile, hasMultipleSlides]
  );
  const showRightArrow = useMemo(
    () => showArrows && position < maxScrollWidth,
    [showArrows, maxScrollWidth, position]
  );
  const showLeftArrow = useMemo(
    () => showArrows && position > 0,
    [showArrows, position]
  );

  const handleScrollLeft = () => {
    const clientWidth = subtractShadowPadding(ref?.current?.clientWidth);
    const scrollWidth = subtractShadowPadding(ref?.current?.scrollWidth);
    const lastScrollLeft = Math.ceil(scrollWidth - clientWidth);
    const reachedRightEdge =
      Math.ceil(ref?.current?.scrollLeft) === lastScrollLeft;

    setPosition((prevState) => {
      if (reachedRightEdge) {
        const itemWidth = ref.current.childNodes[0].clientWidth;
        const gapWidth = columnGapByWidth(width, customGapFactor);

        return position + itemWidth + gapWidth - scrollUnit - overflowAmount;
      }

      return prevState - scrollUnit < 0 ? 0 : Math.ceil(prevState - scrollUnit);
    });
  };

  const handleScrollRight = () => {
    setPosition((prevState) =>
      prevState + scrollUnit > maxScrollWidth
        ? maxScrollWidth
        : Math.ceil(prevState + scrollUnit)
    );
  };

  const findDefaultItemIndexPosition = (index) => {
    if (!index || !ref.current) return 0;
    const clientWidth = subtractShadowPadding(ref.current.clientWidth);
    const scrollWidth = subtractShadowPadding(ref.current.scrollWidth);
    const lastScrollLeft = scrollWidth - clientWidth;

    const itemWidth = ref.current.childNodes[isMobile ? 1 : 0].clientWidth;
    const gapWidth = columnGapByWidth(width, customGapFactor);
    const hiddenItemWidth = isMobile
      ? ref.current.childNodes[0].clientWidth
      : 0;
    const columGaps = (isMobile ? index + 1 : index) * gapWidth;

    const positionOfItem =
      hiddenItemWidth + index * itemWidth + columGaps + itemWidth / 2;

    const halfViewWidth = clientWidth / 2;
    const positionDifference = positionOfItem - halfViewWidth;

    if (positionDifference < 0) return 0;
    if (positionDifference > lastScrollLeft) return lastScrollLeft;
    return positionOfItem - halfViewWidth;
  };

  const subtractShadowPadding = (totalWidth) =>
    totalWidth && (totalWidth - shadowPadding * 2 ?? 0);

  const preventScroll = useCallback((e) => {
    if (e.deltaX !== 0) {
      e.preventDefault();
      e.stopPropagation();
    }

    return false;
  }, []);

  useEffect(() => {
    const elem = document.getElementsByClassName(galleryClassName)[0];

    elem.addEventListener('wheel', preventScroll, { passive: false });

    return () =>
      elem.removeEventListener('wheel', preventScroll, { passive: false });
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => {
    if (!ref.current) return;
    const clientWidth = subtractShadowPadding(ref.current.clientWidth);
    const scrollWidth = subtractShadowPadding(ref.current.scrollWidth);
    const itemWidth = ref.current.childNodes[0].clientWidth;
    const gapWidth = columnGapByWidth(width, customGapFactor);
    const numberOfItemsInView = Math.ceil(
      (clientWidth + gapWidth) / (itemWidth + gapWidth)
    );
    const numberOfItemsToScroll = numberOfItemsInView - 1;
    const hasMultipleSlides = children?.length - numberOfItemsInView > 0;
    const maxScrollLimit = Math.ceil(scrollWidth - clientWidth);
    const scrollStepValue =
      numberOfItemsInView * itemWidth + numberOfItemsInView * gapWidth;
    const overflowValue =
      numberOfItemsInView * itemWidth +
      numberOfItemsToScroll * gapWidth -
      clientWidth;
    const scrollStepValueIfOverflow =
      numberOfItemsToScroll * itemWidth + numberOfItemsToScroll * gapWidth;
    const scrollValue = overflowValue
      ? scrollStepValueIfOverflow
      : scrollStepValue;

    setHasMultipleSlides(hasMultipleSlides);
    setMaxScrollWidth(maxScrollLimit);
    setScrollUnit(scrollValue);
    setOverflowAmount(overflowValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children?.length]);

  useLayoutEffect(() => {
    if (defaultItemIndex >= 0) {
      setPosition(findDefaultItemIndexPosition(defaultItemIndex));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultItemIndex]);

  useLayoutEffect(() => {
    if (!ref.current) return;

    ref.current.scrollLeft = position;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceUpdatePosition, position]);

  return (
    <div className={containerClassName}>
      <div className={galleryClassName} ref={ref}>
        {isMobile && <div className={classes.hidden}>&nbsp;</div>}
        {children && children.length > 0 && children}
        {isMobile && <div className={classes.hidden}>&nbsp;</div>}
      </div>
      {useDirectionalButton && showLeftArrow && (
        <DirectionalButton
          isRippleDisabled
          classContainer={clsx(
            classes.leftArrowButton,
            classes.arrowButton,
            leftArrowClassName
          )}
          buttonClassName={buttonClassName}
          onClickHandler={handleScrollLeft}
          direction={'left'}
          text={null}
          aria-label={`${VIEW_PREVIOUS_TEXT} ${elementName}`}
        />
      )}
      {!useDirectionalButton && showLeftArrow && (
        <img
          src={leftArrowImage}
          onClick={handleScrollLeft}
          className={clsx(
            classes.leftArrowButton,
            classes.arrowButton,
            leftArrowClassName
          )}
          onKeyPress={(e) => e.key === 'Enter' && handleScrollLeft()}
          alt={`${VIEW_PREVIOUS_TEXT} ${elementName}`}
          role="button"
          tabIndex="0"
        />
      )}
      {useDirectionalButton && showRightArrow && (
        <DirectionalButton
          isRippleDisabled
          classContainer={clsx(
            classes.rightArrowButton,
            classes.arrowButton,
            rightArrowClassName
          )}
          buttonClassName={buttonClassName}
          onClickHandler={handleScrollRight}
          direction={'right'}
          text={null}
          aria-label={`${VIEW_NEXT_TEXT} ${elementName}`}
        />
      )}
      {!useDirectionalButton && showRightArrow && (
        <img
          src={rightArrowImage}
          onClick={handleScrollRight}
          className={clsx(
            classes.rightArrowButton,
            classes.arrowButton,
            rightArrowClassName
          )}
          alt={`${VIEW_NEXT_TEXT} ${elementName}`}
          onKeyPress={(e) => e.key === 'Enter' && handleScrollRight()}
          role="button"
          tabIndex="0"
        />
      )}
    </div>
  );
};

export default Carousel;
