/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createReservation7R = /* GraphQL */ `
  mutation CreateReservation7R($input: Reservation7RCreateInput!) {
    createReservation7R(input: $input) {
      id
      sevenRoomsReservationId
      userId
      venueId
      venue {
        id
        name
        address
        city
        state
        zipcode
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        timezoneName
        images
        websiteUrl
        menuUrl
        sevenRoomsVenueId
        phoneNumber
        bookingPolicy
        paymentPolicy
        specialEventDate
        venueType
        isInsider
      }
      sevenRoomsVenueId
      slotOptionId
      slotName
      date
      time
      realDateTime
      firstName
      lastName
      phone
      guests
      email
      status
      isActive
      sevenRoomsStatus
      statusCode
      sevenRoomsCreated
      sevenRoomsUpdated
      sevenRoomsDeleted
      costOption
      referenceCode
      diets
      allergies
      specialOccasions
      notes
      bookingPolicy
      paymentPolicy
      paymentAccountReferenceId
      paymentGratuity
      paymentServiceCharge
      paymentTax
      paymentSubTotal
      paymentRule
    }
  }
`;
export const updateReservation7R = /* GraphQL */ `
  mutation UpdateReservation7R($input: Reservation7RUpdateInput!) {
    updateReservation7R(input: $input) {
      id
      sevenRoomsReservationId
      userId
      venueId
      venue {
        id
        name
        address
        city
        state
        zipcode
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        timezoneName
        images
        websiteUrl
        menuUrl
        sevenRoomsVenueId
        phoneNumber
        bookingPolicy
        paymentPolicy
        specialEventDate
        venueType
        isInsider
      }
      sevenRoomsVenueId
      slotOptionId
      slotName
      date
      time
      realDateTime
      firstName
      lastName
      phone
      guests
      email
      status
      isActive
      sevenRoomsStatus
      statusCode
      sevenRoomsCreated
      sevenRoomsUpdated
      sevenRoomsDeleted
      costOption
      referenceCode
      diets
      allergies
      specialOccasions
      notes
      bookingPolicy
      paymentPolicy
      paymentAccountReferenceId
      paymentGratuity
      paymentServiceCharge
      paymentTax
      paymentSubTotal
      paymentRule
    }
  }
`;
export const cancelReservation7R = /* GraphQL */ `
  mutation CancelReservation7R($id: ID!) {
    cancelReservation7R(id: $id)
  }
`;
export const createStripeClientSetupIntent = /* GraphQL */ `
  mutation CreateStripeClientSetupIntent($sevenRoomsVenueId: String!) {
    createStripeClientSetupIntent(sevenRoomsVenueId: $sevenRoomsVenueId) {
      intentId
      clientSecret
    }
  }
`;
export const createStripeClientPaymentIntent = /* GraphQL */ `
  mutation CreateStripeClientPaymentIntent(
    $sevenRoomsVenueId: String!
    $amount: Int!
  ) {
    createStripeClientPaymentIntent(
      sevenRoomsVenueId: $sevenRoomsVenueId
      amount: $amount
    ) {
      intentId
      clientSecret
    }
  }
`;
export const updateUserProfile = /* GraphQL */ `
  mutation UpdateUserProfile($input: UserProfileInput!) {
    updateUserProfile(input: $input) {
      id
      c1CustRefId
      marketId
      market {
        id
        name
        airtableId
        image
        isActive
        venues {
          items {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        neighborhoods {
          items {
            id
            name
            airtableId
            isActive
            marketId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      userBirthday
      partnerBirthday
      userAnniversary
      notes
      defaultTipPercent
      allergies {
        items {
          id
          userId
          allergyId
          user {
            id
            c1CustRefId
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            userBirthday
            partnerBirthday
            userAnniversary
            notes
            defaultTipPercent
            allergies {
              nextToken
            }
            diets {
              nextToken
            }
            hidePhoneNumberAlert
            isTest
            hidePaymentMethodAlert
            defaultPaymentMethodAccountId
            hideExclusiveTaggingAlert
            createdAt
            updatedAt
          }
          allergy {
            id
            name
            airtableId
            isActive
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      diets {
        items {
          id
          userId
          dietId
          user {
            id
            c1CustRefId
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            userBirthday
            partnerBirthday
            userAnniversary
            notes
            defaultTipPercent
            allergies {
              nextToken
            }
            diets {
              nextToken
            }
            hidePhoneNumberAlert
            isTest
            hidePaymentMethodAlert
            defaultPaymentMethodAccountId
            hideExclusiveTaggingAlert
            createdAt
            updatedAt
          }
          diet {
            id
            name
            airtableId
            isActive
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      hidePhoneNumberAlert
      isTest
      hidePaymentMethodAlert
      defaultPaymentMethodAccountId
      hideExclusiveTaggingAlert
      createdAt
      updatedAt
    }
  }
`;
export const createAccolade = /* GraphQL */ `
  mutation CreateAccolade(
    $input: CreateAccoladeInput!
    $condition: ModelAccoladeConditionInput
  ) {
    createAccolade(input: $input, condition: $condition) {
      id
      name
      airtableId
      icon
      sortOrder
      awards {
        items {
          id
          accoladeId
          awardId
          accolade {
            id
            name
            airtableId
            icon
            sortOrder
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            isActive
            createdAt
            updatedAt
          }
          award {
            id
            name
            airtableId
            primaryText
            secondaryText
            icon
            status
            isActive
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      tags {
        items {
          id
          accoladeId
          tagId
          accolade {
            id
            name
            airtableId
            icon
            sortOrder
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            isActive
            createdAt
            updatedAt
          }
          tag {
            id
            name
            airtableId
            isActive
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const updateAccolade = /* GraphQL */ `
  mutation UpdateAccolade(
    $input: UpdateAccoladeInput!
    $condition: ModelAccoladeConditionInput
  ) {
    updateAccolade(input: $input, condition: $condition) {
      id
      name
      airtableId
      icon
      sortOrder
      awards {
        items {
          id
          accoladeId
          awardId
          accolade {
            id
            name
            airtableId
            icon
            sortOrder
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            isActive
            createdAt
            updatedAt
          }
          award {
            id
            name
            airtableId
            primaryText
            secondaryText
            icon
            status
            isActive
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      tags {
        items {
          id
          accoladeId
          tagId
          accolade {
            id
            name
            airtableId
            icon
            sortOrder
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            isActive
            createdAt
            updatedAt
          }
          tag {
            id
            name
            airtableId
            isActive
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const deleteAccolade = /* GraphQL */ `
  mutation DeleteAccolade(
    $input: DeleteAccoladeInput!
    $condition: ModelAccoladeConditionInput
  ) {
    deleteAccolade(input: $input, condition: $condition) {
      id
      name
      airtableId
      icon
      sortOrder
      awards {
        items {
          id
          accoladeId
          awardId
          accolade {
            id
            name
            airtableId
            icon
            sortOrder
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            isActive
            createdAt
            updatedAt
          }
          award {
            id
            name
            airtableId
            primaryText
            secondaryText
            icon
            status
            isActive
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      tags {
        items {
          id
          accoladeId
          tagId
          accolade {
            id
            name
            airtableId
            icon
            sortOrder
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            isActive
            createdAt
            updatedAt
          }
          tag {
            id
            name
            airtableId
            isActive
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const createAccoladeAward = /* GraphQL */ `
  mutation CreateAccoladeAward(
    $input: CreateAccoladeAwardInput!
    $condition: ModelAccoladeAwardConditionInput
  ) {
    createAccoladeAward(input: $input, condition: $condition) {
      id
      accoladeId
      awardId
      accolade {
        id
        name
        airtableId
        icon
        sortOrder
        awards {
          items {
            id
            accoladeId
            awardId
            accolade {
              id
              name
              airtableId
              icon
              sortOrder
              isActive
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            accoladeId
            tagId
            accolade {
              id
              name
              airtableId
              icon
              sortOrder
              isActive
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        isActive
        createdAt
        updatedAt
      }
      award {
        id
        name
        airtableId
        primaryText
        secondaryText
        icon
        status
        isActive
        venues {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAccoladeAward = /* GraphQL */ `
  mutation UpdateAccoladeAward(
    $input: UpdateAccoladeAwardInput!
    $condition: ModelAccoladeAwardConditionInput
  ) {
    updateAccoladeAward(input: $input, condition: $condition) {
      id
      accoladeId
      awardId
      accolade {
        id
        name
        airtableId
        icon
        sortOrder
        awards {
          items {
            id
            accoladeId
            awardId
            accolade {
              id
              name
              airtableId
              icon
              sortOrder
              isActive
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            accoladeId
            tagId
            accolade {
              id
              name
              airtableId
              icon
              sortOrder
              isActive
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        isActive
        createdAt
        updatedAt
      }
      award {
        id
        name
        airtableId
        primaryText
        secondaryText
        icon
        status
        isActive
        venues {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteAccoladeAward = /* GraphQL */ `
  mutation DeleteAccoladeAward(
    $input: DeleteAccoladeAwardInput!
    $condition: ModelAccoladeAwardConditionInput
  ) {
    deleteAccoladeAward(input: $input, condition: $condition) {
      id
      accoladeId
      awardId
      accolade {
        id
        name
        airtableId
        icon
        sortOrder
        awards {
          items {
            id
            accoladeId
            awardId
            accolade {
              id
              name
              airtableId
              icon
              sortOrder
              isActive
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            accoladeId
            tagId
            accolade {
              id
              name
              airtableId
              icon
              sortOrder
              isActive
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        isActive
        createdAt
        updatedAt
      }
      award {
        id
        name
        airtableId
        primaryText
        secondaryText
        icon
        status
        isActive
        venues {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createAccoladeTag = /* GraphQL */ `
  mutation CreateAccoladeTag(
    $input: CreateAccoladeTagInput!
    $condition: ModelAccoladeTagConditionInput
  ) {
    createAccoladeTag(input: $input, condition: $condition) {
      id
      accoladeId
      tagId
      accolade {
        id
        name
        airtableId
        icon
        sortOrder
        awards {
          items {
            id
            accoladeId
            awardId
            accolade {
              id
              name
              airtableId
              icon
              sortOrder
              isActive
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            accoladeId
            tagId
            accolade {
              id
              name
              airtableId
              icon
              sortOrder
              isActive
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        isActive
        createdAt
        updatedAt
      }
      tag {
        id
        name
        airtableId
        isActive
        venues {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAccoladeTag = /* GraphQL */ `
  mutation UpdateAccoladeTag(
    $input: UpdateAccoladeTagInput!
    $condition: ModelAccoladeTagConditionInput
  ) {
    updateAccoladeTag(input: $input, condition: $condition) {
      id
      accoladeId
      tagId
      accolade {
        id
        name
        airtableId
        icon
        sortOrder
        awards {
          items {
            id
            accoladeId
            awardId
            accolade {
              id
              name
              airtableId
              icon
              sortOrder
              isActive
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            accoladeId
            tagId
            accolade {
              id
              name
              airtableId
              icon
              sortOrder
              isActive
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        isActive
        createdAt
        updatedAt
      }
      tag {
        id
        name
        airtableId
        isActive
        venues {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteAccoladeTag = /* GraphQL */ `
  mutation DeleteAccoladeTag(
    $input: DeleteAccoladeTagInput!
    $condition: ModelAccoladeTagConditionInput
  ) {
    deleteAccoladeTag(input: $input, condition: $condition) {
      id
      accoladeId
      tagId
      accolade {
        id
        name
        airtableId
        icon
        sortOrder
        awards {
          items {
            id
            accoladeId
            awardId
            accolade {
              id
              name
              airtableId
              icon
              sortOrder
              isActive
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            accoladeId
            tagId
            accolade {
              id
              name
              airtableId
              icon
              sortOrder
              isActive
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        isActive
        createdAt
        updatedAt
      }
      tag {
        id
        name
        airtableId
        isActive
        venues {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createAllergy = /* GraphQL */ `
  mutation CreateAllergy(
    $input: CreateAllergyInput!
    $condition: ModelAllergyConditionInput
  ) {
    createAllergy(input: $input, condition: $condition) {
      id
      name
      airtableId
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const updateAllergy = /* GraphQL */ `
  mutation UpdateAllergy(
    $input: UpdateAllergyInput!
    $condition: ModelAllergyConditionInput
  ) {
    updateAllergy(input: $input, condition: $condition) {
      id
      name
      airtableId
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const deleteAllergy = /* GraphQL */ `
  mutation DeleteAllergy(
    $input: DeleteAllergyInput!
    $condition: ModelAllergyConditionInput
  ) {
    deleteAllergy(input: $input, condition: $condition) {
      id
      name
      airtableId
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const createAtmosphere = /* GraphQL */ `
  mutation CreateAtmosphere(
    $input: CreateAtmosphereInput!
    $condition: ModelAtmosphereConditionInput
  ) {
    createAtmosphere(input: $input, condition: $condition) {
      id
      name
      airtableId
      isActive
      venues {
        items {
          id
          venueId
          atmosphereId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          atmosphere {
            id
            name
            airtableId
            isActive
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAtmosphere = /* GraphQL */ `
  mutation UpdateAtmosphere(
    $input: UpdateAtmosphereInput!
    $condition: ModelAtmosphereConditionInput
  ) {
    updateAtmosphere(input: $input, condition: $condition) {
      id
      name
      airtableId
      isActive
      venues {
        items {
          id
          venueId
          atmosphereId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          atmosphere {
            id
            name
            airtableId
            isActive
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteAtmosphere = /* GraphQL */ `
  mutation DeleteAtmosphere(
    $input: DeleteAtmosphereInput!
    $condition: ModelAtmosphereConditionInput
  ) {
    deleteAtmosphere(input: $input, condition: $condition) {
      id
      name
      airtableId
      isActive
      venues {
        items {
          id
          venueId
          atmosphereId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          atmosphere {
            id
            name
            airtableId
            isActive
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createAward = /* GraphQL */ `
  mutation CreateAward(
    $input: CreateAwardInput!
    $condition: ModelAwardConditionInput
  ) {
    createAward(input: $input, condition: $condition) {
      id
      name
      airtableId
      primaryText
      secondaryText
      icon
      status
      isActive
      venues {
        items {
          id
          venueId
          awardId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          award {
            id
            name
            airtableId
            primaryText
            secondaryText
            icon
            status
            isActive
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAward = /* GraphQL */ `
  mutation UpdateAward(
    $input: UpdateAwardInput!
    $condition: ModelAwardConditionInput
  ) {
    updateAward(input: $input, condition: $condition) {
      id
      name
      airtableId
      primaryText
      secondaryText
      icon
      status
      isActive
      venues {
        items {
          id
          venueId
          awardId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          award {
            id
            name
            airtableId
            primaryText
            secondaryText
            icon
            status
            isActive
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteAward = /* GraphQL */ `
  mutation DeleteAward(
    $input: DeleteAwardInput!
    $condition: ModelAwardConditionInput
  ) {
    deleteAward(input: $input, condition: $condition) {
      id
      name
      airtableId
      primaryText
      secondaryText
      icon
      status
      isActive
      venues {
        items {
          id
          venueId
          awardId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          award {
            id
            name
            airtableId
            primaryText
            secondaryText
            icon
            status
            isActive
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCategory = /* GraphQL */ `
  mutation CreateCategory(
    $input: CreateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    createCategory(input: $input, condition: $condition) {
      id
      name
      airtableId
      description
      sortOrder
      status
      isActive
      isHighlighted
      venues {
        items {
          id
          venueId
          categoryId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          category {
            id
            name
            airtableId
            description
            sortOrder
            status
            isActive
            isHighlighted
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory(
    $input: UpdateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    updateCategory(input: $input, condition: $condition) {
      id
      name
      airtableId
      description
      sortOrder
      status
      isActive
      isHighlighted
      venues {
        items {
          id
          venueId
          categoryId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          category {
            id
            name
            airtableId
            description
            sortOrder
            status
            isActive
            isHighlighted
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory(
    $input: DeleteCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    deleteCategory(input: $input, condition: $condition) {
      id
      name
      airtableId
      description
      sortOrder
      status
      isActive
      isHighlighted
      venues {
        items {
          id
          venueId
          categoryId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          category {
            id
            name
            airtableId
            description
            sortOrder
            status
            isActive
            isHighlighted
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCuisine = /* GraphQL */ `
  mutation CreateCuisine(
    $input: CreateCuisineInput!
    $condition: ModelCuisineConditionInput
  ) {
    createCuisine(input: $input, condition: $condition) {
      id
      name
      airtableId
      images
      isActive
      venues {
        items {
          id
          venueId
          cuisineId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          cuisine {
            id
            name
            airtableId
            images
            isActive
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCuisine = /* GraphQL */ `
  mutation UpdateCuisine(
    $input: UpdateCuisineInput!
    $condition: ModelCuisineConditionInput
  ) {
    updateCuisine(input: $input, condition: $condition) {
      id
      name
      airtableId
      images
      isActive
      venues {
        items {
          id
          venueId
          cuisineId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          cuisine {
            id
            name
            airtableId
            images
            isActive
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCuisine = /* GraphQL */ `
  mutation DeleteCuisine(
    $input: DeleteCuisineInput!
    $condition: ModelCuisineConditionInput
  ) {
    deleteCuisine(input: $input, condition: $condition) {
      id
      name
      airtableId
      images
      isActive
      venues {
        items {
          id
          venueId
          cuisineId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          cuisine {
            id
            name
            airtableId
            images
            isActive
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createDiet = /* GraphQL */ `
  mutation CreateDiet(
    $input: CreateDietInput!
    $condition: ModelDietConditionInput
  ) {
    createDiet(input: $input, condition: $condition) {
      id
      name
      airtableId
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const updateDiet = /* GraphQL */ `
  mutation UpdateDiet(
    $input: UpdateDietInput!
    $condition: ModelDietConditionInput
  ) {
    updateDiet(input: $input, condition: $condition) {
      id
      name
      airtableId
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const deleteDiet = /* GraphQL */ `
  mutation DeleteDiet(
    $input: DeleteDietInput!
    $condition: ModelDietConditionInput
  ) {
    deleteDiet(input: $input, condition: $condition) {
      id
      name
      airtableId
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const createGeocode = /* GraphQL */ `
  mutation CreateGeocode(
    $input: CreateGeocodeInput!
    $condition: ModelGeocodeConditionInput
  ) {
    createGeocode(input: $input, condition: $condition) {
      id
      latitude
      longitude
      expirationTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const updateGeocode = /* GraphQL */ `
  mutation UpdateGeocode(
    $input: UpdateGeocodeInput!
    $condition: ModelGeocodeConditionInput
  ) {
    updateGeocode(input: $input, condition: $condition) {
      id
      latitude
      longitude
      expirationTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const deleteGeocode = /* GraphQL */ `
  mutation DeleteGeocode(
    $input: DeleteGeocodeInput!
    $condition: ModelGeocodeConditionInput
  ) {
    deleteGeocode(input: $input, condition: $condition) {
      id
      latitude
      longitude
      expirationTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const createMarket = /* GraphQL */ `
  mutation CreateMarket(
    $input: CreateMarketInput!
    $condition: ModelMarketConditionInput
  ) {
    createMarket(input: $input, condition: $condition) {
      id
      name
      airtableId
      image
      isActive
      venues {
        items {
          id
          name
          airtableId
          sevenRoomsVenueId
          sevenRoomsMunicipalityId
          description
          websiteUrl
          menuUrl
          startOfDay
          dineDash
          bookingPolicy
          paymentPolicy
          phoneNumber
          city
          state
          zipcode
          address
          googlePlaceId
          neighborhoodId
          neighborhood {
            id
            name
            airtableId
            isActive
            marketId
            createdAt
            updatedAt
          }
          normalizedName
          marketId
          market {
            id
            name
            airtableId
            image
            isActive
            venues {
              nextToken
            }
            neighborhoods {
              nextToken
            }
            createdAt
            updatedAt
          }
          geocodeId
          geocode {
            id
            latitude
            longitude
            expirationTimestamp
            createdAt
            updatedAt
          }
          categories {
            items {
              id
              venueId
              categoryId
              createdAt
              updatedAt
            }
            nextToken
          }
          atmospheres {
            items {
              id
              venueId
              atmosphereId
              createdAt
              updatedAt
            }
            nextToken
          }
          cuisines {
            items {
              id
              venueId
              cuisineId
              createdAt
              updatedAt
            }
            nextToken
          }
          cuisineNames
          awards {
            items {
              id
              venueId
              awardId
              createdAt
              updatedAt
            }
            nextToken
          }
          tags {
            items {
              id
              venueId
              tagId
              createdAt
              updatedAt
            }
            nextToken
          }
          seatingOptions {
            items {
              id
              venueId
              seatingOptionId
              createdAt
              updatedAt
            }
            nextToken
          }
          priceRange
          imagesSmall
          images
          imagesFull
          timezoneName
          sortOrder
          isActive
          specialEventDate
          eventSalesClosed
          eventSoldOut
          badgeText
          isTest
          isSignatureCollection
          isInsider
          salesStartDate
          salesEndDate
          eventDates
          isSoldOut
          isCardholderExclusive
          venueType
          experienceId
          isHidden
          relatedVenues {
            items {
              id
              venueId
              relatedVenueId
              createdAt
              updatedAt
            }
            nextToken
          }
          partners {
            items {
              id
              venueId
              partnerId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      neighborhoods {
        items {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateMarket = /* GraphQL */ `
  mutation UpdateMarket(
    $input: UpdateMarketInput!
    $condition: ModelMarketConditionInput
  ) {
    updateMarket(input: $input, condition: $condition) {
      id
      name
      airtableId
      image
      isActive
      venues {
        items {
          id
          name
          airtableId
          sevenRoomsVenueId
          sevenRoomsMunicipalityId
          description
          websiteUrl
          menuUrl
          startOfDay
          dineDash
          bookingPolicy
          paymentPolicy
          phoneNumber
          city
          state
          zipcode
          address
          googlePlaceId
          neighborhoodId
          neighborhood {
            id
            name
            airtableId
            isActive
            marketId
            createdAt
            updatedAt
          }
          normalizedName
          marketId
          market {
            id
            name
            airtableId
            image
            isActive
            venues {
              nextToken
            }
            neighborhoods {
              nextToken
            }
            createdAt
            updatedAt
          }
          geocodeId
          geocode {
            id
            latitude
            longitude
            expirationTimestamp
            createdAt
            updatedAt
          }
          categories {
            items {
              id
              venueId
              categoryId
              createdAt
              updatedAt
            }
            nextToken
          }
          atmospheres {
            items {
              id
              venueId
              atmosphereId
              createdAt
              updatedAt
            }
            nextToken
          }
          cuisines {
            items {
              id
              venueId
              cuisineId
              createdAt
              updatedAt
            }
            nextToken
          }
          cuisineNames
          awards {
            items {
              id
              venueId
              awardId
              createdAt
              updatedAt
            }
            nextToken
          }
          tags {
            items {
              id
              venueId
              tagId
              createdAt
              updatedAt
            }
            nextToken
          }
          seatingOptions {
            items {
              id
              venueId
              seatingOptionId
              createdAt
              updatedAt
            }
            nextToken
          }
          priceRange
          imagesSmall
          images
          imagesFull
          timezoneName
          sortOrder
          isActive
          specialEventDate
          eventSalesClosed
          eventSoldOut
          badgeText
          isTest
          isSignatureCollection
          isInsider
          salesStartDate
          salesEndDate
          eventDates
          isSoldOut
          isCardholderExclusive
          venueType
          experienceId
          isHidden
          relatedVenues {
            items {
              id
              venueId
              relatedVenueId
              createdAt
              updatedAt
            }
            nextToken
          }
          partners {
            items {
              id
              venueId
              partnerId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      neighborhoods {
        items {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteMarket = /* GraphQL */ `
  mutation DeleteMarket(
    $input: DeleteMarketInput!
    $condition: ModelMarketConditionInput
  ) {
    deleteMarket(input: $input, condition: $condition) {
      id
      name
      airtableId
      image
      isActive
      venues {
        items {
          id
          name
          airtableId
          sevenRoomsVenueId
          sevenRoomsMunicipalityId
          description
          websiteUrl
          menuUrl
          startOfDay
          dineDash
          bookingPolicy
          paymentPolicy
          phoneNumber
          city
          state
          zipcode
          address
          googlePlaceId
          neighborhoodId
          neighborhood {
            id
            name
            airtableId
            isActive
            marketId
            createdAt
            updatedAt
          }
          normalizedName
          marketId
          market {
            id
            name
            airtableId
            image
            isActive
            venues {
              nextToken
            }
            neighborhoods {
              nextToken
            }
            createdAt
            updatedAt
          }
          geocodeId
          geocode {
            id
            latitude
            longitude
            expirationTimestamp
            createdAt
            updatedAt
          }
          categories {
            items {
              id
              venueId
              categoryId
              createdAt
              updatedAt
            }
            nextToken
          }
          atmospheres {
            items {
              id
              venueId
              atmosphereId
              createdAt
              updatedAt
            }
            nextToken
          }
          cuisines {
            items {
              id
              venueId
              cuisineId
              createdAt
              updatedAt
            }
            nextToken
          }
          cuisineNames
          awards {
            items {
              id
              venueId
              awardId
              createdAt
              updatedAt
            }
            nextToken
          }
          tags {
            items {
              id
              venueId
              tagId
              createdAt
              updatedAt
            }
            nextToken
          }
          seatingOptions {
            items {
              id
              venueId
              seatingOptionId
              createdAt
              updatedAt
            }
            nextToken
          }
          priceRange
          imagesSmall
          images
          imagesFull
          timezoneName
          sortOrder
          isActive
          specialEventDate
          eventSalesClosed
          eventSoldOut
          badgeText
          isTest
          isSignatureCollection
          isInsider
          salesStartDate
          salesEndDate
          eventDates
          isSoldOut
          isCardholderExclusive
          venueType
          experienceId
          isHidden
          relatedVenues {
            items {
              id
              venueId
              relatedVenueId
              createdAt
              updatedAt
            }
            nextToken
          }
          partners {
            items {
              id
              venueId
              partnerId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      neighborhoods {
        items {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createNeighborhood = /* GraphQL */ `
  mutation CreateNeighborhood(
    $input: CreateNeighborhoodInput!
    $condition: ModelNeighborhoodConditionInput
  ) {
    createNeighborhood(input: $input, condition: $condition) {
      id
      name
      airtableId
      isActive
      marketId
      createdAt
      updatedAt
    }
  }
`;
export const updateNeighborhood = /* GraphQL */ `
  mutation UpdateNeighborhood(
    $input: UpdateNeighborhoodInput!
    $condition: ModelNeighborhoodConditionInput
  ) {
    updateNeighborhood(input: $input, condition: $condition) {
      id
      name
      airtableId
      isActive
      marketId
      createdAt
      updatedAt
    }
  }
`;
export const deleteNeighborhood = /* GraphQL */ `
  mutation DeleteNeighborhood(
    $input: DeleteNeighborhoodInput!
    $condition: ModelNeighborhoodConditionInput
  ) {
    deleteNeighborhood(input: $input, condition: $condition) {
      id
      name
      airtableId
      isActive
      marketId
      createdAt
      updatedAt
    }
  }
`;
export const createPartner = /* GraphQL */ `
  mutation CreatePartner(
    $input: CreatePartnerInput!
    $condition: ModelPartnerConditionInput
  ) {
    createPartner(input: $input, condition: $condition) {
      id
      name
      airtableId
      isActive
      icon
      venues {
        items {
          id
          venueId
          partnerId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          partner {
            id
            name
            airtableId
            isActive
            icon
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePartner = /* GraphQL */ `
  mutation UpdatePartner(
    $input: UpdatePartnerInput!
    $condition: ModelPartnerConditionInput
  ) {
    updatePartner(input: $input, condition: $condition) {
      id
      name
      airtableId
      isActive
      icon
      venues {
        items {
          id
          venueId
          partnerId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          partner {
            id
            name
            airtableId
            isActive
            icon
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePartner = /* GraphQL */ `
  mutation DeletePartner(
    $input: DeletePartnerInput!
    $condition: ModelPartnerConditionInput
  ) {
    deletePartner(input: $input, condition: $condition) {
      id
      name
      airtableId
      isActive
      icon
      venues {
        items {
          id
          venueId
          partnerId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          partner {
            id
            name
            airtableId
            isActive
            icon
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPremierLocation = /* GraphQL */ `
  mutation CreatePremierLocation(
    $input: CreatePremierLocationInput!
    $condition: ModelPremierLocationConditionInput
  ) {
    createPremierLocation(input: $input, condition: $condition) {
      id
      name
      subtitle
      airtableId
      sortOrder
      chefName
      images
      description
      status
      spotlight
      isActive
      venues {
        items {
          id
          venueId
          premierLocationId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          premierLocation {
            id
            name
            subtitle
            airtableId
            sortOrder
            chefName
            images
            description
            status
            spotlight
            isActive
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePremierLocation = /* GraphQL */ `
  mutation UpdatePremierLocation(
    $input: UpdatePremierLocationInput!
    $condition: ModelPremierLocationConditionInput
  ) {
    updatePremierLocation(input: $input, condition: $condition) {
      id
      name
      subtitle
      airtableId
      sortOrder
      chefName
      images
      description
      status
      spotlight
      isActive
      venues {
        items {
          id
          venueId
          premierLocationId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          premierLocation {
            id
            name
            subtitle
            airtableId
            sortOrder
            chefName
            images
            description
            status
            spotlight
            isActive
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePremierLocation = /* GraphQL */ `
  mutation DeletePremierLocation(
    $input: DeletePremierLocationInput!
    $condition: ModelPremierLocationConditionInput
  ) {
    deletePremierLocation(input: $input, condition: $condition) {
      id
      name
      subtitle
      airtableId
      sortOrder
      chefName
      images
      description
      status
      spotlight
      isActive
      venues {
        items {
          id
          venueId
          premierLocationId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          premierLocation {
            id
            name
            subtitle
            airtableId
            sortOrder
            chefName
            images
            description
            status
            spotlight
            isActive
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPremierLocationVenue = /* GraphQL */ `
  mutation CreatePremierLocationVenue(
    $input: CreatePremierLocationVenueInput!
    $condition: ModelPremierLocationVenueConditionInput
  ) {
    createPremierLocationVenue(input: $input, condition: $condition) {
      id
      venueId
      premierLocationId
      venue {
        id
        name
        airtableId
        sevenRoomsVenueId
        sevenRoomsMunicipalityId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        normalizedName
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geocodeId
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        atmospheres {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisines {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisineNames
        awards {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        seatingOptions {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        priceRange
        imagesSmall
        images
        imagesFull
        timezoneName
        sortOrder
        isActive
        specialEventDate
        eventSalesClosed
        eventSoldOut
        badgeText
        isTest
        isSignatureCollection
        isInsider
        salesStartDate
        salesEndDate
        eventDates
        isSoldOut
        isCardholderExclusive
        venueType
        experienceId
        isHidden
        relatedVenues {
          items {
            id
            venueId
            relatedVenueId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            relatedVenue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        partners {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      premierLocation {
        id
        name
        subtitle
        airtableId
        sortOrder
        chefName
        images
        description
        status
        spotlight
        isActive
        venues {
          items {
            id
            venueId
            premierLocationId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            premierLocation {
              id
              name
              subtitle
              airtableId
              sortOrder
              chefName
              images
              description
              status
              spotlight
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePremierLocationVenue = /* GraphQL */ `
  mutation UpdatePremierLocationVenue(
    $input: UpdatePremierLocationVenueInput!
    $condition: ModelPremierLocationVenueConditionInput
  ) {
    updatePremierLocationVenue(input: $input, condition: $condition) {
      id
      venueId
      premierLocationId
      venue {
        id
        name
        airtableId
        sevenRoomsVenueId
        sevenRoomsMunicipalityId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        normalizedName
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geocodeId
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        atmospheres {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisines {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisineNames
        awards {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        seatingOptions {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        priceRange
        imagesSmall
        images
        imagesFull
        timezoneName
        sortOrder
        isActive
        specialEventDate
        eventSalesClosed
        eventSoldOut
        badgeText
        isTest
        isSignatureCollection
        isInsider
        salesStartDate
        salesEndDate
        eventDates
        isSoldOut
        isCardholderExclusive
        venueType
        experienceId
        isHidden
        relatedVenues {
          items {
            id
            venueId
            relatedVenueId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            relatedVenue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        partners {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      premierLocation {
        id
        name
        subtitle
        airtableId
        sortOrder
        chefName
        images
        description
        status
        spotlight
        isActive
        venues {
          items {
            id
            venueId
            premierLocationId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            premierLocation {
              id
              name
              subtitle
              airtableId
              sortOrder
              chefName
              images
              description
              status
              spotlight
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePremierLocationVenue = /* GraphQL */ `
  mutation DeletePremierLocationVenue(
    $input: DeletePremierLocationVenueInput!
    $condition: ModelPremierLocationVenueConditionInput
  ) {
    deletePremierLocationVenue(input: $input, condition: $condition) {
      id
      venueId
      premierLocationId
      venue {
        id
        name
        airtableId
        sevenRoomsVenueId
        sevenRoomsMunicipalityId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        normalizedName
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geocodeId
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        atmospheres {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisines {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisineNames
        awards {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        seatingOptions {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        priceRange
        imagesSmall
        images
        imagesFull
        timezoneName
        sortOrder
        isActive
        specialEventDate
        eventSalesClosed
        eventSoldOut
        badgeText
        isTest
        isSignatureCollection
        isInsider
        salesStartDate
        salesEndDate
        eventDates
        isSoldOut
        isCardholderExclusive
        venueType
        experienceId
        isHidden
        relatedVenues {
          items {
            id
            venueId
            relatedVenueId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            relatedVenue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        partners {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      premierLocation {
        id
        name
        subtitle
        airtableId
        sortOrder
        chefName
        images
        description
        status
        spotlight
        isActive
        venues {
          items {
            id
            venueId
            premierLocationId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            premierLocation {
              id
              name
              subtitle
              airtableId
              sortOrder
              chefName
              images
              description
              status
              spotlight
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createReservation = /* GraphQL */ `
  mutation CreateReservation(
    $input: CreateReservationInput!
    $condition: ModelReservationConditionInput
  ) {
    createReservation(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        c1CustRefId
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        userBirthday
        partnerBirthday
        userAnniversary
        notes
        defaultTipPercent
        allergies {
          items {
            id
            userId
            allergyId
            user {
              id
              c1CustRefId
              marketId
              userBirthday
              partnerBirthday
              userAnniversary
              notes
              defaultTipPercent
              hidePhoneNumberAlert
              isTest
              hidePaymentMethodAlert
              defaultPaymentMethodAccountId
              hideExclusiveTaggingAlert
              createdAt
              updatedAt
            }
            allergy {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        diets {
          items {
            id
            userId
            dietId
            user {
              id
              c1CustRefId
              marketId
              userBirthday
              partnerBirthday
              userAnniversary
              notes
              defaultTipPercent
              hidePhoneNumberAlert
              isTest
              hidePaymentMethodAlert
              defaultPaymentMethodAccountId
              hideExclusiveTaggingAlert
              createdAt
              updatedAt
            }
            diet {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        hidePhoneNumberAlert
        isTest
        hidePaymentMethodAlert
        defaultPaymentMethodAccountId
        hideExclusiveTaggingAlert
        createdAt
        updatedAt
      }
      venueId
      venue {
        id
        name
        airtableId
        sevenRoomsVenueId
        sevenRoomsMunicipalityId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        normalizedName
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geocodeId
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        atmospheres {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisines {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisineNames
        awards {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        seatingOptions {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        priceRange
        imagesSmall
        images
        imagesFull
        timezoneName
        sortOrder
        isActive
        specialEventDate
        eventSalesClosed
        eventSoldOut
        badgeText
        isTest
        isSignatureCollection
        isInsider
        salesStartDate
        salesEndDate
        eventDates
        isSoldOut
        isCardholderExclusive
        venueType
        experienceId
        isHidden
        relatedVenues {
          items {
            id
            venueId
            relatedVenueId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            relatedVenue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        partners {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      sevenRoomsReservationId
      slotOptionId
      slotName
      bookingPolicy
      paymentPolicy
      status
      sevenRoomsStatusCode
      date
      time
      guests
      paymentAccountReferenceId
      paymentGratuity
      paymentServiceCharge
      paymentTax
      paymentSubTotal
      paymentRule
      isActive
      isExclusive
      createdAt
      updatedAt
    }
  }
`;
export const updateReservation = /* GraphQL */ `
  mutation UpdateReservation(
    $input: UpdateReservationInput!
    $condition: ModelReservationConditionInput
  ) {
    updateReservation(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        c1CustRefId
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        userBirthday
        partnerBirthday
        userAnniversary
        notes
        defaultTipPercent
        allergies {
          items {
            id
            userId
            allergyId
            user {
              id
              c1CustRefId
              marketId
              userBirthday
              partnerBirthday
              userAnniversary
              notes
              defaultTipPercent
              hidePhoneNumberAlert
              isTest
              hidePaymentMethodAlert
              defaultPaymentMethodAccountId
              hideExclusiveTaggingAlert
              createdAt
              updatedAt
            }
            allergy {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        diets {
          items {
            id
            userId
            dietId
            user {
              id
              c1CustRefId
              marketId
              userBirthday
              partnerBirthday
              userAnniversary
              notes
              defaultTipPercent
              hidePhoneNumberAlert
              isTest
              hidePaymentMethodAlert
              defaultPaymentMethodAccountId
              hideExclusiveTaggingAlert
              createdAt
              updatedAt
            }
            diet {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        hidePhoneNumberAlert
        isTest
        hidePaymentMethodAlert
        defaultPaymentMethodAccountId
        hideExclusiveTaggingAlert
        createdAt
        updatedAt
      }
      venueId
      venue {
        id
        name
        airtableId
        sevenRoomsVenueId
        sevenRoomsMunicipalityId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        normalizedName
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geocodeId
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        atmospheres {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisines {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisineNames
        awards {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        seatingOptions {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        priceRange
        imagesSmall
        images
        imagesFull
        timezoneName
        sortOrder
        isActive
        specialEventDate
        eventSalesClosed
        eventSoldOut
        badgeText
        isTest
        isSignatureCollection
        isInsider
        salesStartDate
        salesEndDate
        eventDates
        isSoldOut
        isCardholderExclusive
        venueType
        experienceId
        isHidden
        relatedVenues {
          items {
            id
            venueId
            relatedVenueId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            relatedVenue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        partners {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      sevenRoomsReservationId
      slotOptionId
      slotName
      bookingPolicy
      paymentPolicy
      status
      sevenRoomsStatusCode
      date
      time
      guests
      paymentAccountReferenceId
      paymentGratuity
      paymentServiceCharge
      paymentTax
      paymentSubTotal
      paymentRule
      isActive
      isExclusive
      createdAt
      updatedAt
    }
  }
`;
export const deleteReservation = /* GraphQL */ `
  mutation DeleteReservation(
    $input: DeleteReservationInput!
    $condition: ModelReservationConditionInput
  ) {
    deleteReservation(input: $input, condition: $condition) {
      id
      userId
      user {
        id
        c1CustRefId
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        userBirthday
        partnerBirthday
        userAnniversary
        notes
        defaultTipPercent
        allergies {
          items {
            id
            userId
            allergyId
            user {
              id
              c1CustRefId
              marketId
              userBirthday
              partnerBirthday
              userAnniversary
              notes
              defaultTipPercent
              hidePhoneNumberAlert
              isTest
              hidePaymentMethodAlert
              defaultPaymentMethodAccountId
              hideExclusiveTaggingAlert
              createdAt
              updatedAt
            }
            allergy {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        diets {
          items {
            id
            userId
            dietId
            user {
              id
              c1CustRefId
              marketId
              userBirthday
              partnerBirthday
              userAnniversary
              notes
              defaultTipPercent
              hidePhoneNumberAlert
              isTest
              hidePaymentMethodAlert
              defaultPaymentMethodAccountId
              hideExclusiveTaggingAlert
              createdAt
              updatedAt
            }
            diet {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        hidePhoneNumberAlert
        isTest
        hidePaymentMethodAlert
        defaultPaymentMethodAccountId
        hideExclusiveTaggingAlert
        createdAt
        updatedAt
      }
      venueId
      venue {
        id
        name
        airtableId
        sevenRoomsVenueId
        sevenRoomsMunicipalityId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        normalizedName
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geocodeId
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        atmospheres {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisines {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisineNames
        awards {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        seatingOptions {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        priceRange
        imagesSmall
        images
        imagesFull
        timezoneName
        sortOrder
        isActive
        specialEventDate
        eventSalesClosed
        eventSoldOut
        badgeText
        isTest
        isSignatureCollection
        isInsider
        salesStartDate
        salesEndDate
        eventDates
        isSoldOut
        isCardholderExclusive
        venueType
        experienceId
        isHidden
        relatedVenues {
          items {
            id
            venueId
            relatedVenueId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            relatedVenue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        partners {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      sevenRoomsReservationId
      slotOptionId
      slotName
      bookingPolicy
      paymentPolicy
      status
      sevenRoomsStatusCode
      date
      time
      guests
      paymentAccountReferenceId
      paymentGratuity
      paymentServiceCharge
      paymentTax
      paymentSubTotal
      paymentRule
      isActive
      isExclusive
      createdAt
      updatedAt
    }
  }
`;
export const createReservationRequest = /* GraphQL */ `
  mutation CreateReservationRequest(
    $input: CreateReservationRequestInput!
    $condition: ModelReservationRequestConditionInput
  ) {
    createReservationRequest(input: $input, condition: $condition) {
      id
      date
      time
      guests
      realDateTime
      userId
      user {
        id
        c1CustRefId
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        userBirthday
        partnerBirthday
        userAnniversary
        notes
        defaultTipPercent
        allergies {
          items {
            id
            userId
            allergyId
            user {
              id
              c1CustRefId
              marketId
              userBirthday
              partnerBirthday
              userAnniversary
              notes
              defaultTipPercent
              hidePhoneNumberAlert
              isTest
              hidePaymentMethodAlert
              defaultPaymentMethodAccountId
              hideExclusiveTaggingAlert
              createdAt
              updatedAt
            }
            allergy {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        diets {
          items {
            id
            userId
            dietId
            user {
              id
              c1CustRefId
              marketId
              userBirthday
              partnerBirthday
              userAnniversary
              notes
              defaultTipPercent
              hidePhoneNumberAlert
              isTest
              hidePaymentMethodAlert
              defaultPaymentMethodAccountId
              hideExclusiveTaggingAlert
              createdAt
              updatedAt
            }
            diet {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        hidePhoneNumberAlert
        isTest
        hidePaymentMethodAlert
        defaultPaymentMethodAccountId
        hideExclusiveTaggingAlert
        createdAt
        updatedAt
      }
      venueId
      venue {
        id
        name
        airtableId
        sevenRoomsVenueId
        sevenRoomsMunicipalityId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        normalizedName
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geocodeId
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        atmospheres {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisines {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisineNames
        awards {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        seatingOptions {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        priceRange
        imagesSmall
        images
        imagesFull
        timezoneName
        sortOrder
        isActive
        specialEventDate
        eventSalesClosed
        eventSoldOut
        badgeText
        isTest
        isSignatureCollection
        isInsider
        salesStartDate
        salesEndDate
        eventDates
        isSoldOut
        isCardholderExclusive
        venueType
        experienceId
        isHidden
        relatedVenues {
          items {
            id
            venueId
            relatedVenueId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            relatedVenue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        partners {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      sevenRoomsReservationRequestId
      isActive
      referenceCode
      isCustomerCancel
      c1Status
      createdAt
      updatedAt
    }
  }
`;
export const updateReservationRequest = /* GraphQL */ `
  mutation UpdateReservationRequest(
    $input: UpdateReservationRequestInput!
    $condition: ModelReservationRequestConditionInput
  ) {
    updateReservationRequest(input: $input, condition: $condition) {
      id
      date
      time
      guests
      realDateTime
      userId
      user {
        id
        c1CustRefId
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        userBirthday
        partnerBirthday
        userAnniversary
        notes
        defaultTipPercent
        allergies {
          items {
            id
            userId
            allergyId
            user {
              id
              c1CustRefId
              marketId
              userBirthday
              partnerBirthday
              userAnniversary
              notes
              defaultTipPercent
              hidePhoneNumberAlert
              isTest
              hidePaymentMethodAlert
              defaultPaymentMethodAccountId
              hideExclusiveTaggingAlert
              createdAt
              updatedAt
            }
            allergy {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        diets {
          items {
            id
            userId
            dietId
            user {
              id
              c1CustRefId
              marketId
              userBirthday
              partnerBirthday
              userAnniversary
              notes
              defaultTipPercent
              hidePhoneNumberAlert
              isTest
              hidePaymentMethodAlert
              defaultPaymentMethodAccountId
              hideExclusiveTaggingAlert
              createdAt
              updatedAt
            }
            diet {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        hidePhoneNumberAlert
        isTest
        hidePaymentMethodAlert
        defaultPaymentMethodAccountId
        hideExclusiveTaggingAlert
        createdAt
        updatedAt
      }
      venueId
      venue {
        id
        name
        airtableId
        sevenRoomsVenueId
        sevenRoomsMunicipalityId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        normalizedName
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geocodeId
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        atmospheres {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisines {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisineNames
        awards {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        seatingOptions {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        priceRange
        imagesSmall
        images
        imagesFull
        timezoneName
        sortOrder
        isActive
        specialEventDate
        eventSalesClosed
        eventSoldOut
        badgeText
        isTest
        isSignatureCollection
        isInsider
        salesStartDate
        salesEndDate
        eventDates
        isSoldOut
        isCardholderExclusive
        venueType
        experienceId
        isHidden
        relatedVenues {
          items {
            id
            venueId
            relatedVenueId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            relatedVenue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        partners {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      sevenRoomsReservationRequestId
      isActive
      referenceCode
      isCustomerCancel
      c1Status
      createdAt
      updatedAt
    }
  }
`;
export const deleteReservationRequest = /* GraphQL */ `
  mutation DeleteReservationRequest(
    $input: DeleteReservationRequestInput!
    $condition: ModelReservationRequestConditionInput
  ) {
    deleteReservationRequest(input: $input, condition: $condition) {
      id
      date
      time
      guests
      realDateTime
      userId
      user {
        id
        c1CustRefId
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        userBirthday
        partnerBirthday
        userAnniversary
        notes
        defaultTipPercent
        allergies {
          items {
            id
            userId
            allergyId
            user {
              id
              c1CustRefId
              marketId
              userBirthday
              partnerBirthday
              userAnniversary
              notes
              defaultTipPercent
              hidePhoneNumberAlert
              isTest
              hidePaymentMethodAlert
              defaultPaymentMethodAccountId
              hideExclusiveTaggingAlert
              createdAt
              updatedAt
            }
            allergy {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        diets {
          items {
            id
            userId
            dietId
            user {
              id
              c1CustRefId
              marketId
              userBirthday
              partnerBirthday
              userAnniversary
              notes
              defaultTipPercent
              hidePhoneNumberAlert
              isTest
              hidePaymentMethodAlert
              defaultPaymentMethodAccountId
              hideExclusiveTaggingAlert
              createdAt
              updatedAt
            }
            diet {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        hidePhoneNumberAlert
        isTest
        hidePaymentMethodAlert
        defaultPaymentMethodAccountId
        hideExclusiveTaggingAlert
        createdAt
        updatedAt
      }
      venueId
      venue {
        id
        name
        airtableId
        sevenRoomsVenueId
        sevenRoomsMunicipalityId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        normalizedName
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geocodeId
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        atmospheres {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisines {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisineNames
        awards {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        seatingOptions {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        priceRange
        imagesSmall
        images
        imagesFull
        timezoneName
        sortOrder
        isActive
        specialEventDate
        eventSalesClosed
        eventSoldOut
        badgeText
        isTest
        isSignatureCollection
        isInsider
        salesStartDate
        salesEndDate
        eventDates
        isSoldOut
        isCardholderExclusive
        venueType
        experienceId
        isHidden
        relatedVenues {
          items {
            id
            venueId
            relatedVenueId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            relatedVenue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        partners {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      sevenRoomsReservationRequestId
      isActive
      referenceCode
      isCustomerCancel
      c1Status
      createdAt
      updatedAt
    }
  }
`;
export const createSeatingOption = /* GraphQL */ `
  mutation CreateSeatingOption(
    $input: CreateSeatingOptionInput!
    $condition: ModelSeatingOptionConditionInput
  ) {
    createSeatingOption(input: $input, condition: $condition) {
      id
      name
      airtableId
      isActive
      venues {
        items {
          id
          venueId
          seatingOptionId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          seatingOption {
            id
            name
            airtableId
            isActive
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSeatingOption = /* GraphQL */ `
  mutation UpdateSeatingOption(
    $input: UpdateSeatingOptionInput!
    $condition: ModelSeatingOptionConditionInput
  ) {
    updateSeatingOption(input: $input, condition: $condition) {
      id
      name
      airtableId
      isActive
      venues {
        items {
          id
          venueId
          seatingOptionId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          seatingOption {
            id
            name
            airtableId
            isActive
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSeatingOption = /* GraphQL */ `
  mutation DeleteSeatingOption(
    $input: DeleteSeatingOptionInput!
    $condition: ModelSeatingOptionConditionInput
  ) {
    deleteSeatingOption(input: $input, condition: $condition) {
      id
      name
      airtableId
      isActive
      venues {
        items {
          id
          venueId
          seatingOptionId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          seatingOption {
            id
            name
            airtableId
            isActive
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSpecialOccasion = /* GraphQL */ `
  mutation CreateSpecialOccasion(
    $input: CreateSpecialOccasionInput!
    $condition: ModelSpecialOccasionConditionInput
  ) {
    createSpecialOccasion(input: $input, condition: $condition) {
      id
      name
      airtableId
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const updateSpecialOccasion = /* GraphQL */ `
  mutation UpdateSpecialOccasion(
    $input: UpdateSpecialOccasionInput!
    $condition: ModelSpecialOccasionConditionInput
  ) {
    updateSpecialOccasion(input: $input, condition: $condition) {
      id
      name
      airtableId
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const deleteSpecialOccasion = /* GraphQL */ `
  mutation DeleteSpecialOccasion(
    $input: DeleteSpecialOccasionInput!
    $condition: ModelSpecialOccasionConditionInput
  ) {
    deleteSpecialOccasion(input: $input, condition: $condition) {
      id
      name
      airtableId
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const createTag = /* GraphQL */ `
  mutation CreateTag(
    $input: CreateTagInput!
    $condition: ModelTagConditionInput
  ) {
    createTag(input: $input, condition: $condition) {
      id
      name
      airtableId
      isActive
      venues {
        items {
          id
          venueId
          tagId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          tag {
            id
            name
            airtableId
            isActive
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTag = /* GraphQL */ `
  mutation UpdateTag(
    $input: UpdateTagInput!
    $condition: ModelTagConditionInput
  ) {
    updateTag(input: $input, condition: $condition) {
      id
      name
      airtableId
      isActive
      venues {
        items {
          id
          venueId
          tagId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          tag {
            id
            name
            airtableId
            isActive
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTag = /* GraphQL */ `
  mutation DeleteTag(
    $input: DeleteTagInput!
    $condition: ModelTagConditionInput
  ) {
    deleteTag(input: $input, condition: $condition) {
      id
      name
      airtableId
      isActive
      venues {
        items {
          id
          venueId
          tagId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          tag {
            id
            name
            airtableId
            isActive
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      c1CustRefId
      marketId
      market {
        id
        name
        airtableId
        image
        isActive
        venues {
          items {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        neighborhoods {
          items {
            id
            name
            airtableId
            isActive
            marketId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      userBirthday
      partnerBirthday
      userAnniversary
      notes
      defaultTipPercent
      allergies {
        items {
          id
          userId
          allergyId
          user {
            id
            c1CustRefId
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            userBirthday
            partnerBirthday
            userAnniversary
            notes
            defaultTipPercent
            allergies {
              nextToken
            }
            diets {
              nextToken
            }
            hidePhoneNumberAlert
            isTest
            hidePaymentMethodAlert
            defaultPaymentMethodAccountId
            hideExclusiveTaggingAlert
            createdAt
            updatedAt
          }
          allergy {
            id
            name
            airtableId
            isActive
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      diets {
        items {
          id
          userId
          dietId
          user {
            id
            c1CustRefId
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            userBirthday
            partnerBirthday
            userAnniversary
            notes
            defaultTipPercent
            allergies {
              nextToken
            }
            diets {
              nextToken
            }
            hidePhoneNumberAlert
            isTest
            hidePaymentMethodAlert
            defaultPaymentMethodAccountId
            hideExclusiveTaggingAlert
            createdAt
            updatedAt
          }
          diet {
            id
            name
            airtableId
            isActive
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      hidePhoneNumberAlert
      isTest
      hidePaymentMethodAlert
      defaultPaymentMethodAccountId
      hideExclusiveTaggingAlert
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      c1CustRefId
      marketId
      market {
        id
        name
        airtableId
        image
        isActive
        venues {
          items {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        neighborhoods {
          items {
            id
            name
            airtableId
            isActive
            marketId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      userBirthday
      partnerBirthday
      userAnniversary
      notes
      defaultTipPercent
      allergies {
        items {
          id
          userId
          allergyId
          user {
            id
            c1CustRefId
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            userBirthday
            partnerBirthday
            userAnniversary
            notes
            defaultTipPercent
            allergies {
              nextToken
            }
            diets {
              nextToken
            }
            hidePhoneNumberAlert
            isTest
            hidePaymentMethodAlert
            defaultPaymentMethodAccountId
            hideExclusiveTaggingAlert
            createdAt
            updatedAt
          }
          allergy {
            id
            name
            airtableId
            isActive
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      diets {
        items {
          id
          userId
          dietId
          user {
            id
            c1CustRefId
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            userBirthday
            partnerBirthday
            userAnniversary
            notes
            defaultTipPercent
            allergies {
              nextToken
            }
            diets {
              nextToken
            }
            hidePhoneNumberAlert
            isTest
            hidePaymentMethodAlert
            defaultPaymentMethodAccountId
            hideExclusiveTaggingAlert
            createdAt
            updatedAt
          }
          diet {
            id
            name
            airtableId
            isActive
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      hidePhoneNumberAlert
      isTest
      hidePaymentMethodAlert
      defaultPaymentMethodAccountId
      hideExclusiveTaggingAlert
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      c1CustRefId
      marketId
      market {
        id
        name
        airtableId
        image
        isActive
        venues {
          items {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        neighborhoods {
          items {
            id
            name
            airtableId
            isActive
            marketId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      userBirthday
      partnerBirthday
      userAnniversary
      notes
      defaultTipPercent
      allergies {
        items {
          id
          userId
          allergyId
          user {
            id
            c1CustRefId
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            userBirthday
            partnerBirthday
            userAnniversary
            notes
            defaultTipPercent
            allergies {
              nextToken
            }
            diets {
              nextToken
            }
            hidePhoneNumberAlert
            isTest
            hidePaymentMethodAlert
            defaultPaymentMethodAccountId
            hideExclusiveTaggingAlert
            createdAt
            updatedAt
          }
          allergy {
            id
            name
            airtableId
            isActive
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      diets {
        items {
          id
          userId
          dietId
          user {
            id
            c1CustRefId
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            userBirthday
            partnerBirthday
            userAnniversary
            notes
            defaultTipPercent
            allergies {
              nextToken
            }
            diets {
              nextToken
            }
            hidePhoneNumberAlert
            isTest
            hidePaymentMethodAlert
            defaultPaymentMethodAccountId
            hideExclusiveTaggingAlert
            createdAt
            updatedAt
          }
          diet {
            id
            name
            airtableId
            isActive
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      hidePhoneNumberAlert
      isTest
      hidePaymentMethodAlert
      defaultPaymentMethodAccountId
      hideExclusiveTaggingAlert
      createdAt
      updatedAt
    }
  }
`;
export const createUserAllergy = /* GraphQL */ `
  mutation CreateUserAllergy(
    $input: CreateUserAllergyInput!
    $condition: ModelUserAllergyConditionInput
  ) {
    createUserAllergy(input: $input, condition: $condition) {
      id
      userId
      allergyId
      user {
        id
        c1CustRefId
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        userBirthday
        partnerBirthday
        userAnniversary
        notes
        defaultTipPercent
        allergies {
          items {
            id
            userId
            allergyId
            user {
              id
              c1CustRefId
              marketId
              userBirthday
              partnerBirthday
              userAnniversary
              notes
              defaultTipPercent
              hidePhoneNumberAlert
              isTest
              hidePaymentMethodAlert
              defaultPaymentMethodAccountId
              hideExclusiveTaggingAlert
              createdAt
              updatedAt
            }
            allergy {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        diets {
          items {
            id
            userId
            dietId
            user {
              id
              c1CustRefId
              marketId
              userBirthday
              partnerBirthday
              userAnniversary
              notes
              defaultTipPercent
              hidePhoneNumberAlert
              isTest
              hidePaymentMethodAlert
              defaultPaymentMethodAccountId
              hideExclusiveTaggingAlert
              createdAt
              updatedAt
            }
            diet {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        hidePhoneNumberAlert
        isTest
        hidePaymentMethodAlert
        defaultPaymentMethodAccountId
        hideExclusiveTaggingAlert
        createdAt
        updatedAt
      }
      allergy {
        id
        name
        airtableId
        isActive
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUserAllergy = /* GraphQL */ `
  mutation UpdateUserAllergy(
    $input: UpdateUserAllergyInput!
    $condition: ModelUserAllergyConditionInput
  ) {
    updateUserAllergy(input: $input, condition: $condition) {
      id
      userId
      allergyId
      user {
        id
        c1CustRefId
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        userBirthday
        partnerBirthday
        userAnniversary
        notes
        defaultTipPercent
        allergies {
          items {
            id
            userId
            allergyId
            user {
              id
              c1CustRefId
              marketId
              userBirthday
              partnerBirthday
              userAnniversary
              notes
              defaultTipPercent
              hidePhoneNumberAlert
              isTest
              hidePaymentMethodAlert
              defaultPaymentMethodAccountId
              hideExclusiveTaggingAlert
              createdAt
              updatedAt
            }
            allergy {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        diets {
          items {
            id
            userId
            dietId
            user {
              id
              c1CustRefId
              marketId
              userBirthday
              partnerBirthday
              userAnniversary
              notes
              defaultTipPercent
              hidePhoneNumberAlert
              isTest
              hidePaymentMethodAlert
              defaultPaymentMethodAccountId
              hideExclusiveTaggingAlert
              createdAt
              updatedAt
            }
            diet {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        hidePhoneNumberAlert
        isTest
        hidePaymentMethodAlert
        defaultPaymentMethodAccountId
        hideExclusiveTaggingAlert
        createdAt
        updatedAt
      }
      allergy {
        id
        name
        airtableId
        isActive
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserAllergy = /* GraphQL */ `
  mutation DeleteUserAllergy(
    $input: DeleteUserAllergyInput!
    $condition: ModelUserAllergyConditionInput
  ) {
    deleteUserAllergy(input: $input, condition: $condition) {
      id
      userId
      allergyId
      user {
        id
        c1CustRefId
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        userBirthday
        partnerBirthday
        userAnniversary
        notes
        defaultTipPercent
        allergies {
          items {
            id
            userId
            allergyId
            user {
              id
              c1CustRefId
              marketId
              userBirthday
              partnerBirthday
              userAnniversary
              notes
              defaultTipPercent
              hidePhoneNumberAlert
              isTest
              hidePaymentMethodAlert
              defaultPaymentMethodAccountId
              hideExclusiveTaggingAlert
              createdAt
              updatedAt
            }
            allergy {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        diets {
          items {
            id
            userId
            dietId
            user {
              id
              c1CustRefId
              marketId
              userBirthday
              partnerBirthday
              userAnniversary
              notes
              defaultTipPercent
              hidePhoneNumberAlert
              isTest
              hidePaymentMethodAlert
              defaultPaymentMethodAccountId
              hideExclusiveTaggingAlert
              createdAt
              updatedAt
            }
            diet {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        hidePhoneNumberAlert
        isTest
        hidePaymentMethodAlert
        defaultPaymentMethodAccountId
        hideExclusiveTaggingAlert
        createdAt
        updatedAt
      }
      allergy {
        id
        name
        airtableId
        isActive
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createUserDiet = /* GraphQL */ `
  mutation CreateUserDiet(
    $input: CreateUserDietInput!
    $condition: ModelUserDietConditionInput
  ) {
    createUserDiet(input: $input, condition: $condition) {
      id
      userId
      dietId
      user {
        id
        c1CustRefId
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        userBirthday
        partnerBirthday
        userAnniversary
        notes
        defaultTipPercent
        allergies {
          items {
            id
            userId
            allergyId
            user {
              id
              c1CustRefId
              marketId
              userBirthday
              partnerBirthday
              userAnniversary
              notes
              defaultTipPercent
              hidePhoneNumberAlert
              isTest
              hidePaymentMethodAlert
              defaultPaymentMethodAccountId
              hideExclusiveTaggingAlert
              createdAt
              updatedAt
            }
            allergy {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        diets {
          items {
            id
            userId
            dietId
            user {
              id
              c1CustRefId
              marketId
              userBirthday
              partnerBirthday
              userAnniversary
              notes
              defaultTipPercent
              hidePhoneNumberAlert
              isTest
              hidePaymentMethodAlert
              defaultPaymentMethodAccountId
              hideExclusiveTaggingAlert
              createdAt
              updatedAt
            }
            diet {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        hidePhoneNumberAlert
        isTest
        hidePaymentMethodAlert
        defaultPaymentMethodAccountId
        hideExclusiveTaggingAlert
        createdAt
        updatedAt
      }
      diet {
        id
        name
        airtableId
        isActive
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUserDiet = /* GraphQL */ `
  mutation UpdateUserDiet(
    $input: UpdateUserDietInput!
    $condition: ModelUserDietConditionInput
  ) {
    updateUserDiet(input: $input, condition: $condition) {
      id
      userId
      dietId
      user {
        id
        c1CustRefId
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        userBirthday
        partnerBirthday
        userAnniversary
        notes
        defaultTipPercent
        allergies {
          items {
            id
            userId
            allergyId
            user {
              id
              c1CustRefId
              marketId
              userBirthday
              partnerBirthday
              userAnniversary
              notes
              defaultTipPercent
              hidePhoneNumberAlert
              isTest
              hidePaymentMethodAlert
              defaultPaymentMethodAccountId
              hideExclusiveTaggingAlert
              createdAt
              updatedAt
            }
            allergy {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        diets {
          items {
            id
            userId
            dietId
            user {
              id
              c1CustRefId
              marketId
              userBirthday
              partnerBirthday
              userAnniversary
              notes
              defaultTipPercent
              hidePhoneNumberAlert
              isTest
              hidePaymentMethodAlert
              defaultPaymentMethodAccountId
              hideExclusiveTaggingAlert
              createdAt
              updatedAt
            }
            diet {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        hidePhoneNumberAlert
        isTest
        hidePaymentMethodAlert
        defaultPaymentMethodAccountId
        hideExclusiveTaggingAlert
        createdAt
        updatedAt
      }
      diet {
        id
        name
        airtableId
        isActive
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserDiet = /* GraphQL */ `
  mutation DeleteUserDiet(
    $input: DeleteUserDietInput!
    $condition: ModelUserDietConditionInput
  ) {
    deleteUserDiet(input: $input, condition: $condition) {
      id
      userId
      dietId
      user {
        id
        c1CustRefId
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        userBirthday
        partnerBirthday
        userAnniversary
        notes
        defaultTipPercent
        allergies {
          items {
            id
            userId
            allergyId
            user {
              id
              c1CustRefId
              marketId
              userBirthday
              partnerBirthday
              userAnniversary
              notes
              defaultTipPercent
              hidePhoneNumberAlert
              isTest
              hidePaymentMethodAlert
              defaultPaymentMethodAccountId
              hideExclusiveTaggingAlert
              createdAt
              updatedAt
            }
            allergy {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        diets {
          items {
            id
            userId
            dietId
            user {
              id
              c1CustRefId
              marketId
              userBirthday
              partnerBirthday
              userAnniversary
              notes
              defaultTipPercent
              hidePhoneNumberAlert
              isTest
              hidePaymentMethodAlert
              defaultPaymentMethodAccountId
              hideExclusiveTaggingAlert
              createdAt
              updatedAt
            }
            diet {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        hidePhoneNumberAlert
        isTest
        hidePaymentMethodAlert
        defaultPaymentMethodAccountId
        hideExclusiveTaggingAlert
        createdAt
        updatedAt
      }
      diet {
        id
        name
        airtableId
        isActive
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createVenue = /* GraphQL */ `
  mutation CreateVenue(
    $input: CreateVenueInput!
    $condition: ModelVenueConditionInput
  ) {
    createVenue(input: $input, condition: $condition) {
      id
      name
      airtableId
      sevenRoomsVenueId
      sevenRoomsMunicipalityId
      description
      websiteUrl
      menuUrl
      startOfDay
      dineDash
      bookingPolicy
      paymentPolicy
      phoneNumber
      city
      state
      zipcode
      address
      googlePlaceId
      neighborhoodId
      neighborhood {
        id
        name
        airtableId
        isActive
        marketId
        createdAt
        updatedAt
      }
      normalizedName
      marketId
      market {
        id
        name
        airtableId
        image
        isActive
        venues {
          items {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        neighborhoods {
          items {
            id
            name
            airtableId
            isActive
            marketId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      geocodeId
      geocode {
        id
        latitude
        longitude
        expirationTimestamp
        createdAt
        updatedAt
      }
      categories {
        items {
          id
          venueId
          categoryId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          category {
            id
            name
            airtableId
            description
            sortOrder
            status
            isActive
            isHighlighted
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      atmospheres {
        items {
          id
          venueId
          atmosphereId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          atmosphere {
            id
            name
            airtableId
            isActive
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      cuisines {
        items {
          id
          venueId
          cuisineId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          cuisine {
            id
            name
            airtableId
            images
            isActive
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      cuisineNames
      awards {
        items {
          id
          venueId
          awardId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          award {
            id
            name
            airtableId
            primaryText
            secondaryText
            icon
            status
            isActive
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      tags {
        items {
          id
          venueId
          tagId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          tag {
            id
            name
            airtableId
            isActive
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      seatingOptions {
        items {
          id
          venueId
          seatingOptionId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          seatingOption {
            id
            name
            airtableId
            isActive
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      priceRange
      imagesSmall
      images
      imagesFull
      timezoneName
      sortOrder
      isActive
      specialEventDate
      eventSalesClosed
      eventSoldOut
      badgeText
      isTest
      isSignatureCollection
      isInsider
      salesStartDate
      salesEndDate
      eventDates
      isSoldOut
      isCardholderExclusive
      venueType
      experienceId
      isHidden
      relatedVenues {
        items {
          id
          venueId
          relatedVenueId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          relatedVenue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      partners {
        items {
          id
          venueId
          partnerId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          partner {
            id
            name
            airtableId
            isActive
            icon
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateVenue = /* GraphQL */ `
  mutation UpdateVenue(
    $input: UpdateVenueInput!
    $condition: ModelVenueConditionInput
  ) {
    updateVenue(input: $input, condition: $condition) {
      id
      name
      airtableId
      sevenRoomsVenueId
      sevenRoomsMunicipalityId
      description
      websiteUrl
      menuUrl
      startOfDay
      dineDash
      bookingPolicy
      paymentPolicy
      phoneNumber
      city
      state
      zipcode
      address
      googlePlaceId
      neighborhoodId
      neighborhood {
        id
        name
        airtableId
        isActive
        marketId
        createdAt
        updatedAt
      }
      normalizedName
      marketId
      market {
        id
        name
        airtableId
        image
        isActive
        venues {
          items {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        neighborhoods {
          items {
            id
            name
            airtableId
            isActive
            marketId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      geocodeId
      geocode {
        id
        latitude
        longitude
        expirationTimestamp
        createdAt
        updatedAt
      }
      categories {
        items {
          id
          venueId
          categoryId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          category {
            id
            name
            airtableId
            description
            sortOrder
            status
            isActive
            isHighlighted
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      atmospheres {
        items {
          id
          venueId
          atmosphereId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          atmosphere {
            id
            name
            airtableId
            isActive
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      cuisines {
        items {
          id
          venueId
          cuisineId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          cuisine {
            id
            name
            airtableId
            images
            isActive
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      cuisineNames
      awards {
        items {
          id
          venueId
          awardId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          award {
            id
            name
            airtableId
            primaryText
            secondaryText
            icon
            status
            isActive
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      tags {
        items {
          id
          venueId
          tagId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          tag {
            id
            name
            airtableId
            isActive
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      seatingOptions {
        items {
          id
          venueId
          seatingOptionId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          seatingOption {
            id
            name
            airtableId
            isActive
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      priceRange
      imagesSmall
      images
      imagesFull
      timezoneName
      sortOrder
      isActive
      specialEventDate
      eventSalesClosed
      eventSoldOut
      badgeText
      isTest
      isSignatureCollection
      isInsider
      salesStartDate
      salesEndDate
      eventDates
      isSoldOut
      isCardholderExclusive
      venueType
      experienceId
      isHidden
      relatedVenues {
        items {
          id
          venueId
          relatedVenueId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          relatedVenue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      partners {
        items {
          id
          venueId
          partnerId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          partner {
            id
            name
            airtableId
            isActive
            icon
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteVenue = /* GraphQL */ `
  mutation DeleteVenue(
    $input: DeleteVenueInput!
    $condition: ModelVenueConditionInput
  ) {
    deleteVenue(input: $input, condition: $condition) {
      id
      name
      airtableId
      sevenRoomsVenueId
      sevenRoomsMunicipalityId
      description
      websiteUrl
      menuUrl
      startOfDay
      dineDash
      bookingPolicy
      paymentPolicy
      phoneNumber
      city
      state
      zipcode
      address
      googlePlaceId
      neighborhoodId
      neighborhood {
        id
        name
        airtableId
        isActive
        marketId
        createdAt
        updatedAt
      }
      normalizedName
      marketId
      market {
        id
        name
        airtableId
        image
        isActive
        venues {
          items {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        neighborhoods {
          items {
            id
            name
            airtableId
            isActive
            marketId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      geocodeId
      geocode {
        id
        latitude
        longitude
        expirationTimestamp
        createdAt
        updatedAt
      }
      categories {
        items {
          id
          venueId
          categoryId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          category {
            id
            name
            airtableId
            description
            sortOrder
            status
            isActive
            isHighlighted
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      atmospheres {
        items {
          id
          venueId
          atmosphereId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          atmosphere {
            id
            name
            airtableId
            isActive
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      cuisines {
        items {
          id
          venueId
          cuisineId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          cuisine {
            id
            name
            airtableId
            images
            isActive
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      cuisineNames
      awards {
        items {
          id
          venueId
          awardId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          award {
            id
            name
            airtableId
            primaryText
            secondaryText
            icon
            status
            isActive
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      tags {
        items {
          id
          venueId
          tagId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          tag {
            id
            name
            airtableId
            isActive
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      seatingOptions {
        items {
          id
          venueId
          seatingOptionId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          seatingOption {
            id
            name
            airtableId
            isActive
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      priceRange
      imagesSmall
      images
      imagesFull
      timezoneName
      sortOrder
      isActive
      specialEventDate
      eventSalesClosed
      eventSoldOut
      badgeText
      isTest
      isSignatureCollection
      isInsider
      salesStartDate
      salesEndDate
      eventDates
      isSoldOut
      isCardholderExclusive
      venueType
      experienceId
      isHidden
      relatedVenues {
        items {
          id
          venueId
          relatedVenueId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          relatedVenue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      partners {
        items {
          id
          venueId
          partnerId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          partner {
            id
            name
            airtableId
            isActive
            icon
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createVenueRelated = /* GraphQL */ `
  mutation CreateVenueRelated(
    $input: CreateVenueRelatedInput!
    $condition: ModelVenueRelatedConditionInput
  ) {
    createVenueRelated(input: $input, condition: $condition) {
      id
      venueId
      relatedVenueId
      venue {
        id
        name
        airtableId
        sevenRoomsVenueId
        sevenRoomsMunicipalityId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        normalizedName
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geocodeId
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        atmospheres {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisines {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisineNames
        awards {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        seatingOptions {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        priceRange
        imagesSmall
        images
        imagesFull
        timezoneName
        sortOrder
        isActive
        specialEventDate
        eventSalesClosed
        eventSoldOut
        badgeText
        isTest
        isSignatureCollection
        isInsider
        salesStartDate
        salesEndDate
        eventDates
        isSoldOut
        isCardholderExclusive
        venueType
        experienceId
        isHidden
        relatedVenues {
          items {
            id
            venueId
            relatedVenueId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            relatedVenue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        partners {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      relatedVenue {
        id
        name
        airtableId
        sevenRoomsVenueId
        sevenRoomsMunicipalityId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        normalizedName
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geocodeId
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        atmospheres {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisines {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisineNames
        awards {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        seatingOptions {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        priceRange
        imagesSmall
        images
        imagesFull
        timezoneName
        sortOrder
        isActive
        specialEventDate
        eventSalesClosed
        eventSoldOut
        badgeText
        isTest
        isSignatureCollection
        isInsider
        salesStartDate
        salesEndDate
        eventDates
        isSoldOut
        isCardholderExclusive
        venueType
        experienceId
        isHidden
        relatedVenues {
          items {
            id
            venueId
            relatedVenueId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            relatedVenue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        partners {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateVenueRelated = /* GraphQL */ `
  mutation UpdateVenueRelated(
    $input: UpdateVenueRelatedInput!
    $condition: ModelVenueRelatedConditionInput
  ) {
    updateVenueRelated(input: $input, condition: $condition) {
      id
      venueId
      relatedVenueId
      venue {
        id
        name
        airtableId
        sevenRoomsVenueId
        sevenRoomsMunicipalityId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        normalizedName
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geocodeId
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        atmospheres {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisines {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisineNames
        awards {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        seatingOptions {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        priceRange
        imagesSmall
        images
        imagesFull
        timezoneName
        sortOrder
        isActive
        specialEventDate
        eventSalesClosed
        eventSoldOut
        badgeText
        isTest
        isSignatureCollection
        isInsider
        salesStartDate
        salesEndDate
        eventDates
        isSoldOut
        isCardholderExclusive
        venueType
        experienceId
        isHidden
        relatedVenues {
          items {
            id
            venueId
            relatedVenueId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            relatedVenue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        partners {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      relatedVenue {
        id
        name
        airtableId
        sevenRoomsVenueId
        sevenRoomsMunicipalityId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        normalizedName
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geocodeId
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        atmospheres {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisines {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisineNames
        awards {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        seatingOptions {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        priceRange
        imagesSmall
        images
        imagesFull
        timezoneName
        sortOrder
        isActive
        specialEventDate
        eventSalesClosed
        eventSoldOut
        badgeText
        isTest
        isSignatureCollection
        isInsider
        salesStartDate
        salesEndDate
        eventDates
        isSoldOut
        isCardholderExclusive
        venueType
        experienceId
        isHidden
        relatedVenues {
          items {
            id
            venueId
            relatedVenueId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            relatedVenue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        partners {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteVenueRelated = /* GraphQL */ `
  mutation DeleteVenueRelated(
    $input: DeleteVenueRelatedInput!
    $condition: ModelVenueRelatedConditionInput
  ) {
    deleteVenueRelated(input: $input, condition: $condition) {
      id
      venueId
      relatedVenueId
      venue {
        id
        name
        airtableId
        sevenRoomsVenueId
        sevenRoomsMunicipalityId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        normalizedName
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geocodeId
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        atmospheres {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisines {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisineNames
        awards {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        seatingOptions {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        priceRange
        imagesSmall
        images
        imagesFull
        timezoneName
        sortOrder
        isActive
        specialEventDate
        eventSalesClosed
        eventSoldOut
        badgeText
        isTest
        isSignatureCollection
        isInsider
        salesStartDate
        salesEndDate
        eventDates
        isSoldOut
        isCardholderExclusive
        venueType
        experienceId
        isHidden
        relatedVenues {
          items {
            id
            venueId
            relatedVenueId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            relatedVenue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        partners {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      relatedVenue {
        id
        name
        airtableId
        sevenRoomsVenueId
        sevenRoomsMunicipalityId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        normalizedName
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geocodeId
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        atmospheres {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisines {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisineNames
        awards {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        seatingOptions {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        priceRange
        imagesSmall
        images
        imagesFull
        timezoneName
        sortOrder
        isActive
        specialEventDate
        eventSalesClosed
        eventSoldOut
        badgeText
        isTest
        isSignatureCollection
        isInsider
        salesStartDate
        salesEndDate
        eventDates
        isSoldOut
        isCardholderExclusive
        venueType
        experienceId
        isHidden
        relatedVenues {
          items {
            id
            venueId
            relatedVenueId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            relatedVenue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        partners {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createVenuePartner = /* GraphQL */ `
  mutation CreateVenuePartner(
    $input: CreateVenuePartnerInput!
    $condition: ModelVenuePartnerConditionInput
  ) {
    createVenuePartner(input: $input, condition: $condition) {
      id
      venueId
      partnerId
      venue {
        id
        name
        airtableId
        sevenRoomsVenueId
        sevenRoomsMunicipalityId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        normalizedName
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geocodeId
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        atmospheres {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisines {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisineNames
        awards {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        seatingOptions {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        priceRange
        imagesSmall
        images
        imagesFull
        timezoneName
        sortOrder
        isActive
        specialEventDate
        eventSalesClosed
        eventSoldOut
        badgeText
        isTest
        isSignatureCollection
        isInsider
        salesStartDate
        salesEndDate
        eventDates
        isSoldOut
        isCardholderExclusive
        venueType
        experienceId
        isHidden
        relatedVenues {
          items {
            id
            venueId
            relatedVenueId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            relatedVenue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        partners {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      partner {
        id
        name
        airtableId
        isActive
        icon
        venues {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateVenuePartner = /* GraphQL */ `
  mutation UpdateVenuePartner(
    $input: UpdateVenuePartnerInput!
    $condition: ModelVenuePartnerConditionInput
  ) {
    updateVenuePartner(input: $input, condition: $condition) {
      id
      venueId
      partnerId
      venue {
        id
        name
        airtableId
        sevenRoomsVenueId
        sevenRoomsMunicipalityId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        normalizedName
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geocodeId
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        atmospheres {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisines {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisineNames
        awards {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        seatingOptions {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        priceRange
        imagesSmall
        images
        imagesFull
        timezoneName
        sortOrder
        isActive
        specialEventDate
        eventSalesClosed
        eventSoldOut
        badgeText
        isTest
        isSignatureCollection
        isInsider
        salesStartDate
        salesEndDate
        eventDates
        isSoldOut
        isCardholderExclusive
        venueType
        experienceId
        isHidden
        relatedVenues {
          items {
            id
            venueId
            relatedVenueId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            relatedVenue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        partners {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      partner {
        id
        name
        airtableId
        isActive
        icon
        venues {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteVenuePartner = /* GraphQL */ `
  mutation DeleteVenuePartner(
    $input: DeleteVenuePartnerInput!
    $condition: ModelVenuePartnerConditionInput
  ) {
    deleteVenuePartner(input: $input, condition: $condition) {
      id
      venueId
      partnerId
      venue {
        id
        name
        airtableId
        sevenRoomsVenueId
        sevenRoomsMunicipalityId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        normalizedName
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geocodeId
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        atmospheres {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisines {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisineNames
        awards {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        seatingOptions {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        priceRange
        imagesSmall
        images
        imagesFull
        timezoneName
        sortOrder
        isActive
        specialEventDate
        eventSalesClosed
        eventSoldOut
        badgeText
        isTest
        isSignatureCollection
        isInsider
        salesStartDate
        salesEndDate
        eventDates
        isSoldOut
        isCardholderExclusive
        venueType
        experienceId
        isHidden
        relatedVenues {
          items {
            id
            venueId
            relatedVenueId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            relatedVenue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        partners {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      partner {
        id
        name
        airtableId
        isActive
        icon
        venues {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createVenueCategory = /* GraphQL */ `
  mutation CreateVenueCategory(
    $input: CreateVenueCategoryInput!
    $condition: ModelVenueCategoryConditionInput
  ) {
    createVenueCategory(input: $input, condition: $condition) {
      id
      venueId
      categoryId
      venue {
        id
        name
        airtableId
        sevenRoomsVenueId
        sevenRoomsMunicipalityId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        normalizedName
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geocodeId
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        atmospheres {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisines {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisineNames
        awards {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        seatingOptions {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        priceRange
        imagesSmall
        images
        imagesFull
        timezoneName
        sortOrder
        isActive
        specialEventDate
        eventSalesClosed
        eventSoldOut
        badgeText
        isTest
        isSignatureCollection
        isInsider
        salesStartDate
        salesEndDate
        eventDates
        isSoldOut
        isCardholderExclusive
        venueType
        experienceId
        isHidden
        relatedVenues {
          items {
            id
            venueId
            relatedVenueId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            relatedVenue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        partners {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      category {
        id
        name
        airtableId
        description
        sortOrder
        status
        isActive
        isHighlighted
        venues {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateVenueCategory = /* GraphQL */ `
  mutation UpdateVenueCategory(
    $input: UpdateVenueCategoryInput!
    $condition: ModelVenueCategoryConditionInput
  ) {
    updateVenueCategory(input: $input, condition: $condition) {
      id
      venueId
      categoryId
      venue {
        id
        name
        airtableId
        sevenRoomsVenueId
        sevenRoomsMunicipalityId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        normalizedName
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geocodeId
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        atmospheres {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisines {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisineNames
        awards {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        seatingOptions {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        priceRange
        imagesSmall
        images
        imagesFull
        timezoneName
        sortOrder
        isActive
        specialEventDate
        eventSalesClosed
        eventSoldOut
        badgeText
        isTest
        isSignatureCollection
        isInsider
        salesStartDate
        salesEndDate
        eventDates
        isSoldOut
        isCardholderExclusive
        venueType
        experienceId
        isHidden
        relatedVenues {
          items {
            id
            venueId
            relatedVenueId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            relatedVenue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        partners {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      category {
        id
        name
        airtableId
        description
        sortOrder
        status
        isActive
        isHighlighted
        venues {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteVenueCategory = /* GraphQL */ `
  mutation DeleteVenueCategory(
    $input: DeleteVenueCategoryInput!
    $condition: ModelVenueCategoryConditionInput
  ) {
    deleteVenueCategory(input: $input, condition: $condition) {
      id
      venueId
      categoryId
      venue {
        id
        name
        airtableId
        sevenRoomsVenueId
        sevenRoomsMunicipalityId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        normalizedName
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geocodeId
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        atmospheres {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisines {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisineNames
        awards {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        seatingOptions {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        priceRange
        imagesSmall
        images
        imagesFull
        timezoneName
        sortOrder
        isActive
        specialEventDate
        eventSalesClosed
        eventSoldOut
        badgeText
        isTest
        isSignatureCollection
        isInsider
        salesStartDate
        salesEndDate
        eventDates
        isSoldOut
        isCardholderExclusive
        venueType
        experienceId
        isHidden
        relatedVenues {
          items {
            id
            venueId
            relatedVenueId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            relatedVenue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        partners {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      category {
        id
        name
        airtableId
        description
        sortOrder
        status
        isActive
        isHighlighted
        venues {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createVenueAtmosphere = /* GraphQL */ `
  mutation CreateVenueAtmosphere(
    $input: CreateVenueAtmosphereInput!
    $condition: ModelVenueAtmosphereConditionInput
  ) {
    createVenueAtmosphere(input: $input, condition: $condition) {
      id
      venueId
      atmosphereId
      venue {
        id
        name
        airtableId
        sevenRoomsVenueId
        sevenRoomsMunicipalityId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        normalizedName
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geocodeId
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        atmospheres {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisines {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisineNames
        awards {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        seatingOptions {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        priceRange
        imagesSmall
        images
        imagesFull
        timezoneName
        sortOrder
        isActive
        specialEventDate
        eventSalesClosed
        eventSoldOut
        badgeText
        isTest
        isSignatureCollection
        isInsider
        salesStartDate
        salesEndDate
        eventDates
        isSoldOut
        isCardholderExclusive
        venueType
        experienceId
        isHidden
        relatedVenues {
          items {
            id
            venueId
            relatedVenueId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            relatedVenue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        partners {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      atmosphere {
        id
        name
        airtableId
        isActive
        venues {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateVenueAtmosphere = /* GraphQL */ `
  mutation UpdateVenueAtmosphere(
    $input: UpdateVenueAtmosphereInput!
    $condition: ModelVenueAtmosphereConditionInput
  ) {
    updateVenueAtmosphere(input: $input, condition: $condition) {
      id
      venueId
      atmosphereId
      venue {
        id
        name
        airtableId
        sevenRoomsVenueId
        sevenRoomsMunicipalityId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        normalizedName
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geocodeId
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        atmospheres {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisines {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisineNames
        awards {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        seatingOptions {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        priceRange
        imagesSmall
        images
        imagesFull
        timezoneName
        sortOrder
        isActive
        specialEventDate
        eventSalesClosed
        eventSoldOut
        badgeText
        isTest
        isSignatureCollection
        isInsider
        salesStartDate
        salesEndDate
        eventDates
        isSoldOut
        isCardholderExclusive
        venueType
        experienceId
        isHidden
        relatedVenues {
          items {
            id
            venueId
            relatedVenueId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            relatedVenue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        partners {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      atmosphere {
        id
        name
        airtableId
        isActive
        venues {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteVenueAtmosphere = /* GraphQL */ `
  mutation DeleteVenueAtmosphere(
    $input: DeleteVenueAtmosphereInput!
    $condition: ModelVenueAtmosphereConditionInput
  ) {
    deleteVenueAtmosphere(input: $input, condition: $condition) {
      id
      venueId
      atmosphereId
      venue {
        id
        name
        airtableId
        sevenRoomsVenueId
        sevenRoomsMunicipalityId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        normalizedName
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geocodeId
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        atmospheres {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisines {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisineNames
        awards {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        seatingOptions {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        priceRange
        imagesSmall
        images
        imagesFull
        timezoneName
        sortOrder
        isActive
        specialEventDate
        eventSalesClosed
        eventSoldOut
        badgeText
        isTest
        isSignatureCollection
        isInsider
        salesStartDate
        salesEndDate
        eventDates
        isSoldOut
        isCardholderExclusive
        venueType
        experienceId
        isHidden
        relatedVenues {
          items {
            id
            venueId
            relatedVenueId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            relatedVenue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        partners {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      atmosphere {
        id
        name
        airtableId
        isActive
        venues {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createVenueCuisine = /* GraphQL */ `
  mutation CreateVenueCuisine(
    $input: CreateVenueCuisineInput!
    $condition: ModelVenueCuisineConditionInput
  ) {
    createVenueCuisine(input: $input, condition: $condition) {
      id
      venueId
      cuisineId
      venue {
        id
        name
        airtableId
        sevenRoomsVenueId
        sevenRoomsMunicipalityId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        normalizedName
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geocodeId
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        atmospheres {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisines {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisineNames
        awards {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        seatingOptions {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        priceRange
        imagesSmall
        images
        imagesFull
        timezoneName
        sortOrder
        isActive
        specialEventDate
        eventSalesClosed
        eventSoldOut
        badgeText
        isTest
        isSignatureCollection
        isInsider
        salesStartDate
        salesEndDate
        eventDates
        isSoldOut
        isCardholderExclusive
        venueType
        experienceId
        isHidden
        relatedVenues {
          items {
            id
            venueId
            relatedVenueId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            relatedVenue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        partners {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      cuisine {
        id
        name
        airtableId
        images
        isActive
        venues {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateVenueCuisine = /* GraphQL */ `
  mutation UpdateVenueCuisine(
    $input: UpdateVenueCuisineInput!
    $condition: ModelVenueCuisineConditionInput
  ) {
    updateVenueCuisine(input: $input, condition: $condition) {
      id
      venueId
      cuisineId
      venue {
        id
        name
        airtableId
        sevenRoomsVenueId
        sevenRoomsMunicipalityId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        normalizedName
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geocodeId
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        atmospheres {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisines {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisineNames
        awards {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        seatingOptions {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        priceRange
        imagesSmall
        images
        imagesFull
        timezoneName
        sortOrder
        isActive
        specialEventDate
        eventSalesClosed
        eventSoldOut
        badgeText
        isTest
        isSignatureCollection
        isInsider
        salesStartDate
        salesEndDate
        eventDates
        isSoldOut
        isCardholderExclusive
        venueType
        experienceId
        isHidden
        relatedVenues {
          items {
            id
            venueId
            relatedVenueId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            relatedVenue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        partners {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      cuisine {
        id
        name
        airtableId
        images
        isActive
        venues {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteVenueCuisine = /* GraphQL */ `
  mutation DeleteVenueCuisine(
    $input: DeleteVenueCuisineInput!
    $condition: ModelVenueCuisineConditionInput
  ) {
    deleteVenueCuisine(input: $input, condition: $condition) {
      id
      venueId
      cuisineId
      venue {
        id
        name
        airtableId
        sevenRoomsVenueId
        sevenRoomsMunicipalityId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        normalizedName
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geocodeId
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        atmospheres {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisines {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisineNames
        awards {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        seatingOptions {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        priceRange
        imagesSmall
        images
        imagesFull
        timezoneName
        sortOrder
        isActive
        specialEventDate
        eventSalesClosed
        eventSoldOut
        badgeText
        isTest
        isSignatureCollection
        isInsider
        salesStartDate
        salesEndDate
        eventDates
        isSoldOut
        isCardholderExclusive
        venueType
        experienceId
        isHidden
        relatedVenues {
          items {
            id
            venueId
            relatedVenueId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            relatedVenue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        partners {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      cuisine {
        id
        name
        airtableId
        images
        isActive
        venues {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createVenueAward = /* GraphQL */ `
  mutation CreateVenueAward(
    $input: CreateVenueAwardInput!
    $condition: ModelVenueAwardConditionInput
  ) {
    createVenueAward(input: $input, condition: $condition) {
      id
      venueId
      awardId
      venue {
        id
        name
        airtableId
        sevenRoomsVenueId
        sevenRoomsMunicipalityId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        normalizedName
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geocodeId
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        atmospheres {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisines {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisineNames
        awards {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        seatingOptions {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        priceRange
        imagesSmall
        images
        imagesFull
        timezoneName
        sortOrder
        isActive
        specialEventDate
        eventSalesClosed
        eventSoldOut
        badgeText
        isTest
        isSignatureCollection
        isInsider
        salesStartDate
        salesEndDate
        eventDates
        isSoldOut
        isCardholderExclusive
        venueType
        experienceId
        isHidden
        relatedVenues {
          items {
            id
            venueId
            relatedVenueId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            relatedVenue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        partners {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      award {
        id
        name
        airtableId
        primaryText
        secondaryText
        icon
        status
        isActive
        venues {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateVenueAward = /* GraphQL */ `
  mutation UpdateVenueAward(
    $input: UpdateVenueAwardInput!
    $condition: ModelVenueAwardConditionInput
  ) {
    updateVenueAward(input: $input, condition: $condition) {
      id
      venueId
      awardId
      venue {
        id
        name
        airtableId
        sevenRoomsVenueId
        sevenRoomsMunicipalityId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        normalizedName
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geocodeId
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        atmospheres {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisines {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisineNames
        awards {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        seatingOptions {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        priceRange
        imagesSmall
        images
        imagesFull
        timezoneName
        sortOrder
        isActive
        specialEventDate
        eventSalesClosed
        eventSoldOut
        badgeText
        isTest
        isSignatureCollection
        isInsider
        salesStartDate
        salesEndDate
        eventDates
        isSoldOut
        isCardholderExclusive
        venueType
        experienceId
        isHidden
        relatedVenues {
          items {
            id
            venueId
            relatedVenueId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            relatedVenue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        partners {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      award {
        id
        name
        airtableId
        primaryText
        secondaryText
        icon
        status
        isActive
        venues {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteVenueAward = /* GraphQL */ `
  mutation DeleteVenueAward(
    $input: DeleteVenueAwardInput!
    $condition: ModelVenueAwardConditionInput
  ) {
    deleteVenueAward(input: $input, condition: $condition) {
      id
      venueId
      awardId
      venue {
        id
        name
        airtableId
        sevenRoomsVenueId
        sevenRoomsMunicipalityId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        normalizedName
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geocodeId
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        atmospheres {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisines {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisineNames
        awards {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        seatingOptions {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        priceRange
        imagesSmall
        images
        imagesFull
        timezoneName
        sortOrder
        isActive
        specialEventDate
        eventSalesClosed
        eventSoldOut
        badgeText
        isTest
        isSignatureCollection
        isInsider
        salesStartDate
        salesEndDate
        eventDates
        isSoldOut
        isCardholderExclusive
        venueType
        experienceId
        isHidden
        relatedVenues {
          items {
            id
            venueId
            relatedVenueId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            relatedVenue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        partners {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      award {
        id
        name
        airtableId
        primaryText
        secondaryText
        icon
        status
        isActive
        venues {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createVenueTag = /* GraphQL */ `
  mutation CreateVenueTag(
    $input: CreateVenueTagInput!
    $condition: ModelVenueTagConditionInput
  ) {
    createVenueTag(input: $input, condition: $condition) {
      id
      venueId
      tagId
      venue {
        id
        name
        airtableId
        sevenRoomsVenueId
        sevenRoomsMunicipalityId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        normalizedName
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geocodeId
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        atmospheres {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisines {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisineNames
        awards {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        seatingOptions {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        priceRange
        imagesSmall
        images
        imagesFull
        timezoneName
        sortOrder
        isActive
        specialEventDate
        eventSalesClosed
        eventSoldOut
        badgeText
        isTest
        isSignatureCollection
        isInsider
        salesStartDate
        salesEndDate
        eventDates
        isSoldOut
        isCardholderExclusive
        venueType
        experienceId
        isHidden
        relatedVenues {
          items {
            id
            venueId
            relatedVenueId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            relatedVenue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        partners {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      tag {
        id
        name
        airtableId
        isActive
        venues {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateVenueTag = /* GraphQL */ `
  mutation UpdateVenueTag(
    $input: UpdateVenueTagInput!
    $condition: ModelVenueTagConditionInput
  ) {
    updateVenueTag(input: $input, condition: $condition) {
      id
      venueId
      tagId
      venue {
        id
        name
        airtableId
        sevenRoomsVenueId
        sevenRoomsMunicipalityId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        normalizedName
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geocodeId
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        atmospheres {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisines {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisineNames
        awards {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        seatingOptions {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        priceRange
        imagesSmall
        images
        imagesFull
        timezoneName
        sortOrder
        isActive
        specialEventDate
        eventSalesClosed
        eventSoldOut
        badgeText
        isTest
        isSignatureCollection
        isInsider
        salesStartDate
        salesEndDate
        eventDates
        isSoldOut
        isCardholderExclusive
        venueType
        experienceId
        isHidden
        relatedVenues {
          items {
            id
            venueId
            relatedVenueId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            relatedVenue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        partners {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      tag {
        id
        name
        airtableId
        isActive
        venues {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteVenueTag = /* GraphQL */ `
  mutation DeleteVenueTag(
    $input: DeleteVenueTagInput!
    $condition: ModelVenueTagConditionInput
  ) {
    deleteVenueTag(input: $input, condition: $condition) {
      id
      venueId
      tagId
      venue {
        id
        name
        airtableId
        sevenRoomsVenueId
        sevenRoomsMunicipalityId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        normalizedName
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geocodeId
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        atmospheres {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisines {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisineNames
        awards {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        seatingOptions {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        priceRange
        imagesSmall
        images
        imagesFull
        timezoneName
        sortOrder
        isActive
        specialEventDate
        eventSalesClosed
        eventSoldOut
        badgeText
        isTest
        isSignatureCollection
        isInsider
        salesStartDate
        salesEndDate
        eventDates
        isSoldOut
        isCardholderExclusive
        venueType
        experienceId
        isHidden
        relatedVenues {
          items {
            id
            venueId
            relatedVenueId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            relatedVenue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        partners {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      tag {
        id
        name
        airtableId
        isActive
        venues {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createVenueSeatingOption = /* GraphQL */ `
  mutation CreateVenueSeatingOption(
    $input: CreateVenueSeatingOptionInput!
    $condition: ModelVenueSeatingOptionConditionInput
  ) {
    createVenueSeatingOption(input: $input, condition: $condition) {
      id
      venueId
      seatingOptionId
      venue {
        id
        name
        airtableId
        sevenRoomsVenueId
        sevenRoomsMunicipalityId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        normalizedName
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geocodeId
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        atmospheres {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisines {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisineNames
        awards {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        seatingOptions {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        priceRange
        imagesSmall
        images
        imagesFull
        timezoneName
        sortOrder
        isActive
        specialEventDate
        eventSalesClosed
        eventSoldOut
        badgeText
        isTest
        isSignatureCollection
        isInsider
        salesStartDate
        salesEndDate
        eventDates
        isSoldOut
        isCardholderExclusive
        venueType
        experienceId
        isHidden
        relatedVenues {
          items {
            id
            venueId
            relatedVenueId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            relatedVenue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        partners {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      seatingOption {
        id
        name
        airtableId
        isActive
        venues {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateVenueSeatingOption = /* GraphQL */ `
  mutation UpdateVenueSeatingOption(
    $input: UpdateVenueSeatingOptionInput!
    $condition: ModelVenueSeatingOptionConditionInput
  ) {
    updateVenueSeatingOption(input: $input, condition: $condition) {
      id
      venueId
      seatingOptionId
      venue {
        id
        name
        airtableId
        sevenRoomsVenueId
        sevenRoomsMunicipalityId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        normalizedName
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geocodeId
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        atmospheres {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisines {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisineNames
        awards {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        seatingOptions {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        priceRange
        imagesSmall
        images
        imagesFull
        timezoneName
        sortOrder
        isActive
        specialEventDate
        eventSalesClosed
        eventSoldOut
        badgeText
        isTest
        isSignatureCollection
        isInsider
        salesStartDate
        salesEndDate
        eventDates
        isSoldOut
        isCardholderExclusive
        venueType
        experienceId
        isHidden
        relatedVenues {
          items {
            id
            venueId
            relatedVenueId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            relatedVenue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        partners {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      seatingOption {
        id
        name
        airtableId
        isActive
        venues {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteVenueSeatingOption = /* GraphQL */ `
  mutation DeleteVenueSeatingOption(
    $input: DeleteVenueSeatingOptionInput!
    $condition: ModelVenueSeatingOptionConditionInput
  ) {
    deleteVenueSeatingOption(input: $input, condition: $condition) {
      id
      venueId
      seatingOptionId
      venue {
        id
        name
        airtableId
        sevenRoomsVenueId
        sevenRoomsMunicipalityId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        normalizedName
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geocodeId
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        atmospheres {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisines {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisineNames
        awards {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        seatingOptions {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        priceRange
        imagesSmall
        images
        imagesFull
        timezoneName
        sortOrder
        isActive
        specialEventDate
        eventSalesClosed
        eventSoldOut
        badgeText
        isTest
        isSignatureCollection
        isInsider
        salesStartDate
        salesEndDate
        eventDates
        isSoldOut
        isCardholderExclusive
        venueType
        experienceId
        isHidden
        relatedVenues {
          items {
            id
            venueId
            relatedVenueId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            relatedVenue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        partners {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      seatingOption {
        id
        name
        airtableId
        isActive
        venues {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
