import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { HOMEPAGE_LINK_ALT_TEXT } from '../../../assets/copy';
import LogoSvg from '../../../assets/icons/logo.svg';
import { WIDTH_BREAKPOINT } from '../../../utils/constants/Breakpoints';
import { ERROR_PAGE_TYPES } from '../../../utils/constants/ErrorPageTypes';
import { useViewport } from '../../../utils/useViewport';
import DesktopAppBar from './DesktopAppBar';
import MobileAppBar from './MobileAppBar';
import { useAuth } from '../../../utils/useAuth';
import { trackClick } from '../../../utils/useOneTag';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 56,
    paddingTop: theme.spacing(5),
    paddingLeft: theme.spacing(2),
    borderBottom: '1px solid #E4E5E5',
    position: 'fixed',
    top: 0,
    zIndex: 999,
    background: theme.palette.background.white,
    width: '100%',
    [theme.breakpoints.up('xl')]: {
      flexWrap: 'nowrap',
      height: 64,
      padding: theme.spacing(0, 2),
    },
    [theme.breakpoints.up('xxl')]: {
      padding: theme.spacing(0, 5),
    },
  },
  logo: {
    maxWidth: '100%',
    position: 'absolute',
    left: 16,
    top: 12,
    cursor: 'pointer',
    [theme.breakpoints.up('xl')]: {
      minWidth: 142,
      position: 'static',
    },
    '-webkit-user-select': 'none',
  },
  buttons: {
    bottom: 32,
  },
}));

const ROUTES_TO_HIDE = new Set(['/onboarding', '/users']);
const widthBreakpoint = WIDTH_BREAKPOINT;

const AppBar = () => {
  const classes = useStyles();
  const { width } = useViewport();
  const { errorType } = useSelector((state) => state.error);
  const location = useLocation();
  const { user } = useAuth();
  const { market } = useSelector((state) => state.markets);

  const handleAppBarClick = (event) => {
    trackClick(event, "app-bar-logo", "Link");
  }

  const shouldShow = () => {
    const isAuthenticated = user?.session?.isAuthenticated;
    const isOnboarded = user?.marketId != null;
    const isMarketSelected = market?.id != null;
    const shouldHideOnRoute =
      ROUTES_TO_HIDE.has(location.pathname) ||
      location.pathname.startsWith('/permalink');

    if (
      !isAuthenticated ||
      !isOnboarded ||
      !isMarketSelected ||
      shouldHideOnRoute
    ) {
      return false;
    }

    if (location.pathname !== '/error') {
      return true;
    }

    const error = ERROR_PAGE_TYPES[errorType] || ERROR_PAGE_TYPES.GENERIC;
    return error.shouldShowAppBar === true;
  };

  return (
    shouldShow() && (
      <header>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className={classes.root}>
          <Grid item>
            <Link
              aria-label={HOMEPAGE_LINK_ALT_TEXT}
              to="/"
              onClick={handleAppBarClick}
              data-cy="app-bar-logo">
              <img
                aria-hidden="true"
                src={LogoSvg}
                alt={''}
                className={classes.logo}
              />
            </Link>
          </Grid>
          {width >= widthBreakpoint ? (
            <DesktopAppBar buttonsClassName={classes.buttons} />
          ) : (
            <MobileAppBar buttonsClassName={classes.buttons} />
          )}
        </Grid>
      </header>
    )
  );
};

export default AppBar;
