import React, { useCallback, useEffect, useState } from 'react';

import { Box } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import TextInput from '../../components/core/TextInput';
import PhoneInput from '../../components/core/PhoneInput';
import {
  CONTACT_NUMBER_EMPTY_ERROR,
  INVALID_PHONE_INPUT_ERROR,
  PHONE_NUMBER_LABEL,
  PHONE_NUMBER_PLACEHOLDER,
} from '../../assets/copy';
import AppTheme from '../../theme';
import { actionSetDraftReservation } from '../../store/Reservations/ReservationsAction';
import { validatePhoneNumber } from '../../utils/validatePhoneNumber';
import Typography from '../../components/overrides/Typography';
import { PHONE_NUMBER_VALIDATION_TYPES } from '../../utils/constants/PhoneNumberValidationTypes';

const useStyle = makeStyles((theme) => ({
  error: {
    color: theme.palette.red[50],
    marginTop: theme.spacing(1),
  },
}));

const PhoneField = () => {
  const classes = useStyle();

  const { draftReservation } = useSelector((state) => state.reservations);
  const dispatch = useDispatch();

  const [formData, setFormData] = useState(draftReservation);
  const [inputBorder, setInputBorder] = useState();

  const {
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      phone: '',
    },
  });
  const handleOnChange = useCallback((e) => {
    setFormData((prevValue) => ({ ...prevValue, phoneNumber: e.target.value }));
  }, []);

  useEffect(() => {
    dispatch(actionSetDraftReservation(formData));
  }, [formData?.phoneNumber, dispatch, formData]);

  useEffect(() => {
    !validatePhoneNumber(formData?.phoneNumber)
      ? setInputBorder(`2px solid ${AppTheme.palette.red[50]}`)
      : setInputBorder(`2px solid ${AppTheme.palette.primary.main}`);
  }, [formData?.phoneNumber]);

  return (
    <>
      <Box>
        <Controller
          name="phone"
          control={control}
          render={({ field }) => (
            <TextInput
              label={PHONE_NUMBER_LABEL}
              name="phone"
              autoComplete="off"
              margin="dense"
              variant="outlined"
              fullWidth
              inputProps={{
                inputMode: 'tel',
                style: { border: inputBorder },
              }}
              placeholder={PHONE_NUMBER_PLACEHOLDER}
              inputComponent={PhoneInput}
              {...field}
              value={formData?.phoneNumber}
              onChange={(e) => {
                field?.onChange(e);
                handleOnChange(e);
              }}
            />
          )}
          rules={{
            required: true,
            validate: (values) => validatePhoneNumber(values),
          }}
        />
        {errors?.phone?.type === PHONE_NUMBER_VALIDATION_TYPES.REQUIRED && (
          <Typography variant="error" color="error" className={classes.error}>
            {CONTACT_NUMBER_EMPTY_ERROR}
          </Typography>
        )}
        {errors?.phone?.type === PHONE_NUMBER_VALIDATION_TYPES.VALIDATE && (
          <Typography variant="error" color="error" className={classes.error}>
            {INVALID_PHONE_INPUT_ERROR}
          </Typography>
        )}
      </Box>
    </>
  );
};

export default PhoneField;
