import {
  Box,
  Backdrop,
  Fade,
  makeStyles,
  Modal,
  Card,
  CardContent,
  CardMedia,
} from '@material-ui/core';
import Divider from '../../components/overrides/Divider';
import React, { useCallback } from 'react';
import {
  EXCLUSIVE_ALERT_TITLE,
  CARDHOLDER_DESCRIPTION,
  INSIDER_DESCRIPTION,
  CARDHOLDER_TIMESLOT_TITLE,
  INSIDER_TIMESLOT_TITLE,
} from '../../assets/copy';
import Typography from '../../components/overrides/Typography';
import { useViewport } from '../../utils/useViewport';
import CardholderTable from '../../assets/images/cardholder-table.png';
import InsiderTable from '../../assets/images/insider-table.png';
import SignatureSvg from '../../assets/icons/grv-signature.svg';
import { WIDTH_BREAKPOINT } from '../../utils/constants/Breakpoints';
import CloseButton from '../../components/core/CloseButton';
import { enterKeyPressHandler, isEscapeKeyEvent } from '../../utils/keyPresses';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'center',
    [theme.breakpoints.up('xl')]: {
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  content: {
    backgroundColor: theme.palette.background.white,
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.2)',
    borderRadius: '16px 16px 0 0',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '75%',
    overflow: 'scroll',
    '-webkit-overflow-scrolling': 'touch',
    padding: 0,
    '&::-webkit-scrollbar': {
      background: 'transparent' /* hide scroll bar on Chrome/Safari/Webkit */,
      width: 0,
    },
    '-ms-overflow-style': 'none' /* hide scroll bar on IE and Edge */,
    scrollbarWidth: 'none' /* hide scroll bar on Firefox */,
    [theme.breakpoints.up('xl')]: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'flex-end',
      alignContent: 'flex-end',
      maxWidth: 410,
      height: 'auto',
      borderRadius: 16,
    },
  },
  contentSection: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up('xl')]: {
      flexWrap: 'wrap',
      display: 'flex',
      width: '100%',
      paddingLeft: theme.spacing(2.5),
      paddingRight: theme.spacing(2.5),
    },
  },
  closeBox: {
    [theme.breakpoints.up('xl')]: {
      margin: theme.spacing(3.75, 3.25),
    },
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(2.5, 1.5, 2.5, 0),
      position: 'sticky',
      top: 0,
      backgroundColor: theme.palette.background.white,
      zIndex: 1,
      height: 'auto',
    },
  },
  title: {
    fontFamily: 'Optimist',
    fontSize: '14px',
    paddingTop: theme.spacing(2),
    textAlign: 'center',
  },
  titleSection: {
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
  },
  timeSlot: {
    padding: `${theme.spacing(3)} ${theme.spacing(2)} 0`,
    margin: `${theme.spacing(2)} auto ${theme.spacing(2.5)}`,
    background: theme.palette.chip.background,
    width: 'fit-content',
  },
  close: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  img: {
    display: 'flex',
    margin: theme.spacing(0, 'auto'),
  },
  card: {
    boxShadow: 'none',
    padding: theme.spacing(2.25, 0),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3, 0),
    },
    width: '100%',
    background: theme.palette.chip.background,
    '&:first-of-type': {
      paddingTop: 0,
    },
  },
  wrapper: {
    display: 'grid',
    gridColumnGap: theme.spacing(1.5),
    gridTemplateColumns: 'auto 1fr',
    position: 'relative',
    background: theme.palette.chip.background,
    [theme.breakpoints.up('xl')]: {
      gridColumnGap: 0,
      padding: 0,
    },
  },
  cardImage: {
    height: 40,
    width: 77,
    position: 'relative',
  },
  cardContent: {
    userSelect: 'none',
    alignSelf: 'center',
    gridColumn: 2,
    flex: 'auto',
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
    [theme.breakpoints.up('xl')]: {
      marginLeft: theme.spacing(3),
    },
  },
}));

const ExclusiveAlert = ({ isOpen, handleCloseAlert }) => {
  const classes = useStyles();
  const { width } = useViewport();
  const isDesktopView = width > WIDTH_BREAKPOINT;

  const handleClose = useCallback(() => {
    handleCloseAlert();
  }, [handleCloseAlert]);

  const onKeyPressHandler =
    (enterKeyPressHandler || isEscapeKeyEvent) && handleClose;

  const closeButton = useCallback(
    () => (
      <Box
        className={classes.closeBox}
        display="flex"
        justifyContent="space-between"
        onKeyPress={onKeyPressHandler}>
        <Typography
          id="table_parameter_heading"
          variant={isDesktopView ? 'medium2Semibold' : 'medium1Semibold'}
          component="h1"
        />
        <CloseButton
          close={handleClose}
          className={classes.close}
          width={isDesktopView ? 21 : 14}
          height={isDesktopView ? 21 : 14}
          fill="#0D74AF"
          tabIndex={0}
          variant="svg"
        />
      </Box>
    ),
    [
      classes.close,
      classes.closeBox,
      handleClose,
      isDesktopView,
      onKeyPressHandler,
    ]
  );

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      closeAfterTransition
      className={classes.modal}>
      <Fade in={isOpen}>
        <Box
          aria-labelledby="exclusive_alert_heading"
          id="exclusive_alert"
          className={classes.content}>
          {isDesktopView && closeButton()}
          <Box className={classes.contentSection}>
            {!isDesktopView && closeButton()}
            <img alt="" className={classes.img} src={SignatureSvg} />
            <Box className={classes.titleSection}>
              <Typography
                component="h1"
                className={classes.title}
                variant="medium1Normal">
                {EXCLUSIVE_ALERT_TITLE}
              </Typography>
            </Box>
            <Box className={classes.timeSlot}>
              <Card className={classes.card}>
                <Box className={classes.wrapper}>
                  <CardMedia
                    className={classes.cardImage}
                    image={CardholderTable}
                  />
                  <CardContent className={classes.cardContent}>
                    <Box>
                      <Typography variant="small2Semibold">
                        {CARDHOLDER_TIMESLOT_TITLE}
                      </Typography>
                      <Typography variant="small3Normal">
                        {CARDHOLDER_DESCRIPTION}
                      </Typography>
                    </Box>
                  </CardContent>
                </Box>
              </Card>
              <Divider />
              <Card className={classes.card}>
                <Box className={classes.wrapper}>
                  <CardMedia
                    className={classes.cardImage}
                    image={InsiderTable}
                  />
                  <CardContent className={classes.cardContent}>
                    <Box>
                      <Typography variant="small2Semibold">
                        {INSIDER_TIMESLOT_TITLE}
                      </Typography>
                      <Typography variant="small3Normal">
                        {INSIDER_DESCRIPTION}
                      </Typography>
                    </Box>
                  </CardContent>
                </Box>
              </Card>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ExclusiveAlert;
