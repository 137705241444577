import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import NavigationLink from '../NavigationLink';
import { DESKTOP_NAVIGATION_LINKS } from '../../../utils/constants/Links';
import { trackClick } from '../../../utils/useOneTag';

const useStyles = makeStyles((theme) => ({
  root: {
    gap: theme.spacing(2),
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('xxl')]: {
      gap: theme.spacing(5),
      padding: theme.spacing(0, 7),
    },
  },
}));

const DesktopMenu = () => {
  const classes = useStyles();
  const handleDesktopNavClick = (event, dataCy) => {
    trackClick(event, dataCy, "Link");
  }
  return (
    <Grid className={classes.root} container item>
      {DESKTOP_NAVIGATION_LINKS.map(({ label, path, variant, dataCy }) => (
        <NavigationLink
          key={path}
          label={label}
          path={path}
          variant={variant}
          data-cy={dataCy}
          onClick={(e) => {handleDesktopNavClick(e, dataCy)}}
        />
      ))}
    </Grid>
  );
};

export default DesktopMenu;
