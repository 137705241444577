import React from 'react';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';
import COLORS from './colorsEnum';

const StyleChip = withStyles({
  root: ({ type }) => {
    return {
      fontWeight: 400,
      fontFamily: 'Optimist',
      fontStyle: 'normal',
      fontSize: '12px',
      cursor: 'pointer',
      ...COLORS[type],
    };
  },
})(Chip);

const Badge = ({ text, type }) => {
  return <StyleChip label={text} size="small" variant="outlined" type={type} />;
};

export default Badge;
