import React, { useCallback } from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import Typography from '../overrides/Typography';
import SelectText from '../../components/core/SelectText';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: theme.spacing(2, 0, 1),
    [theme.breakpoints.up('xl')]: {
      margin: theme.spacing(2, 0, 3),
    },
  },
}));

const DietaryPreferences = ({
  setRestrictions,
  text,
  itemMap,
  list,
  style,
}) => {
  const classes = useStyles();
  const handleSelections = useCallback(
    (item) => {
      const filter = list.filter((selection) => selection.id !== item.id);
      if (filter.length === list.length) {
        setRestrictions((prevState) => [...prevState, item]);
      } else {
        setRestrictions(filter);
      }
    },
    [list, setRestrictions]
  );

  return (
    <Grid container item className={style}>
      <Typography variant="small4Normal">{text}</Typography>
      <Box className={classes.root}>
        {itemMap.map((item) => (
          <SelectText
            role="listbox"
            key={`key-${item.id}`}
            item={item}
            list={list}
            aria-multiselectable="true"
            onClick={() => handleSelections(item)}
          />
        ))}
      </Box>
    </Grid>
  );
};

export default DietaryPreferences;
