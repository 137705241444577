import { useViewport } from '../../utils/useViewport';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { generateISOString, isDateInBetween } from '../../utils/dateTime';
import { RESERVATION_TYPE } from '../../utils/constants/Reservation';
import {
  convertUserReservationItemsForDraft,
  createReservationPayload,
} from '../../utils/reservationFilters';
import {
  actionClearReservationCreateStatus,
  actionClearReservationPaymentRedirectFromStepUp,
  actionClearReservationUpdateStatus,
  actionCreateReservationRequest,
  actionGetReservationRequest,
  actionSetDraftReservation,
} from '../../store/Reservations/ReservationsAction';
import {
  handleCancel,
  handleCancelEdit,
  handleCancelEditReservation,
  handleEdit,
  handleReserveError,
  handleToggleEditModal,
  handleUpdated,
} from './helpers/reserveHelpers';
import {
  CREDIT_CARD_ERROR_TYPE,
  GRATUITY_TYPE,
  PAYMENT_RULE_TYPE,
} from './helpers/paymentAvailabilityTypes';
import {
  getPaymentNonce,
  handlePaymentIntentStripe,
  handleSetupIntentStripe,
} from './helpers/paymentHandlers';
import PageSection from '../../components/core/PageSection';
import { Box } from '@material-ui/core';
import CloseButton from '../../components/core/CloseButton';
import {
  CLOSE_BUTTON_DESCRIPTION,
  CLOSE_BUTTON_LABEL,
  RESERVE_STRIPE_STEP_UP_ABORTED,
  RESERVE_STRIPE_STEP_UP_OR_PAYMENT_FAILED,
} from '../../assets/copy';
import Title from '../../components/reserve/Title';
import ReservationCard from '../../components/reserve/ReservationCard';
import FormFooter from '../../components/reserve/FormFooter';
import CloseButtonForm from '../../components/reserve/CloseButtonForm';
import CancelEditModal from '../../components/reserve/CancelEditModal';
import { makeStyles } from '@material-ui/core/styles';
import { WIDTH_BREAKPOINT } from '../../utils/constants/Breakpoints';
import ReservationDetails from './ReservationDetailsComponent';
import {
  actionUpdateUserDefaultPaymentAccount,
  actionUpdateUserRequest,
} from '../../store/User/UserAction';
import CommonLoading from '../common/CommonLoading';
import { C1_CALLBACK_ERROR_CODES, useAuth } from '../../utils/useAuth';
import { Redirect } from 'react-router-dom';
import { sanitizeTotalAmount, toCents } from '../../utils/currencyHelper';
import { useHeapTrack } from '../../utils/useHeap';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 0),
  },
  close: {},
  closeButtonContainer: {
    paddingBottom: theme.spacing(0.5),
    paddingTop: theme.spacing(1),
    overflow: 'hidden',
    marginLeft: 0,
    marginRight: 0,
    maxWidth: '100%',
    [theme.breakpoints.up('xl')]: {
      marginLeft: '25px',
      marginRight: 'auto',
      maxWidth: 'calc(100% - 25px)',
    },
  },
  desktopLayoutCard: {
    flex: '0 0 350px',
  },
  desktopLayoutContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  desktopLayoutContent: {
    boxSizing: 'border-box',
    maxWidth: `calc(531px + ${theme.spacing(3)})`,
  },
  desktopLayoutTitleContainer: {
    flexGrow: 0,
    maxWidth: '100%',
    flexBasis: '100%',
  },
}));

export const PAYMENT_STATUS_TYPES = Object.freeze({
  SUCCESS: 'success',
  FAIL: 'fail',
  STEP_UP: 'step_up',
});

class PaymentNonceDetailsResponse {
  constructor({ statusType, details }) {
    this.statusType = statusType;
    this.details = details;
  }
}

const Reserve = ({ type, onEditPayment }) => {
  const { EVENTS, trackPageEvent } = useHeapTrack();
  const classes = useStyles();
  const { width } = useViewport();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const { venue } = useSelector((state) => state.venues);
  const reservationCreateStatus = useSelector(
    (state) => state.reservations?.reservationCreateStatus
  );

  const [showAlert, setShowAlert] = useState(false);
  const reservationFetchStatus = useSelector(
    (state) => state.reservations?.status
  );

  const [specialOccasionsWithDatesFlag, setSpecialOccasionsWithDatesFlag] =
    useState({
      Birthday: false,
      Anniversary: false,
    });

  const [name, setName] = useState('');
  const {
    draftReservation,
    reservation,
    reservationUpdateStatus,
    paymentAccount,
    stepUp,
  } = useSelector((state) => state.reservations);
  const { availability } = useSelector((state) => state.availability);
  const venuePayment = draftReservation.payment;
  const paymentRule = venuePayment.ccPaymentRule;

  const isReservationNotAvlbl =
    reservationCreateStatus?.error?.errors?.[0]?.message?.includes(
      'NO_AVAILABILITY'
    ) ||
    reservationUpdateStatus?.error?.errors?.[0]?.message?.includes(
      'NO_AVAILABILITY'
    );

  const [mounted, setMounted] = useState(false);
  const [shouldShowLoader, setShouldShowLoader] = useState(true);
  const [totalAmount, setTotalAmount] = useState(0);
  const [originalAmount, setOriginalAmount] = useState(0);
  const [paymentGratuity, setPaymentGratuity] = useState(0);
  const [paymentSubTotal, setPaymentSubTotal] = useState(0);
  const [paymentServiceCharge, setPaymentServiceCharge] = useState(0);
  const [paymentTax, setPaymentTax] = useState(0);
  const [venuePaymentPolicy, setVenuePaymentPolicy] = useState('');
  const [customTip, setCustomTip] = useState(0);
  const [cardNumberError, setCardNumberError] = useState('');
  const [cardCardExpiryError, setCardExpiryError] = useState('');
  const [cardCvcError, setCardCvcError] = useState('');
  const { navigationMenuVisible, specialOccasions } = useSelector(
    (state) => state.appData
  );
  const { user } = useSelector((state) => state.user);
  const userReservation = moment(draftReservation.date, 'YYYY-MM-DD');
  const userAnniversary = moment(user.userAnniversary, 'MM-DD-YYYY');
  const userBirthday = moment(user.userBirthday, 'MM-DD-YYYY');
  const partnerBirthday = moment(user.partnerBirthday, 'MM-DD-YYYY');
  const [showInfoBlock, setShowInfoBlock] = useState(false);
  const [shouldShowStripeForm, setShouldShowStripeForm] = useState(false);
  const [stripeAlertMessage, setStripeAlertMessage] = useState(null);
  const [venueMerchantId, setVenueMerchantId] = useState(null);
  const [redirectToConfirmPage, setRedirectToConfirmPage] = useState(false);
  const [isPaymentCalculated, setIsPaymentCalculated] = useState(false);

  const checkForSpecialDates = (
    reservationDate,
    specialOccasionDate,
    occasionType
  ) => {
    if (isDateInBetween(reservationDate, specialOccasionDate)) {
      if (!showInfoBlock) {
        setShowInfoBlock(true); // if any of this flag has been switched to true and hasn't been set to true yet then we will display the InfoBlock, instead of looping through all of the object to check if there's a true
      }
      setSpecialOccasionsWithDatesFlag((prev) => {
        return { ...prev, [occasionType]: true };
      });
    }
  };

  useEffect(() => {
    // If not coming from step up redirect, hide the spinner
    if (!stepUp?.shouldRedirect) {
      setShouldShowLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    trackPageEvent(EVENTS.RESERVATION_BOOKING_PAGE.LOAD, {
      venueId: draftReservation.venue.id,
      venueName: draftReservation.venue.name,
      sevenRoomsVenueId: draftReservation.venue.sevenRoomsVenueId,
      dateTime: generateISOString(
        draftReservation.realDateTime,
        draftReservation.venue.timezoneName
      ),
    });
    window.oneTag.track('view', {
      event_name: 'page_view',
      page_title: window.location.pathname,
      merchant_id: draftReservation.venue.sevenRoomsVenueId,
      merchant_name: draftReservation.venue.name,
      merchant_date: generateISOString(
        draftReservation.realDateTime,
        draftReservation.venue.timezoneName
      ),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // This useEffect should only run on the Create Reservation feature
  useEffect(() => {
    if (!isPaymentCalculated || venueMerchantId == null) return;
    if (location.state?.type === RESERVATION_TYPE.UPDATE) return;
    if (!stepUp?.shouldRedirect) return;
    dispatch(actionClearReservationPaymentRedirectFromStepUp());

    if (stepUp?.errorCode != null) {
      console.warn(
        `Step up error code: ${stepUp.errorCode}; fallback to Stripe form`
      );

      const stripeAlertCopy =
        stepUp.errorCode === C1_CALLBACK_ERROR_CODES.STEP_UP_ABORTED
          ? RESERVE_STRIPE_STEP_UP_ABORTED
          : RESERVE_STRIPE_STEP_UP_OR_PAYMENT_FAILED;

      setStripeAlertMessage(stripeAlertCopy);
      setShouldShowStripeForm(true);
      setShouldShowLoader(false);
      return;
    }

    handleReserve();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaymentCalculated, venueMerchantId]);

  useEffect(() => {
    // TODO standardize this - store the availability in one place only, ideally in availability state
    if (availability?.paymentInformation?.accountId != null) {
      setVenueMerchantId(availability.paymentInformation.accountId);
    } else if (
      draftReservation.venue.availability != null &&
      draftReservation.venue.availability.length > 0
    ) {
      setVenueMerchantId(
        draftReservation.venue.availability[0].paymentInformation.accountId
      );
    }
  }, [availability, draftReservation.venue.availability]);

  // This useEffect should only run on the Create Reservation feature
  useEffect(() => {
    if (location.state?.type !== RESERVATION_TYPE.UPDATE) {
      checkForSpecialDates(userReservation, userAnniversary, 'Anniversary');
      checkForSpecialDates(userReservation, userBirthday, 'Birthday');
      checkForSpecialDates(userReservation, partnerBirthday, 'Birthday');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // This useEffect should only run on the Create Reservation feature
  useEffect(() => {
    if (stepUp?.shouldRedirect) return;
    if (location.state?.type !== RESERVATION_TYPE.UPDATE) {
      const filteredSpecialDates = Object.keys(
        specialOccasionsWithDatesFlag
      ).filter((specialDates) => specialOccasionsWithDatesFlag[specialDates]);
      const reservationsOccasions = convertUserReservationItemsForDraft(
        specialOccasions,
        filteredSpecialDates
      );
      const draftReservationClone = { ...draftReservation };
      draftReservationClone.specialOccasions = reservationsOccasions;
      dispatch(actionSetDraftReservation(draftReservationClone));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specialOccasionsWithDatesFlag]);

  const [tipProperties, setTipProperties] = useState({
    18: {
      percentage: 0.18,
      isSelected: false,
    },
    20: {
      percentage: 0.2,
      isSelected: false,
    },
    25: {
      percentage: 0.25,
      isSelected: false,
    },
    30: {
      percentage: 0.3,
      isSelected: false,
    },
    custom: {
      isSelected: false,
    },
  });
  const [reservationObj, setReservationObj] = useState({}); // the value of reservation is based from the venue.payments object 7Rs. This will be hardcoded for now
  const [isPaymentDisabled, setIsPaymentDisabled] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const { redirectToC1Challenge, refreshAccessToken } = useAuth();

  useEffect(() => {
    if (draftReservation && draftReservation.venue) {
      setMounted(true);
    }
  }, [draftReservation]);

  useEffect(() => {
    handleReserveError(
      setShowAlert,
      setIsPaymentDisabled,
      isReservationNotAvlbl
    );
  }, [history, isReservationNotAvlbl, reservationFetchStatus]);

  useEffect(() => {
    if (
      reservation != null &&
      (reservationCreateStatus?.success || reservationUpdateStatus?.success)
    ) {
      // Update default payment account if users select new default payment method
      if (
        paymentAccount?.isDefaultPaymentEdited &&
        reservationCreateStatus?.success
      ) {
        dispatch(
          actionUpdateUserDefaultPaymentAccount({
            payment: paymentAccount,
            user,
          })
        );

        // Hide payment method alert when users choose a default method
        // even if they don't dismiss the message
        dispatch(
          actionUpdateUserRequest({
            hidePaymentMethodAlert: true,
            id: user.id,
          })
        );
      }
      dispatch(actionGetReservationRequest({ id: reservation.id }));
      setRedirectToConfirmPage(true);
    }
    return () => {
      dispatch(actionClearReservationCreateStatus());
      dispatch(actionClearReservationUpdateStatus());
    };
  }, [
    history,
    dispatch,
    reservationCreateStatus,
    reservationUpdateStatus,
    reservation,
    paymentAccount,
    user,
  ]);

  useEffect(() => {
    if (venuePayment == null) return;
    const {
      pregratuityTotal,
      mandatedGratuityAmt,
      gratuityType,
      taxAmt,
      serviceChargeAmt: serviceCharge,
      subtotal,
      paymentPolicy,
    } = venuePayment;

    if (gratuityType === GRATUITY_TYPE.SPECIFIC_GRATUITY) {
      const amount = sanitizeTotalAmount(pregratuityTotal, mandatedGratuityAmt);
      setTotalAmount(amount);
      setOriginalAmount(amount);
      setPaymentGratuity(mandatedGratuityAmt);
      setPaymentSubTotal(subtotal);
      setPaymentServiceCharge(serviceCharge);
      setPaymentTax(taxAmt);
      setVenuePaymentPolicy(paymentPolicy);
      setReservationObj(venuePayment);
      setIsPaymentCalculated(true);
    } else if (gratuityType === GRATUITY_TYPE.CLIENT_GRATUITY) {
      const amount = sanitizeTotalAmount(pregratuityTotal, 0);
      setTotalAmount(amount);
      setOriginalAmount(amount);
      setPaymentSubTotal(subtotal);
      setPaymentServiceCharge(serviceCharge);
      setPaymentTax(taxAmt);
      setVenuePaymentPolicy(paymentPolicy);
      setReservationObj(venuePayment);
      setIsPaymentCalculated(true);

      // On redirect from step up, get paymentGratuity from draftReservation and set on useState
      if (stepUp?.shouldRedirect) {
        setPaymentGratuity(draftReservation.paymentGratuity);
      }
    } else {
      setReservationObj({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [venuePayment, draftReservation.paymentGratuity]);

  const updateNotes = (notes) => {
    const updatedDraftReservation = draftReservation;
    updatedDraftReservation.notes = notes;
    dispatch(actionSetDraftReservation(updatedDraftReservation));
  };

  const updateSelectOccasions = (list) => {
    const updatedDraftReservation = draftReservation;
    updatedDraftReservation.specialOccasions = list;
    dispatch(actionSetDraftReservation(updatedDraftReservation));
  };

  const handleSetName = (e) => {
    setName(e.target.value);
  };

  const setErrorMessages = (error) => {
    if (CREDIT_CARD_ERROR_TYPE.INCOMPLETE_NUMBER === error.code) {
      setCardNumberError(error.message);
    } else if (CREDIT_CARD_ERROR_TYPE.INCOMPLETE_EXPIRY === error.code) {
      setCardExpiryError(error.message);
    } else if (CREDIT_CARD_ERROR_TYPE.INCOMPLETE_CVC === error.code) {
      setCardCvcError(error.message);
    } else if (1000 < error.code) {
      console.log(error.message);
    }
  };

  const clearErrorMessages = () => {
    setCardNumberError('');
    setCardExpiryError('');
    setCardCvcError('');
  };

  const modalCancelAction = () =>
    history?.location?.state?.type === RESERVATION_TYPE.UPDATE
      ? handleCancelEditReservation(
          history,
          dispatch,
          setModalOpen,
          reservation
        )
      : handleCancelEdit(history, dispatch, setModalOpen);

  const getPaymentNonceDetails = async (isTokenRefreshed = false) => {
    try {
      if (!user.isPushProvisioningEnabled) {
        throw new Error('User does not have push provisioning enabled');
      }

      const getPaymentNoncePayload = {
        associatedAccountReferenceId:
          paymentAccount.associatedAccountReferenceId,
        lastFourCardNumber: paymentAccount.accountDetails.lastFourCardNumber,
        reservationDateTime: generateISOString(
          draftReservation.realDateTime,
          draftReservation.venue.timezoneName
        ),
        forwardEndMerchantId: venueMerchantId,
        profileReferenceId: user.c1CustRefId,
        ccid: user.session.ccid,
      };
      const nonce = await getPaymentNonce(getPaymentNoncePayload);

      const details = {
        paymentIntermediary: 'capitalonebraintree',
        paymentIntermediaryToken: nonce,
        paymentCorrelationId: user.session.ccid,
        paymentProfileReferenceId: user.c1CustRefId,
      };

      return new PaymentNonceDetailsResponse({
        statusType: PAYMENT_STATUS_TYPES.SUCCESS,
        details,
      });
    } catch (error) {
      console.error(error);

      if (
        (error.message?.startsWith('INVALID_TOKEN') ||
          error.message?.startsWith('FORBIDDEN')) &&
        !isTokenRefreshed
      ) {
        await refreshAccessToken();
        return getPaymentNonceDetails(true);
      }

      if (error.message?.startsWith('FORBIDDEN')) {
        redirectToC1Challenge('reserve');
        return new PaymentNonceDetailsResponse({
          statusType: PAYMENT_STATUS_TYPES.STEP_UP,
        });
      }

      console.warn('Falling back on Stripe form');
      setStripeAlertMessage(RESERVE_STRIPE_STEP_UP_OR_PAYMENT_FAILED);
      setShouldShowStripeForm(true);
      setShouldShowLoader(false);

      return new PaymentNonceDetailsResponse({
        statusType: PAYMENT_STATUS_TYPES.FAIL,
      });
    }
  };

  // TODO refactor this to reduce cognitive complexity
  const handleReserve = async () => {
    setShouldShowLoader(true);
    if (paymentRule === PAYMENT_RULE_TYPE.ADVANCED_PAYMENT) {
      clearErrorMessages();
      setIsPaymentDisabled(true);

      let error = null;
      let statusType = null;
      let intentId = null;
      let paymentNonceDetails = {};

      if (shouldShowStripeForm) {
        const response = await handlePaymentIntentStripe(
          stripe,
          elements,
          totalAmount,
          draftReservation.venue.sevenRoomsVenueId || venue.sevenRoomsVenueId,
          name
        );
        error = response.error;
        statusType = response.statusType;
        intentId = response.intentId;
      } else {
        const response = await getPaymentNonceDetails();
        error = response.error;
        statusType = response.statusType;
        paymentNonceDetails = response.details;

        if (statusType === PAYMENT_STATUS_TYPES.STEP_UP) return;
      }

      setIsPaymentDisabled(false);

      if (error) {
        setErrorMessages(error);
        setShouldShowLoader(false);
      }

      if (statusType === PAYMENT_STATUS_TYPES.SUCCESS) {
        dispatch(
          actionCreateReservationRequest(
            createReservationPayload({
              ...draftReservation,
              ...paymentNonceDetails,
              paymentInstrumentToken: intentId,
              paymentGratuity: toCents(paymentGratuity),
              paymentServiceCharge: toCents(paymentServiceCharge),
              paymentTax: toCents(paymentTax),
              paymentSubTotal: toCents(paymentSubTotal),
              paymentPolicy: venuePaymentPolicy,
              paymentRule,
              paymentAccountReferenceId:
                paymentAccount?.associatedAccountReferenceId,
            })
          )
        );
      }
    } else if (paymentRule === PAYMENT_RULE_TYPE.SAVE_FOR_LATER) {
      clearErrorMessages();
      setIsPaymentDisabled(true);

      let error = null;
      let statusType = null;
      let intentId = null;
      let paymentNonceDetails = {};

      if (shouldShowStripeForm) {
        const response = await handleSetupIntentStripe(
          stripe,
          elements,
          draftReservation.venue.sevenRoomsVenueId || venue.sevenRoomsVenueId,
          name
        );
        error = response.error;
        statusType = response.statusType;
        intentId = response.intentId;
      } else {
        const response = await getPaymentNonceDetails();
        error = response.error;
        statusType = response.statusType;
        paymentNonceDetails = response.details;
      }

      setIsPaymentDisabled(false);
      if (error) {
        setErrorMessages(error);
        setShouldShowLoader(false);
      }

      if (statusType === PAYMENT_STATUS_TYPES.SUCCESS) {
        dispatch(
          actionCreateReservationRequest(
            createReservationPayload({
              ...draftReservation,
              ...paymentNonceDetails,
              paymentInstrumentToken: intentId,
              paymentPolicy: venuePaymentPolicy,
              paymentRule,
              paymentAccountReferenceId:
                paymentAccount?.associatedAccountReferenceId,
            })
          )
        );
      }
    } else {
      // NO PAYMENT RULES
      dispatch(
        actionCreateReservationRequest(
          createReservationPayload({
            ...draftReservation,
            paymentRule: PAYMENT_RULE_TYPE.NONE,
          })
        )
      );
    }
  };

  const handleClose = () => {
    history?.location?.state?.type === RESERVATION_TYPE.UPDATE
      ? handleToggleEditModal(modalOpen, setModalOpen)
      : modalCancelAction();
  };

  const stripeProperties = {
    handleSetName,
    cardNumberError,
    cardCvcError,
    cardCardExpiryError,
  };

  const reservationChargesProperties = {
    reservationObj,
    totalAmount,
    originalAmount,
    setTotalAmount,
    customTip,
    setCustomTip,
    tipProperties,
    setTipProperties,
    setPaymentGratuity: (gratuityAmount) => {
      setPaymentGratuity(gratuityAmount);
      // Save paymentGratuity on draftReservation so it is persisted on redirect from step up
      dispatch(
        actionSetDraftReservation({
          ...draftReservation,
          paymentGratuity: gratuityAmount,
        })
      );
    },
  };

  const closeButtonContainerMb = width > WIDTH_BREAKPOINT ? 3 : 2;

  const closeButtonWidth = width > WIDTH_BREAKPOINT ? 225 : 'auto';

  /*
    Don't show the loader if using Stripe form, since Stripe requires card form
    element to be visible in DOM, and the loader would remove the form from the DOM
  */
  if (shouldShowLoader && !shouldShowStripeForm) {
    return <CommonLoading active={shouldShowLoader} />;
  }

  if (redirectToConfirmPage) {
    return (
      <Redirect
        to={{
          pathname: '/reserve/confirmed',
          fromReservationPage: true,
          reservationCreateStatus,
          reservationUpdateStatus,
        }}
      />
    );
  }

  return (
    <PageSection className={classes.root}>
      {mounted && width > WIDTH_BREAKPOINT ? (
        <>
          <main className={classes.desktopLayoutContainer}>
            <Box mb={5} className={classes.desktopLayoutTitleContainer}>
              <Box
                className={classes.closeButtonContainer}
                mb={closeButtonContainerMb}
                display="flex"
                justifyContent="flex-end">
                <Box
                  display="flex"
                  justifyContent="center"
                  width={closeButtonWidth}>
                  <CloseButton
                    className={classes.close}
                    close={handleClose}
                    description={CLOSE_BUTTON_DESCRIPTION}
                    fill="#0D74AF"
                    label={CLOSE_BUTTON_LABEL}
                    role="link"
                  />
                </Box>
              </Box>
              <Box aria-hidden="true">
                <Title type={type} />
              </Box>
            </Box>
            <Box className={classes.desktopLayoutCard}>
              <ReservationCard
                type={type}
                reservation={draftReservation}
                showActionLinks={false}
                showSeeVenueLink={false}
              />
            </Box>
            <Box className={classes.desktopLayoutContent} pl={4}>
              <ReservationDetails
                type={type}
                paymentRule={paymentRule}
                stripeProperties={stripeProperties}
                reservationChargesProperties={reservationChargesProperties}
                reservation={draftReservation}
                updateNotes={updateNotes}
                updateSelectOccasions={updateSelectOccasions}
                showErrorAlert={showAlert}
                diningOptionsSlotName={draftReservation?.slotName}
                paymentPolicy={venuePaymentPolicy}
                bookingPolicy={draftReservation?.bookingPolicy}
                showInfoBlock={showInfoBlock}
                onEditPayment={onEditPayment}
                shouldShowStripeForm={shouldShowStripeForm}
                stripeAlertMessage={stripeAlertMessage}
              />
              <FormFooter
                type={type}
                isPaymentDisabled={isPaymentDisabled}
                handleReserve={handleReserve}
                handleEdit={handleEdit}
                handleCancel={handleCancel}
                handleUpdated={() =>
                  handleUpdated(
                    history,
                    dispatch,
                    draftReservation,
                    reservation
                  )
                }
              />
            </Box>
          </main>
        </>
      ) : (
        !navigationMenuVisible && (
          <>
            <main mb={2} className={classes.closeButtonContainer}>
              <CloseButtonForm
                handleClose={handleClose}
                setModalOpen={setModalOpen}
                modalOpen={modalOpen}
                toggleModal={handleToggleEditModal}
              />
              <Title type={type} aria-live="polite" />
              <ReservationCard type={type} reservation={draftReservation} />
              <ReservationDetails
                onEditPayment={onEditPayment}
                type={type}
                reservation={draftReservation}
                paymentRule={paymentRule}
                stripeProperties={stripeProperties}
                reservationChargesProperties={reservationChargesProperties}
                updateNotes={updateNotes}
                updateSelectOccasions={updateSelectOccasions}
                showErrorAlert={showAlert}
                diningOptionsSlotName={draftReservation?.slotName}
                paymentPolicy={venuePaymentPolicy}
                bookingPolicy={draftReservation?.bookingPolicy}
                showInfoBlock={showInfoBlock}
                shouldShowStripeForm={shouldShowStripeForm}
                stripeAlertMessage={stripeAlertMessage}
              />
              <FormFooter
                type={type}
                isPaymentDisabled={isPaymentDisabled}
                handleReserve={handleReserve}
                handleEdit={handleEdit}
                handleCancel={() => () => handleCancel(history)}
                handleUpdated={() =>
                  handleUpdated(
                    history,
                    dispatch,
                    draftReservation,
                    reservation
                  )
                }
              />
            </main>
          </>
        )
      )}
      <CancelEditModal
        modalOpen={modalOpen}
        handleCancelEdit={modalCancelAction}
        handleToggleEditModal={() =>
          handleToggleEditModal(modalOpen, setModalOpen)
        }
      />
    </PageSection>
  );
};

export default Reserve;
